import { Axios } from "../../service/serviceBasic";
import { messageErrorDefault } from '../../helper/constants';
import { toast } from "react-toastify";

import { 
  CHANGE_SUMMARY_DATA,
} from "./types";

export const onChangeSummaryData = (field, value) => dispatch => {
  dispatch({
    type: CHANGE_SUMMARY_DATA,
    payload: {field, value},
  });
}

export const getTerm = () => async (dispatch, getState) => {
  dispatch(onChangeSummaryData('loadingSummaryData', true));

  const { condominiumRegistration_condominiumId } = getState().condominiumRegistrationReducer.form;

  await Axios.post("v1/terms/getTermRegistration")
  .then(async resTerm => {
    await Axios.post("v1/termsCondominium/getTermCondominiumRegistration", {CondominiumId: condominiumRegistration_condominiumId})
    .then(resTermCond => {
      if (resTermCond.data !== null) {
        dispatch(onChangeSummaryData('summaryData_conditions', true));
      }
    })
    .catch(err => {
      console.log(err);
    })

    dispatch(onChangeSummaryData('summaryData_conditions_html', resTerm.data.html));
    dispatch(onChangeSummaryData('loadingSummaryData', false));
  })
  .catch(err => {
    console.log(err);
    dispatch(onChangeSummaryData('loadingSummaryData', false));
  })
}

export const acceptTerm = () => async (dispatch, getState) => {
  dispatch(onChangeSummaryData('loadingBtnSummaryData', true));

  const { condominiumRegistration_condominiumId } = getState().condominiumRegistrationReducer.form;
  const { userId } = getState().administrativeHeadReducer;

  return await Axios.post("v1/termsCondominium/acceptTermRegistration", {CondominiumId: condominiumRegistration_condominiumId, UserId: userId})
  .then(res => {
    dispatch(onChangeSummaryData('loadingBtnSummaryData', false));
    return true;
  })
  .catch(err => {
    toast.warn(messageErrorDefault, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "ui-alert-toast",
      bodyClassName: "ui-alert-toast-content",
    });
    dispatch(onChangeSummaryData('loadingBtnSummaryData', false));
    return false;
  })
}