import { Axios } from '../../service/serviceBasic';
import * as Types from './types';
import { messageErrorDefault } from '../../helper/constants';
import { toast } from 'react-toastify';
import { setCurrentStepAction } from '../navigationSteps/actions';

export const setSubscription = (payload) => ({
  type: Types.SET_SUBSCRIPTION,
  payload
})
export const setCouponLoading = (payload) => ({
  type: Types.SET_COUPON_LOADING,
  payload
})

export const setCouponInfo = (payload) => ({
  type: Types.SET_COUPON_INFO,
  payload
})

export const setLoading = (payload) => ({
  type: Types.SET_LOADING,
  payload
})
export const setSubscriptionList = (payload) => ({
  type: Types.SET_SUBSCRIPTION_LIST,
  payload
})
export const clearData = (payload) => ({
  type: Types.CLEAR_DATA,
  payload
})

export const changeSubscription = (subscription) => (dispatch, getState) => {
  setSubscription(subscription);
}

export const getSubscriptions = (coupon = null) => (dispatch, getState) => {
  dispatch(clearData());
  const hasCouponCode = !!coupon;

  if(hasCouponCode){
    dispatch(setCouponLoading(true));
  } else {
    dispatch(setLoading(true));
  }

  const { form } = getState().condominiumRegistrationReducer;  

  const request = {
    condominiumId: form.condominiumRegistration_condominiumId    
  }  

  if(hasCouponCode)
    request.coupon = coupon.toUpperCase(); 
 
  Axios.post('/v1/subscription/condominium/listCalculated', request)
    .then((response) => {
      dispatch(setSubscriptionList(addFeatureList(response.data)));

      const couponInfo = response.data[0]?.couponInfo;

      if(!hasCouponCode){
        return dispatch(setCouponInfo(null));
      }

      if(couponInfo?.isValid){

        dispatch(setCouponInfo({
          isValid: true, 
          discountAmount: couponInfo.discountAmount,         
          coupon: couponInfo.code,
          value: couponInfo.value,
          type: couponInfo.type
        }));

      } else { 

        dispatch(setCouponInfo({
          isValid: false,
        }))

      }    
     
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(setCouponLoading(false));
    });
}
 
export const registerSubscription = (history) => (dispatch, getState) => {
  const { choosePlanReducer, navigationStepsReducer, condominiumRegistrationReducer } = getState();
  const { form } = condominiumRegistrationReducer;    
 
  const request = {
    condominiumId:  form.condominiumRegistration_condominiumId,
    subscriptionId: choosePlanReducer.subscription.id,
    amountSubscription: choosePlanReducer.subscription.amount    
  }

  if(choosePlanReducer.couponInfo?.isValid){
    request.coupon = choosePlanReducer.couponInfo.coupon
  }

  const nextIndex = navigationStepsReducer.currentStep.Id + 1;
  const nextStep = navigationStepsReducer.navigations.Groups.find(item => item.Id == nextIndex);

  Axios.post('v1/condominium/registerSubscription', request)
  .then(() => {

    dispatch(setCurrentStepAction(nextStep));
    history.push('/payment')

  }).catch((error) => {

   if(!!request.coupon)
      dispatch(getSubscriptions(request.coupon))

    toast.warn(error.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "ui-alert-toast",
      bodyClassName: "ui-alert-toast-content",
    });
  }).finally(() => {

  });
}

const addFeatureList = (list) => {
  const newSubscription = {};
  for (var subscription of list) {
    const current = Object.values(subscriptionEnum).find(item =>  item.id == subscription.id);
    // In the case of the plan isn't in the subscription enum
    if(current)
      newSubscription[current.name] = { ...subscription, featureList: current.featureList };
  }
  return newSubscription;
}

const subscriptionEnum = {
  ouro: {
    id: '3976b839-5705-4ae7-a1cf-d8772d72f957',
    name: 'ouro',
    featureList: [
      'Boletos',
      'Autorizar acesso'
    ]
  },
  prata: {
    id: 'f8193f88-f036-11ed-8225-0242ac190002',
    name: 'prata',
    featureList: [
      'Carteirinha digital',
      'Reservas de área comum',
      'Documentos on-line',
      'Álbum de fotos on-line'
    ]
  },
  bronze: {
    id: '76150ddd-f028-11ed-8225-0242ac190002',
    name: 'bronze',
    featureList: [
      'Minhas Unidades',
      'Lista de Telefones',
      'Enquete',
      'Classificados',
      'Quadro de Avisos',
      'Recados aos Condôminos',
      'Aviso de Correspondência',
      'Fale com o condomínio'
    ]
  }
}

const calculatedMock = {
  data: [
    {
      "condominiumId": "219d8853-a6bc-4d77-b3ab-a26a237939b8",
      "amount": 210.00,
      "id": "3976b839-5705-4ae7-a1cf-d8772d72f957",
      "name": "Plano Ouro",
      "minPrice": 210.00,
      "unitPrice": 2.00,
      "subscriptionTypeId": "a816e2ca-f027-11ed-8225-0242ac190002",
      "waitingPeriod": 12,
      "statusRegister": 1
    },
    {
      "condominiumId": "219d8853-a6bc-4d77-b3ab-a26a237939b8",
      "amount": 49.90,
      "id": "76150ddd-f028-11ed-8225-0242ac190002",
      "name": "Plano Bronze",
      "minPrice": 49.90,
      "unitPrice": 1.00,
      "subscriptionTypeId": "a816e2ca-f027-11ed-8225-0242ac190002",
      "waitingPeriod": 12,
      "statusRegister": 1
    },
    {
      "condominiumId": "219d8853-a6bc-4d77-b3ab-a26a237939b8",
      "amount": 90.00,
      "id": "f8193f88-f036-11ed-8225-0242ac190002",
      "name": "Plano Prata",
      "minPrice": 90.00,
      "unitPrice": 1.50,
      "subscriptionTypeId": "a816e2ca-f027-11ed-8225-0242ac190002",
      "waitingPeriod": 12,
      "statusRegister": 1
    }
  ]
}

