import { SET_CONCLUSION_MESSAGE } from './types';

const initialState = {
  title: '',
  description: '',
  icon: '',
  // error: '',
  isError:false,
  clearStorage: false,
  urlRedirect: '',
  link: ''
}
export default function conclusionReducer(state = initialState, action) {

  switch(action.type){
    case SET_CONCLUSION_MESSAGE: return {...state, ...action.payload };
    default: return state;
  }
}