const reindex = (list) => {
    for (let x = 0; x < list.length; x++) {
        list[x].id = x+1;
    }
    return list;
}

const orderByDivision = (items) => {
    return items.sort((a, b) => {
        const nameA = a.division.toUpperCase();
        const nameB = b.division.toUpperCase();
        if (nameA < nameB) {
        return -1;
        }
        if (nameA > nameB) {
        return 1;
        }

        return 0;
    });
}
  
const orderByName = (items) => {
    items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
        return -1;
        }
        if (nameA > nameB) {
        return 1;
        }

        return 0;
    });

    return items;
};

const orderUnitsByNumberDesc = (list) => {
    try {
      return sortByNumber(list, true);
    }
    catch(e) {
        return list;
    }    
}
    

const orderUnitsByNumberAsc = (list) => {
    try {
        return sortByNumber(list);
    }
    catch(e) {
        return list;
    }
}

const orderFloor = (list) => {
    try {      
      return sortByNumber(list);      
    }
    catch(e) {
        return list;
    }
}

function sortByNumber(list, desc = false) {
  return list.sort((a, b) => {
    const ANumber = checkHasNumber(a.name) ?  parseInt(a.name.replace(/\D/g,'')) : 0;
    const BNumber = checkHasNumber(b.name) ?  parseInt(b.name.replace(/\D/g,'')) : 0;
    return desc ? BNumber - ANumber : ANumber - BNumber;
  }); 
}

function checkHasNumber(name) {
  return /\d+/.test(name);
}
   

export default {
    reindex: reindex,
    orderByDivision: orderByDivision,
    orderByName: orderByName,
    orderUnitsByNumberDesc: orderUnitsByNumberDesc,
    orderUnitsByNumberAsc: orderUnitsByNumberAsc,
    orderFloor: orderFloor
}
