import React from 'react';
import './detailCondominium.scss';

import Field from "../../../components/field";
import Table from "../../../components/table";
import Trash from "../../../assets/images/trash.png";
import AddIcon from "../../../assets/images/add.png";
import SuperButton from "../../../components/button";
import AreaField from '../../../components/areaField';
import { Icon, Typography } from '@material-ui/core';
import { blocksCount } from '../../../helper/constants';


export default function DetailCondominium({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const {
    onChangeBlockCondominiumName,
    onChangeFloorCondominiumName,
    onAddFloorCodominium,
    onAddUnitCondominium,
    onDeleteFloorCondominium,
    onDeleteUnitCondominium,
    onAddBlockCondominium
  } = props; // actions

  const getTable = () => {

    const rowsTable = [{
      head: true,
      columns: [
        { content: 'BLOCO', style: { width: '17%' } },
        { content: 'PAVIMENTO', style: { width: '17%' } },
        { content: 'UNIDADES', style: { width: '61%' } },
        { content: '', style: { width: '5%' } }
      ]
    }];

    form.condominiumSpec_condominium_blocks.map((block, indexBlock) => {    
      const columnBlock = { // Bloco -- Nome do bloco
        content:
          <div>            
            <Field
              type="text"
              required={true}
              name={`block${indexBlock}`}
              label=""
              minlength={3}
              maxlength={250}
              value={form.condominiumSpec_condominium_blocks.find(x => x.id === block.id) ? form.condominiumSpec_condominium_blocks.find(x => x.id === block.id).name : ""}
              onChange={(field, value) => {
                onChangeBlockCondominiumName(block.id, value);
              }}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ margin: 0, padding: 0 }}
              validateSync={(value) => form.condominiumSpec_condominium_blocks.filter(x => x.name.trim().toUpperCase() === value.trim().toUpperCase()).length === 1}
              validateSyncMessage="Bloco já existe!"
            />
            <SuperButton
              classes="btn-add-floor"
              onclick={() => onAddFloorCodominium(block.id)}
              content="ADICIONAR PAVIMENTO"
            />
          </div>,
        rowSpan: block.floors.length,
        style: form.condominiumSpec_condominium_blocks.length && indexBlock + 1 === form.condominiumSpec_condominium_blocks.length ? {borderBottomLeftRadius: '8px'} : null
      };

      block.floors.map((floor, indexFloor) => {
        let columns = [
          { // PAVIMENTO - Nome do andar
            content:
              <Field
                type="text"
                required={true}
                name={`block${indexBlock}-floor${indexFloor}`}
                label=""
                minlength={3}
                maxlength={250}
                value={block.floors.find(x => x.id === floor.id) ? block.floors.find(x => x.id === floor.id).name : ""}
                onChange={(field, value) => {
                  onChangeFloorCondominiumName(block.id, floor.id, value);
                }}
                returnObject={(self) => createRefFields(self)}
                noAsterisk
                style={{ margin: 0, padding: 0}}
                validateSync={(value) => block.floors.filter(x => x.name.trim().toUpperCase() === value.trim().toUpperCase()).length === 1}
                validateSyncMessage="Andar já existe para esse bloco!"
              />
          },
          { // UNIDADES - Nome da unidade
            content:
              <div className="units-condominium">
                <Field
                  type="text"
                  required={true}
                  name={`block${indexBlock}-floor${indexFloor}-unit${indexFloor}`}
                  label=""
                  value={block.floors.find(x => x.id === floor.id) ? block.floors.find(x => x.id === floor.id).units : ""}
                  onChange={() => null}
                  returnObject={(self) => createRefFields(self)}
                  noAsterisk
                  style={{ margin: 0, padding: 0 }}
                  hidden
                />              
                {
                  floor.units.map((unit, index) => {
                    return (
                      <button
                        className="btn-delete-unit"
                        title="Deletar unidade"
                        key={index}
                        onClick={(ev) => {
                          ev.preventDefault();
                          onDeleteUnitCondominium(block.id, floor.id, unit.id);
                        }}
                      >
                        {unit.name}&nbsp;<Icon>cancel</Icon>
                      </button>
                    )
                  })
                }
                <SuperButton
                  classes="btn-add-unit"
                  onclick={() => onAddUnitCondominium(block.id, floor.id)}
                  content="ADICIONAR UNIDADE"
                />
              </div>
          },
          {
            content:
              <button
                className="btn-delete-floor"
                onClick={(ev) => { ev.preventDefault(); onDeleteFloorCondominium(block.id, floor.id) }}
                title="Deletar pavimento"
              >
                <Icon>delete</Icon>
              </button>,
            style: { paddingLeft: '0', paddingRight: '0', textAlign: 'center' }
          }
        ]

        if (indexFloor === 0) {
          columns.unshift(columnBlock);
        }        

        rowsTable.push({
          head: false,
          columns: columns
        });

        return columns;
      });

      return block;
    })

    return rowsTable;
  }

  return (
    <div className="detailCondominium">       
      <Table list={getTable()} />
      <Field
        type="text"
        name={`validate-blocks-count`}
        label=""
        value=""
        onChange={(field, value) => null}
        returnObject={(self) => createRefFields(self)}
        noAsterisk
        style={{ margin: 0, padding: 0 }}
        validateSync={(value) => form.condominiumSpec_condominium_blocks.length >= blocksCount.Two}
        validateSyncMessage={`Obrigatório ao menos ${blocksCount.Two} blocos.`}
        hidden
      />
      <SuperButton
        onclick={() => onAddBlockCondominium()}
        content={<>ADICIONAR BLOCO <img src={AddIcon} alt="adicionar" /></>}
      />
    </div>
  );
}
