import { Axios } from "../../service/serviceBasic";
import { toast } from "react-toastify";
import { onChangeCondominiumSpecificationsData } from "../condominiumSpecifications/actions";
import { messageErrorDefault, numberOfUnits } from '../../helper/constants';
import listProcessing from '../../helper/listProcessing';

import { 
    CHANGE_STREET_NAME,
    CHANGE_UNIT_COUNT,
    DELETE_STREET,
    ADD_STREET,
    CHANGE_FLOOR_NAME,
    CHANGE_UNITS_NAMES_BUILDING,
    DELETE_FLOOR_BUILDING,
    ADD_FLOOR_BUILDING,
    DELETE_UNIT_BUILDING,
    ADD_UNIT_BUILDING,
    CHANGE_BLOCK_CONDOMINIUM_NAME,
    CHANGE_FLOOR_CONDOMINIUM_NAME,
    ADD_FLOOR_CONDOMINIUM,
    ADD_BLOCK_CONDOMINIUM,
    DELETE_UNIT_HOUSE,
    ADD_UNIT_HOUSE,
    SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS
  } from "./types";

  import {
    CHANGE_BLOCKS_CONDOMINIUM
  }
  from "../condominiumSpecifications/types";
import { condominiumTypes } from "../../helper/constants";

export const onChangeStreetName = (id, name) => dispatch => {
  dispatch({
    type: CHANGE_STREET_NAME,
    payload: {id, name},
  });
}

export const onChangeUnitCount = (id, unitCount) => dispatch => {
  dispatch({
    type: CHANGE_UNIT_COUNT,
    payload: {id, unitCount},
  });
}

export const onDeleteStreet = (id) => dispatch => {
  dispatch({
    type: DELETE_STREET,
    payload: id,
  });
}

export const onAddStreet = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  const streets = form.condominiumSpec_house_streets;

  let nextNumberStreet = 0;

  for (let index = 0; index < streets.length; index++) {
    const street = streets[index];
    const hasNumberInName = /\d*/.test(street.name);
    const numberInName = parseInt(street.name.replace(/\D/g, '') || 0);
    if(hasNumberInName && numberInName > nextNumberStreet){
      nextNumberStreet = parseInt(street.name.replace(/\D/g, ''))      
    }
  }

  nextNumberStreet = nextNumberStreet !== 0 ? nextNumberStreet : streets.length;

  const street = {
    name: `Rua ${nextNumberStreet + 1}`,
    units: addUnits(form.condominiumSpec_house_unitsPerStreet, 'Casa', form)
  }

  dispatch({
    type: ADD_STREET,
    payload: street
  });
}

export const onChangeFloorName = (id, name) => dispatch => {
  dispatch({
    type: CHANGE_FLOOR_NAME,
    payload: {id, name},
  });
}

export const onChangeUnitsNamesBuilding = (id, units) => dispatch => {
  dispatch({
    type: CHANGE_UNITS_NAMES_BUILDING,
    payload: {id, units},
  });
}

export const onDeleteFloorBuilding = (id) => dispatch => {
  dispatch({
    type: DELETE_FLOOR_BUILDING,
    payload: id,
  });
}

export const setloadingBtnCondominiumSpecifications = (value) => dispatch => {
  dispatch({
    type: SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS,
    payload: value,
  });
}

export const onAddFloorBuilding = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;
  let floors = form.condominiumSpec_building_floors;

  const floor = addFloors(1, form.condominiumSpec_building_unitsPerFloor, 'Andar', form.condominiumSpec_building_nameOfUnits,
    floors)[0];

  dispatch({
    type: ADD_FLOOR_BUILDING,
    payload: floor
  });
}

export const onDeleteUnitBuilding = (idFloor, idUnit) => dispatch => {
  dispatch({
    type: DELETE_UNIT_BUILDING,
    payload: {idFloor, idUnit}
  });
}

export const onDeleteUnitHouse = (idStreet, idUnit) => dispatch => {
  dispatch({
    type: DELETE_UNIT_HOUSE,
    payload: {idStreet, idUnit}
  });
}

export const onAddUnitBuilding = (idFloor) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;
  const floor = form.condominiumSpec_building_floors.find(x => x.id === idFloor);

  const unit = addUnits(1, form.condominiumSpec_building_nameOfUnits, floor.units)[0];

  dispatch({
    type: ADD_UNIT_BUILDING,
    payload: {idFloor, unit}
  });
}

export const onAddUnitHouse = (idStreet, customName) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;
  const street = form.condominiumSpec_house_streets.find(x => x.id === idStreet);

  let unit = {};

  if(customName)
  { 
    
    const lastID = street.units.map(item => item.id).sort((a,b) => b - a)[0] || 0

    console.log(lastID)

    unit = {
      id: lastID + 1,
      name: customName
    }    
    
  }else{
    unit = addUnits(1, 'Casa', street.units)[0];
  }

  dispatch({
    type: ADD_UNIT_HOUSE,
    payload: {unit, idStreet}
  });
}

export const onChangeBlockCondominiumName = (idBlock, name) => dispatch => {
  dispatch({
    type: CHANGE_BLOCK_CONDOMINIUM_NAME,
    payload: {idBlock, name}
  });
}

export const onChangeFloorCondominiumName = (idBlock, idFloor, name) => dispatch => {
  dispatch({
    type: CHANGE_FLOOR_CONDOMINIUM_NAME,
    payload: {idBlock, idFloor, name}
  });
}

export const onAddFloorCodominium = (idBlock) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;  

  form.condominiumSpec_condominium_blocks.map(block => {
    if (block.id === idBlock) {
      block.floors = block.floors.concat(
        addFloors(
          1,
          form.condominiumSpec_condominium_unitsCount,
          "Andar",
          form.condominiumSpec_condominium_nameOfUnits,
          block.floors,
          form.condominiumSpec_condominium_checkNumberOfUnits
        ));

      block.floors = listProcessing.orderFloor(block.floors);
      block.floors = listProcessing.reindex(block.floors);
    };

    return block;
  });

  dispatch({
    type: ADD_FLOOR_CONDOMINIUM,
    payload: form.condominiumSpec_condominium_blocks
  });
}
export const onAddUnitCondominium = (idBlock, idFloor) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  form.condominiumSpec_condominium_blocks.map(block => {
    if (block.id === idBlock) {
      block.floors.map(floor => {
        if (floor.id === idFloor) {
          floor.units = floor.units.concat(
            addUnits(1, form.condominiumSpec_condominium_nameOfUnits, floor.units)
          );
          floor.units = listProcessing.orderUnitsByNumberAsc(floor.units);
          floor.units = listProcessing.reindex(floor.units);
        }
        return floor;
      })      
    }    
    return block;
  })

  dispatch({
    type: CHANGE_BLOCKS_CONDOMINIUM,
    payload: form.condominiumSpec_condominium_blocks
  });
}
export const onDeleteFloorCondominium = (idBlock, idFloor) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  let blocks = form.condominiumSpec_condominium_blocks.filter(block => {
    if (block.id === idBlock) {
      block.floors = block.floors.filter(y => y.id !== idFloor);
      block.floors = listProcessing.reindex(block.floors);
    }
    return block.floors.length > 0;
  })

  blocks = listProcessing.reindex(blocks);

  dispatch({
    type: CHANGE_BLOCKS_CONDOMINIUM,
    payload: blocks
  });
}

export const onDeleteUnitCondominium = (idBlock, idFloor, idUnit) => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  let blocks = form.condominiumSpec_condominium_blocks.filter(block => {
    if (block.id === idBlock) {
      block.floors = block.floors.filter(floor => {
        if (floor.id === idFloor) {
          floor.units = floor.units.filter(unit => unit.id !== idUnit);
          floor.units = listProcessing.reindex(floor.units);
        }
        return floor.units.length > 0;
      })
      block.floors = listProcessing.reindex(block.floors);
    }

    return block.floors.length > 0;
  })

  blocks = listProcessing.reindex(blocks);

  dispatch({
    type: CHANGE_BLOCKS_CONDOMINIUM,
    payload: blocks
  });
}

export const onAddBlockCondominium = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;
  let blocks = form.condominiumSpec_condominium_blocks.concat(addBlocks(1, form));

  blocks = listProcessing.orderByName(blocks);
  blocks = listProcessing.reindex(blocks);

  dispatch({
    type: ADD_BLOCK_CONDOMINIUM,
    payload: blocks
  });
}

const addBlocks = (quantity, form) => {
  let arrayBlocks = form.condominiumSpec_condominium_blocks;

  let lastNumberName = 0;
  let lastNumberId = 0;

  if (arrayBlocks.length > 0) {
    arrayBlocks = listProcessing.orderUnitsByNumberDesc(arrayBlocks);
    lastNumberName = arrayBlocks[0].name.replace(/\D/g,'');

    arrayBlocks = arrayBlocks.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    lastNumberId = arrayBlocks[0].id;
  }

  try {lastNumberName = parseInt(lastNumberName)}catch(e) {lastNumberName = 0;};

  let blocks = [];
  for (let k = 0; k < quantity; k++, lastNumberName++, lastNumberId++) {
    blocks.push({
      id: lastNumberId+1,
      name: `Bloco ${lastNumberName+1}`,
      floors: addFloors(
          form.condominiumSpec_condominium_floors,
          form.condominiumSpec_condominium_unitsCount,
          "Andar",
          form.condominiumSpec_condominium_nameOfUnits,
          [],
          form.condominiumSpec_condominium_checkNumberOfUnits
        )
    })
  }
  return blocks;
}

const addFloors = (quantity, unitsPerFloor, nameFloor, nameUnit, arrayFloors = [], checkNumberOfUnits) => {
  let lastNumberName = 0;
  let lastNumberId = 0;

  if (arrayFloors.length > 0) {
    arrayFloors = listProcessing.orderUnitsByNumberDesc(arrayFloors);
    lastNumberName = arrayFloors[0].name.replace(/\D/g,'');

    arrayFloors = arrayFloors.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    lastNumberId = arrayFloors[0].id;
  }

  try {lastNumberName = parseInt(lastNumberName)}catch(e) {lastNumberName = 0;};

  const numbersOfUnitsObj = {
    [numberOfUnits.Dezena]: 10,
    [numberOfUnits.Centena]: 100,
    [numberOfUnits.Milhar]: 1000    
  } 

  const nameOfUnits = numbersOfUnitsObj[checkNumberOfUnits];

  let floors = [];
  for (let k = 0; k < quantity; k++, lastNumberName++, lastNumberId++) {
    floors.push({
      id: parseInt(lastNumberId)+1,
      name: `${nameFloor} ${lastNumberName+1}`,
      units: addUnits(unitsPerFloor, nameUnit, [], !!nameOfUnits ? nameOfUnits * (lastNumberName + 1) : undefined )
    })
  }
  return floors;
}

const addUnits = (quantity, name, arrayUnits = [], incrementNumber = 0) => { 
  let lastNumberName = 0;
  let lastNumberId = 0;

  if (arrayUnits.length > 0) {
    arrayUnits = listProcessing.orderUnitsByNumberDesc(arrayUnits);
    lastNumberName = arrayUnits[0].name.replace(/\D/g,'');

    arrayUnits = arrayUnits.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    lastNumberId = arrayUnits[0].id;
  }
  
  try {lastNumberName = parseInt(lastNumberName)}catch(e) {lastNumberName = 0;};

  let units = [];
  for (let k = 0; k < quantity; k++, lastNumberName++, lastNumberId++) {
    units.push({
      id: parseInt(lastNumberId)+1,
      name: `${name} ${lastNumberName + 1 + incrementNumber}`
    })
  }
  
  return listProcessing.orderUnitsByNumberAsc(units);
}

export const addCondominiumSpecifications = () => async (dispatch, getState) => {
  dispatch(setloadingBtnCondominiumSpecifications(true));

  const { form } = getState().condominiumSpecificationsReducer;
  const { condominiumRegistration_condominiumId } = getState().condominiumRegistrationReducer.form;

  let obj = {
    CondominiumTypeId: form.condominiumSpec_type,
    Id: condominiumRegistration_condominiumId,
    //CodeCondominium: null,
    Blocks: [],
    Units: []
  };

  if (form.condominiumSpec_type === condominiumTypes.House) { // House
    //obj.CodeCondominium = form.condominiumSpec_house_code;
    form.condominiumSpec_house_streets.map(street => {
      street.units.map(unit => {
        obj.Units.push({
          CondominiumId: condominiumRegistration_condominiumId,
          Name: unit.name,
          Division: street.name
        });
        return unit;
      })     
      return street;
    });    
  } else if (form.condominiumSpec_type === condominiumTypes.Building) { // Building
    //obj.CodeCondominium = form.condominiumSpec_building_code;
    form.condominiumSpec_building_floors.map(floor => {
      floor.units.map(unit => {
        obj.Units.push({
          CondominiumId: condominiumRegistration_condominiumId,
          Name: unit.name,
          Division: floor.name
        });        
        return unit;
      })
      return floor;
    })
  } else if (form.condominiumSpec_type === condominiumTypes.Condominium) { // Condominium
    //obj.CodeCondominium = form.condominiumSpec_condominium_code;
    obj.Units = []; // 0 Unidade sem bloco

    form.condominiumSpec_condominium_blocks.map(block => {
      let units = [];
      block.floors.map(floor => {
        floor.units.map(unit => {
          units.push({
            CondominiumId: condominiumRegistration_condominiumId,
            Name: unit.name,
            Division: floor.name
          })
          return unit;
        })
        return floor;
      })
      obj.Blocks.push({
        CondominiumId: condominiumRegistration_condominiumId,
        Name: block.name,
        Units: units // Unidades com bloco (Dentro de Blocks)
      })
      return block;
    })
  }

  return await Axios.post("v1/condominium/addCondominiumSpecifications", obj)
  .then((res) => {
    dispatch(setloadingBtnCondominiumSpecifications(false));
    return true;
  })
  .catch(error => {
    toast.warn(error.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "ui-alert-toast",
      bodyClassName: "ui-alert-toast-content",
    });
    dispatch(setloadingBtnCondominiumSpecifications(false));
    return false;
  })
}





