import React, { useState } from 'react'
import useCoupon from './hooks/useCoupon';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';

const RenderAddCouponButton = ({ onClick }) => (
  <>
    <svg width="25" height="15" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.24 8.25L28.7 10.725L14.76 24.75L12.3 22.275L26.24 8.25ZM4.1 0H36.9C39.1755 0 41 1.83562 41 4.125V12.375C39.9126 12.375 38.8698 12.8096 38.1009 13.5832C37.332 14.3568 36.9 15.406 36.9 16.5C36.9 17.594 37.332 18.6432 38.1009 19.4168C38.8698 20.1904 39.9126 20.625 41 20.625V28.875C41 31.1644 39.1755 33 36.9 33H4.1C3.01261 33 1.96976 32.5654 1.20086 31.7918C0.431963 31.0182 0 29.969 0 28.875V20.625C2.2755 20.625 4.1 18.7894 4.1 16.5C4.1 15.406 3.66804 14.3568 2.89914 13.5832C2.13024 12.8096 1.08739 12.375 0 12.375V4.125C0 3.03098 0.431963 1.98177 1.20086 1.20818C1.96976 0.434597 3.01261 0 4.1 0ZM4.1 4.125V9.36375C5.34574 10.0864 6.38033 11.1265 7.09967 12.3792C7.81902 13.632 8.19774 15.0532 8.19774 16.5C8.19774 17.9468 7.81902 19.368 7.09967 20.6208C6.38033 21.8735 5.34574 22.9136 4.1 23.6362V28.875H36.9V23.6362C35.6543 22.9136 34.6197 21.8735 33.9003 20.6208C33.181 19.368 32.8023 17.9468 32.8023 16.5C32.8023 15.0532 33.181 13.632 33.9003 12.3792C34.6197 11.1265 35.6543 10.0864 36.9 9.36375V4.125H4.1ZM15.375 8.25C17.0765 8.25 18.45 9.63188 18.45 11.3438C18.45 13.0556 17.0765 14.4375 15.375 14.4375C13.6735 14.4375 12.3 13.0556 12.3 11.3438C12.3 9.63188 13.6735 8.25 15.375 8.25ZM25.625 18.5625C27.3265 18.5625 28.7 19.9444 28.7 21.6562C28.7 23.3681 27.3265 24.75 25.625 24.75C23.9235 24.75 22.55 23.3681 22.55 21.6562C22.55 19.9444 23.9235 18.5625 25.625 18.5625Z" fill="#672CB0" />
    </svg>

    <Typography onClick={onClick} className='coupon-text pointer'>Adicionar cupom</Typography>
  </>
)

const RenderInputCouponForm = ({ loading, onSubmit, errorMessage }) => {
  const [code, setCode] = useState('');

  return (
    <div className='coupon-input'>
      <div className='coupon-input-wrapper'>
        <label className='coupon-text'>Cupom de desconto</label>
        <TextField
          InputProps={{
            style: {
              textTransform: 'uppercase',
              maxHeight: 20,
              maxWidth: 130,
              fontSize: 12,
              fontWeight: 'bold',
              border: 0,
              borderBottom: '1px solid #582498'
            },
          }}
          inputProps={{
            maxLength: 20,
            style: {
              textTransform: 'uppercase'
            }
          }}
          placeholder='Digite seu cupom'
          color='primary'
          size='small'
          onBlur={ev => setCode(ev.target.value)}
        />
        <Button
          disabled={loading}
          onClick={() => onSubmit(code)}
          variant='outlined'
          color='primary'
          size='small'
          style={{ borderWidth: 2, maxWidth: 20, maxHeight: 30, borderRadius: 8, textTransform: 'none' }}
        >
          {loading ? <CircularProgress size={12} /> : 'Ativar'}
        </Button>
      </div>
      <span className='coupon-error'>{errorMessage}</span>
    </div>
  )
}

const RenderSuccessCoupon = ({ successMessage }) => {
  return (
    <>
      <svg width="25" height="15" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.24 8.25L28.7 10.725L14.76 24.75L12.3 22.275L26.24 8.25ZM4.1 0H36.9C39.1755 0 41 1.83562 41 4.125V12.375C39.9126 12.375 38.8698 12.8096 38.1009 13.5832C37.332 14.3568 36.9 15.406 36.9 16.5C36.9 17.594 37.332 18.6432 38.1009 19.4168C38.8698 20.1904 39.9126 20.625 41 20.625V28.875C41 31.1644 39.1755 33 36.9 33H4.1C3.01261 33 1.96976 32.5654 1.20086 31.7918C0.431963 31.0182 0 29.969 0 28.875V20.625C2.2755 20.625 4.1 18.7894 4.1 16.5C4.1 15.406 3.66804 14.3568 2.89914 13.5832C2.13024 12.8096 1.08739 12.375 0 12.375V4.125C0 3.03098 0.431963 1.98177 1.20086 1.20818C1.96976 0.434597 3.01261 0 4.1 0ZM4.1 4.125V9.36375C5.34574 10.0864 6.38033 11.1265 7.09967 12.3792C7.81902 13.632 8.19774 15.0532 8.19774 16.5C8.19774 17.9468 7.81902 19.368 7.09967 20.6208C6.38033 21.8735 5.34574 22.9136 4.1 23.6362V28.875H36.9V23.6362C35.6543 22.9136 34.6197 21.8735 33.9003 20.6208C33.181 19.368 32.8023 17.9468 32.8023 16.5C32.8023 15.0532 33.181 13.632 33.9003 12.3792C34.6197 11.1265 35.6543 10.0864 36.9 9.36375V4.125H4.1ZM15.375 8.25C17.0765 8.25 18.45 9.63188 18.45 11.3438C18.45 13.0556 17.0765 14.4375 15.375 14.4375C13.6735 14.4375 12.3 13.0556 12.3 11.3438C12.3 9.63188 13.6735 8.25 15.375 8.25ZM25.625 18.5625C27.3265 18.5625 28.7 19.9444 28.7 21.6562C28.7 23.3681 27.3265 24.75 25.625 24.75C23.9235 24.75 22.55 23.3681 22.55 21.6562C22.55 19.9444 23.9235 18.5625 25.625 18.5625Z" fill="#672CB0" />
      </svg>

      <Typography className='coupon-text'>{successMessage}</Typography>
    </>
  )
}

const CouponInput = () => {

  const {
    loading,
    couponInfo,
    openCouponInput,
    getCouponErrorMessage,
    setOpenCouponInput,
    getDiscountText,
    onSubmit
  } = useCoupon();


  return (
    <div className='coupon-container'>
      {!openCouponInput && (
        <RenderAddCouponButton onClick={() => setOpenCouponInput(true)} />
      )}

      {openCouponInput && !couponInfo?.isValid && (
        <RenderInputCouponForm
          loading={loading}
          errorMessage={getCouponErrorMessage(couponInfo)}
          onSubmit={onSubmit}
        />
      )}

      {couponInfo?.isValid && (
        <RenderSuccessCoupon successMessage={`Cupom de ${getDiscountText(couponInfo)} de desconto`} />
      )}
    </div>
  )
}

export default CouponInput
