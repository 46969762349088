import React from 'react';
import './table.scss';

export default function Table({list}) {

	return (
		<div className="table-registration-cond">
			<table>	
        <tbody>
          {list.map((row, i) => {
            return (
              <tr key={i}>
                {row.head ? (
                  row.columns.map((col, index) => (
                    <th key={index} style={col.style}>{col.content}</th>
                  ))
                ) : (
                  row.columns.map((col, index) => (
                    <td key={index} style={col.style} rowSpan={col.rowSpan}>{col.content}</td>
                  ))
                )}  
              </tr>     
            )
          })}
        </tbody>			
			</table>
		</div>
	);
}