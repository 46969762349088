import {
  CHANGE_SUMMARY_DATA,
  SET_LOADING
} from './types'

const initialState = {
  summaryData_conditions: false,
  summaryData_termsOfUse: false,
  summaryData_conditions_html: '',
  loadingSummaryData: false,
  loadingBtnSummaryData: false
}

export default function summaryDataReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SUMMARY_DATA:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case SET_LOADING:
      return {
        ...state,
        loadingSummaryData: action.payload
      }
    default:
      return state
  }
}


