import React from 'react';
import './house.scss';

import Field from "../../../components/field";
import CheckObligation from "../../../components/checkObligation";
import CheckBox from "../../../components/checkBox";
import ItemCheckBox from "../../../components/checkBox/itemCheckBox";

export default function House({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const { onChangeCondominiumSpecificationsData, onChangeStreetsHouse } = props; // actions

  return (
    <div>
      
      <CheckObligation
        field="condominiumSpec_house_code"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_house_code">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_house_code"
              label="CÓDIGO DO CONDOMÍNIO"
              minlength={2}
              mask="SSSS"
              value={form.condominiumSpec_house_code}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value.toUpperCase())}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ width: '40%' }}
            />
          </div>
        )}
      </CheckObligation>


      {/* QUANTIDADE DE RUAS */}
      
      <CheckObligation
        field="condominiumSpec_house_streetCount"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_house_streetCount">
            {/* <div className="label"></div> */}
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_house_streetCount"
              label="QUANTIDADE DE RUAS EXISTENTES NO CONDOMÍNIO:"
              maxlength={4}
              mask="9999"
              value={form.condominiumSpec_house_streetCount}
              onChange={(field, value) => {
                onChangeCondominiumSpecificationsData(field, value);
              }}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ margin: 0, width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_house_streetCount) > 0}
            />
          </div>
        )}
      </CheckObligation>

      {/* QUANTIDADE DE CASAS */}
      <CheckObligation
        field="condominiumSpec_house_unitsPerStreet"
        fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
      >
        {(validations) => (
          <div className="col condominiumSpec_house_unitsPerStreet">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_house_unitsPerStreet"
              label={'NÚMERO DE UNIDADES POR RUA:'}
              maxlength={5}
              mask="99999"
              value={form.condominiumSpec_house_unitsPerStreet}
              onChange={(field, value) => {
                onChangeCondominiumSpecificationsData(field, value);
              }}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ margin: 0, width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_house_unitsPerStreet) > 0}
            />
          </div>
        )}
      </CheckObligation>
    </div>
  );
}
