import { SET_CONCLUSION_MESSAGE } from './types';
import { toast } from 'react-toastify';
import React from 'react';

export const setConclusionMessage = obj => dispatch => {

  dispatch({
    type: SET_CONCLUSION_MESSAGE,
    payload: obj
  });
} 
