import React from "react";
import { Switch, Route, Redirect } from "react-router";

import CondominiumRegistration from "./screens/condominiumRegistration";
import AdministrativeHead from "./screens/administrativeHead";
import CondominiumSpecifications from "./screens/condominiumSpecifications";
import DetailCondominiumUnits from "./screens/detailCondominiumUnits";
import SummaryData from "./screens/summaryData";
import Contract from "./screens/contract";
import Payment from "./screens/payment";
import ChoosePlan from "./screens/choosePlan";
import Conclusion from "./screens/conclusion";
import PaymentMethods from "./screens/paymentMethods";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact={true}
          component={() => <Redirect to="/administrativeHead" />}
        />
        <Route path="/administrativeHead" component={AdministrativeHead} />
        <Route path="/condominiumRegistration" component={CondominiumRegistration} />       
        <Route path="/condominiumSpecifications" component={CondominiumSpecifications} />
        <Route path="/detailCondominiumUnits" component={DetailCondominiumUnits} />
        <Route path="/summaryData" component={SummaryData} />
        <Route path="/contract" component={Contract} />
        <Route path="/choosePlan" component={ChoosePlan} />
        <Route path="/paymentMethods" component={PaymentMethods} />
        <Route path="/payment" component={Payment} />
        <Route path="/conclusion" component={Conclusion} />
      </Switch>
    );
  }
}

export default Routes;
