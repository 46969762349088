import React from 'react';
import './building.scss';

import Field from "../../../components/field";
import CheckObligation from "../../../components/checkObligation";
import CheckBox from '../../../components/checkBox';
import ItemCheckBox from '../../../components/checkBox/itemCheckBox';
import { nameOfUnits, numberOfUnits } from '../../../helper/constants';

export default function Building({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const { onChangeCondominiumSpecificationsData } = props; // actions

  return (
    <div>
      <div className="condominiumSpec_building_checkNameOfUnits">
        <CheckObligation
          field="condominiumSpec_building_checkNameOfUnits"
          fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
        >
          {(validations) => (
            <div className="col">
              <Field
                type="text"
                required={validations.required}
                name="condominiumSpec_building_checkNameOfUnits"
                label=""
                value={form.condominiumSpec_building_checkNameOfUnits}
                onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                returnObject={(self) => createRefFields(self)}
                hidden={true}
                noAsterisk
              />

              <CheckBox
                label="COMO DESEJA CHAMAR CADA UNIDADE DO SEU PRÉDIO ?"
                arrayItems={[
                  <ItemCheckBox
                    key={1}
                    label="SALA"
                    check={form.condominiumSpec_building_checkNameOfUnits === nameOfUnits.Sala}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNameOfUnits", nameOfUnits.Sala);
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_nameOfUnits", "Sala");
                    }}
                  />,
                  <ItemCheckBox
                    key={2}
                    label="APARTAMENTO"
                    check={form.condominiumSpec_building_checkNameOfUnits === nameOfUnits.Apartamento}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNameOfUnits", nameOfUnits.Apartamento);
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_nameOfUnits", "Apartamento");
                    }}
                  />,
                  <ItemCheckBox
                    key={3}
                    label="LOJA"
                    check={form.condominiumSpec_building_checkNameOfUnits === nameOfUnits.Loja}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNameOfUnits", nameOfUnits.Loja);
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_nameOfUnits", "Loja");
                    }}
                  />,
                  <ItemCheckBox
                    key={4}
                    label="ESCRITÓRIO"
                    check={form.condominiumSpec_building_checkNameOfUnits === nameOfUnits.Escritório}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNameOfUnits", nameOfUnits.Escritório);
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_nameOfUnits", "Escritório");
                    }}
                  />,
                  <ItemCheckBox
                    key={5}
                    label="OUTRO"
                    check={form.condominiumSpec_building_checkNameOfUnits === nameOfUnits.Outro}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNameOfUnits", nameOfUnits.Outro);
                    }}
                  />
                ]}
              />
            </div>
          )}
        </CheckObligation>            

        <CheckObligation
          field="condominiumSpec_building_nameOfUnits"
          fieldConditional={{
            condominiumSpec_type: form.condominiumSpec_type,
            condominiumSpec_building_checkNameOfUnits: form.condominiumSpec_building_checkNameOfUnits
          }}
        >
          {(validations) => (
            <div className="col-80 condominiumSpec_building_nameOfUnits">
              <Field
                type="text"
                required={validations.required}
                name="condominiumSpec_building_nameOfUnits"
                label="COMO DESEJA CHAMAR A SUA UNIDADE ?"
                minlength={3}
                maxlength={150}
                value={form.condominiumSpec_building_nameOfUnits}
                onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                returnObject={(self) => createRefFields(self)}
                noAsterisk
                style={{ margin: 0, width: '100%' }}
              />
            </div>
          )}
        </CheckObligation>
      </div>

      <CheckObligation
        field="condominiumSpec_building_code"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type,         
        }}
         >
        {(validations) => (
          <div className="col">            
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_building_code"
              label="CÓDIGO DO CONDOMÍNIO"
              minlength={2}
              mask="SSSS"
              value={form.condominiumSpec_building_code}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value.toUpperCase())}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ width: '40%' }}
            />
          </div>
        )}
        </CheckObligation>

      <CheckObligation
        field="condominiumSpec_building_floorsCount"
        fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
      >
        {(validations) => (
          <div className="col condominiumSpec_building_floors">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_building_floorsCount"
              label="QUANTOS ANDARES POSSUI SEU PRÉDIO ?"
              mask="999"
              value={form.condominiumSpec_building_floorsCount}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ margin: 0, width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_building_floorsCount) > 0}
            />
          </div>
        )}
      </CheckObligation>

      <CheckObligation
        field="condominiumSpec_building_unitsPerFloor"
        fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
      >
        {(validations) => (
          <div className="col condominiumSpec_building_unitsPerFloor">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_building_unitsPerFloor"
              label="QUANTAS UNIDADES EM CADA ANDAR ?"
              mask="999"
              value={form.condominiumSpec_building_unitsPerFloor}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ margin: 0, width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_building_unitsPerFloor) > 0}
            />
          </div>
        )}
      </CheckObligation>
      <div style={{marginTop: 15}}
          field="condominiumSpec_building_checkNumberOfUnits"
          fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
        >
          { form.condominiumSpec_type == 2 && /* {(validations) => ( }*/
            <div className="col">
              <Field
                type="text"
                // required={validations.required}
                name="condominiumSpec_building_checkNumberOfUnits"
                label=""
                value={form.condominiumSpec_building_checkNumberOfUnits}
                onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                returnObject={(self) => createRefFields(self)}
                hidden={true}
                noAsterisk
              />
              <CheckBox
                label="COMO PODEMOS INICIAR A NUMERAÇÂO DAS UNIDADES?"
                arrayItems={[
                  <ItemCheckBox
                    key="UNIDADE"
                    label="UNIDADE(1)"
                    check={form.condominiumSpec_building_checkNumberOfUnits === numberOfUnits.Unidade}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNumberOfUnits", numberOfUnits.Unidade);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Unidade");
                    }}
                  />,
                  <ItemCheckBox
                    key="DEZENA"
                    label="DEZENA(10)"
                    check={form.condominiumSpec_building_checkNumberOfUnits === numberOfUnits.Dezena}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNumberOfUnits", numberOfUnits.Dezena);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Dezena");
                    }}
                  />,
                  <ItemCheckBox
                    key="CENTENA"
                    label="CENTENA(101)"
                    check={form.condominiumSpec_building_checkNumberOfUnits === numberOfUnits.Centena}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNumberOfUnits", numberOfUnits.Centena);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Centena");
                    }}
                  />,
                  <ItemCheckBox
                    key="MILHAR"
                    label="MILHAR(1001)"
                    check={form.condominiumSpec_building_checkNumberOfUnits === numberOfUnits.Milhar}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_building_checkNumberOfUnits", numberOfUnits.Milhar);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Milhar");
                    }}
                  />
                ]}
              />
            </div>
          // )
        }
        </div>

    </div>
  );
}
