import React from 'react';
import './contract.scss';

class Contract extends React.Component {

  render() {

    return (
      <section className="container">
        <div className="contract">
          <h1 className="title">DECLARO</h1>

          <div dangerouslySetInnerHTML={{__html: 
            this.props.location === undefined || this.props.location.state === undefined || this.props.location.state.html === undefined
            ? '' : this.props.location.state.html}} />

          <div className="col-100 button-contrato">
            <button onClick={() => { this.props.history.push('/summaryData') }} className="previous">Voltar</button>
          </div>

        </div>
      </section>
    );
  }
}

export default Contract;