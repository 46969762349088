import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumbs } from './breadcrumbs'
import {withRouter} from 'react-router-dom';
import { Redirect } from 'react-router';

const Conteiner = ({breadcrumbs, match, navigation}) =>{
  
  let redirect = null
  if((!navigation || !navigation.currentStep) && match.path !=='/condominiumRegistration'){
    redirect = (<Redirect to="/condominiumRegistration" />)
  }else 
  if(navigation.currentStep && navigation.currentStep.URL && navigation.currentStep.URL !== match.path){
    redirect = (<Redirect to={navigation.currentStep.URL} />)
  }
  return (
    <>
      <Breadcrumbs {...breadcrumbs} />
      {redirect}
    </>
  )
}


const getBreadcrumbsData = navigation =>{
  let defaultStep = {Name:"Cadastro de Condomínio",Id:0}
  let title = "Cadastro de Condomínio";
  let steps = [{ className:""}, {className:""}]
  let number = 1;
  let isMobileMode  = window.innerWidth <= 576;

  if (navigation.navigations.Groups) {
      
    let { currentStep } = navigation
    let areadyActive = false
    let className ="";
    
    
    if (!currentStep || !currentStep.hasOwnProperty("Id")){
      currentStep = defaultStep
    }

    title = currentStep.Name
    
    steps = navigation.navigations.Groups.map((grupo, index) =>{
      
      className = "breadcrumb-done"
      
      if (areadyActive)
        className = ""
    
      if (grupo.Id === currentStep.Id) {
        className = "breadcrumb-active"
        areadyActive = true
        number = index + 1;
      }
      
      return {className};
    })

    
  }
  
  if(isMobileMode){
    steps = [{className:"breadcrumb-text", text: steps.length }];
  }

  return {title, steps, number};
}

const mapStateToProps = (state) => ({
  breadcrumbs:getBreadcrumbsData(state.navigationStepsReducer),
  navigation:state.navigationStepsReducer
})

export default connect(
  mapStateToProps
)(withRouter(Conteiner))
