export const CHANGE_CONDOMINIUN_DATA = 'CHANGE_CONDOMINIUN_DATA';
export const SET_CITIES = 'SET_CITIES';
export const SET_FILTERED_CITIES = 'SET_FILTERED_CITIES';
export const SET_FORM = 'SET_FORM';
export const SET_LOADING_CONDOMINIUM_REGISTRATION = "SET_LOADING_CONDOMINIUM_REGISTRATION";
export const SET_LOADING_BTN_CONDOMINIUM_REGISTRATION = "SET_LOADING_BTN_CONDOMINIUM_REGISTRATION";
export const SET_FORM_INITIAL_STATE = "SET_FORM_INITIAL_STATE";
export const SET_ID_CONDOMINIUM_REGISTRATION = "SET_ID_CONDOMINIUM_REGISTRATION";
export const SET_CONDOMINIUM_CODE = "SET_CONDOMINIUM_CODE";


