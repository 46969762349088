import React, { useState } from 'react';
import './detailHouse.scss';

import Field from "../../../components/field";
import AreaField from "../../../components/areaField";
import Table from "../../../components/table";
import AddIcon from "../../../assets/images/add.png";
import Button from "../../../components/button";
import { Icon, Modal } from '@material-ui/core';
import SuperButton from "../../../components/button";
import { DialogTitle, Dialog, DialogContent, DialogActions, TextField, InputAdornment, Button as MuiButton } from '@mui/material';

const initialModal = {
  isOpen: false,
  title: '',
  houseName: '',
  streetId: null
}

export default function DetailHouse({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const { onDeleteStreet, onChangeStreetName, onChangeUnitCount, onAddStreet, onDeleteUnitHouse, onAddUnitHouse } = props; // actions

  const [modalAdd, setModalAdd] = useState(initialModal);

  const openAddModal = (streetId) => {
    setModalAdd(prev => (
      {
        ...prev, 
        isOpen: true,
        streetId
      }
    ))
  }

  const closeModal = () => {
    setModalAdd(initialModal);
  }

  const inputModalChange  = (value) => {
    setModalAdd(prev => (
      {
        ...prev, 
        houseName: value       
      }
    ))
  }

  const confirmModal = () => {
    onAddUnitHouse(modalAdd.streetId, `Casa ${modalAdd.houseName}`);
    closeModal();
  }

  const getTable = () => {

    const rowsTable = [{
      head: true,
      columns: [
        { content: 'NOME DA RUA', style: { width: '50%' } },
        { content: 'UNIDADES', style: {} },
        { content: '', style: { width: '5%' } }
      ]
    }];

    if (!form.condominiumSpec_house_streets) {
      this.props.history.push("/condominiumSpecifications");
    }    

    form.condominiumSpec_house_streets.map((street, index) => {
      return rowsTable.push({
        head: false,
        columns: [
          { // Nome Rua
            content:              
              <Field
                type="text"
                required={true}
                name={`streetName${index}`}
                label=""
                minlength={3}
                maxlength={250}
                value={form.condominiumSpec_house_streets.find(x => x.id === street.id) ? form.condominiumSpec_house_streets.find(x => x.id === street.id).name : ""}
                onChange={(field, value) => {
                  onChangeStreetName(street.id, value);
                }}
                returnObject={(self) => createRefFields(self)}
                noAsterisk
                style={{ margin: 0, padding: 0, width: '100%', height: '85px'}}
                validateSync={(value) => form.condominiumSpec_house_streets.filter(x => x.name.trim().toUpperCase() === value.trim().toUpperCase()).length === 1}
                validateSyncMessage="Rua já existe!"
              />
          },         
          { // Unidades
            content: 
              <div className="units-house">
                <Field
                  type="text"
                  required={true}
                  name={`units${index}`}
                  label=""
                  value={form.condominiumSpec_house_streets.find(x => x.id === street.id) ? form.condominiumSpec_house_streets.find(x => x.id === street.id).units : ""}
                  onChange={() => null}
                  returnObject={(self) => createRefFields(self)}
                  noAsterisk
                  style={{ margin: 0, padding: 0}}
                  hidden
                />
                {
                  street.units.map((unit, index) => {
                    return (
                      <button 
                        className="btn-delete-unit"
                        title="Deletar unidade"
                        key={index} 
                        onClick={(ev) => {
                          ev.preventDefault();
                          onDeleteUnitHouse(street.id, unit.id);
                        }} 
                      >
                        {unit.name}&nbsp;<Icon>cancel</Icon>
                      </button>
                    )
                  })
                }
                <SuperButton 
                  classes="btn-add-unit"
                  onclick={() => openAddModal(street.id)}
                  content="ADICIONAR"
                />                
              </div>
              // <Field
              //   type="text"
              //   required={true}
              //   name={`unitCount${index}`}
              //   label=""
              //   maxlength={5}
              //   mask="99999"
              //   value={form.condominiumSpec_house_streets.find(x => x.id === street.id) ? form.condominiumSpec_house_streets.find(x => x.id === street.id).unitCount : ""}
              //   onChange={(field, value) => {
              //     onChangeUnitCount(street.id, value);
              //   }}
              //   returnObject={(self) => createRefFields(self)}
              //   noAsterisk
              //   style={{ margin: 0, padding: 0}}
              //   validateSync={() => parseInt(form.condominiumSpec_house_streets.find(x => x.id === street.id).unitCount) > 0}
              // />
          },
          {
            content: 
            <button
                className="bt-delete-street"
                onClick={(ev) => {ev.preventDefault(); onDeleteStreet(street.id)}} 
                title="Deletar rua"
              >
                <Icon>delete</Icon>
              </button>,
            style: {paddingLeft: '0', paddingRight: '0', textAlign: 'center', cursor: 'pointer'}
          }
        ]
      });
    })

    return rowsTable;
  }

  return (
    <div className="detailHouse">
      <Table list={getTable()} />
      <Button 
        onclick={() => onAddStreet()}
        content={<>ADICIONAR RUA <img src={AddIcon} alt="adicionar" /></>} 
      />

      <Dialog open={modalAdd.isOpen} onClose={closeModal}>        
        <DialogTitle>
          Adicionar casa
        </DialogTitle>
        <DialogContent sx={{display:'flex', alignItems:"center", justifyContent:'center'}}>
          <TextField
          variant='outlined'
          color='primary'
          InputProps={{
            startAdornment: <InputAdornment position="start">Casa</InputAdornment>,
          }}
          onChange={(ev) => inputModalChange(ev.target.value)}
          >

          </TextField>
        </DialogContent>
        <DialogActions sx={{maxHeight:50, overflow:'hidden'}}>
          <SuperButton 
          classes="btn-add-unit"
          onclick={confirmModal} 
          content='ADICIONAR'
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
