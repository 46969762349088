import React from 'react';
import './detailBuilding.scss';

import Field from "../../../components/field";
import Table from "../../../components/table";
import AddIcon from "../../../assets/images/add.png";
import SuperButton from "../../../components/button";
import { Icon } from '@material-ui/core';


export default function DetailBuilding({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const { 
    onChangeFloorName, 
    onChangeUnitsNamesBuilding, 
    onDeleteFloorBuilding, 
    onAddFloorBuilding,
    onDeleteUnitBuilding,
    onAddUnitBuilding
  } = props; // actions

  const getTable = () => {

    const rowsTable = [{
      head: true,
      columns: [
        { content: 'PAVIMENTO', style: { width: '30%' } },
        { content: 'UNIDADES', style: {} },
        { content: '', style: { width: '5%' } }
      ]
    }];

    form.condominiumSpec_building_floors.map((floor, index) => {
      return rowsTable.push({
        head: false,
        columns: [
          { // Pavimento -- Nome do andar
            content:              
              <Field
                type="text"
                required={true}
                name={`floor${index}`}
                label=""
                minlength={3}
                maxlength={250}
                value={form.condominiumSpec_building_floors.find(x => x.id === floor.id) ? form.condominiumSpec_building_floors.find(x => x.id === floor.id).name : ""}
                onChange={(field, value) => {
                  onChangeFloorName(floor.id, value);
                }}
                returnObject={(self) => createRefFields(self)}
                noAsterisk
                style={{ margin: 0, padding: 0}}
                validateSync={(value) => form.condominiumSpec_building_floors.filter(x => x.name.trim().toUpperCase() === value.trim().toUpperCase()).length === 1}
                validateSyncMessage="Pavimento já existe!"
              />
          },         
          { // Unidades
            content:
              <div className="units-building">
                <Field
                  type="text"
                  required={true}
                  name={`units${index}`}
                  label=""
                  value={form.condominiumSpec_building_floors.find(x => x.id === floor.id) ? form.condominiumSpec_building_floors.find(x => x.id === floor.id).units : ""}
                  onChange={() => null}
                  returnObject={(self) => createRefFields(self)}
                  noAsterisk
                  style={{ margin: 0, padding: 0}}
                  hidden
                />
                {
                  floor.units.map((unit, index) => {
                    return (
                      <button 
                        className="btn-delete-unit"
                        title="Deletar unidade"
                        key={index} 
                        onClick={(ev) => {
                          ev.preventDefault();
                          onDeleteUnitBuilding(floor.id, unit.id);
                        }} 
                      >
                        {unit.name}&nbsp;<Icon>cancel</Icon>
                      </button>
                    )
                  })
                }
                <SuperButton 
                  classes="btn-add-unit"
                  onclick={() => onAddUnitBuilding(floor.id)}
                  content="ADICIONAR"
                />
              </div>
          },
          {
            content: 
              <button
                className="bt-delete-floor"
                onClick={(ev) => {ev.preventDefault(); onDeleteFloorBuilding(floor.id)}} 
                title="Deletar andar"
              >
                <Icon>delete</Icon>
              </button>,
            style: {paddingLeft: '0', paddingRight: '0', textAlign: 'center'}
          }
        ]
      });
    })

    return rowsTable;
  }

  return (
    <div className="detailBuilding">
      <Table list={getTable()} />
      <SuperButton 
        onclick={() => onAddFloorBuilding()}
        content={<>ADICIONAR ANDAR <img src={AddIcon} alt="adicionar" /></>} 
      />
    </div>
  );
}