import React from 'react'

const conditions = (condition, value1, value2) => {
  switch(condition){
    case "=": return value1 === value2
    case "<>": return value1 !== value2
    default: return 
  }
}

const validateList = (listValidations, otherValues) => {

  return listValidations.find(v => {
    return false === conditions(v.test, v.value, otherValues ? otherValues[v.field] : null)
  }) ? false : true;
}

const validate = (validations, otherValues) => {
  let result = {};

  for(let key in validations){
    switch(key){
      case "required": result = {...result, ...{[key]: validateList(validations[key], otherValues)}}; break;
      case "visible": result = {...result, ...{[key]: validateList(validations[key], otherValues)}}; break;
      default: break;
    }
  } 

  return result;
}

export const CheckObligation = ({steps, children, fieldConditional, fieldKey}) => {
  let field = null;
  //let steps = props.currentStep.Fields.filter(e=>e);

  if (steps)
    field = steps.find((c) => c.key === fieldKey);
  
  let resultadoValidacao = {};
  if (field) {
    let showChildren = true;
    if (field.validations) {
      resultadoValidacao = validate(field.validations, fieldConditional);

      let { visible } =  resultadoValidacao;
      if(visible === false) showChildren = false;   
    } else {
      resultadoValidacao = field;
    }

    return (
      <>
        {showChildren && children(resultadoValidacao)}
      </>
    )
  } else return null;
}
