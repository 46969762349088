import React from 'react';
import './cards.scss';

function ItemCard({ check, classes, onchange, children, width, height }) {

  return (
    <div
      className={`item-card ${classes} ${check ? 'selected' : ''}`}
      // style={{ width: width, height: height }}
      onClick={onchange}
    >
      {children}
    </div>
  );
}

export default ItemCard;

