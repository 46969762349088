import React, { Component } from 'react'


class Form extends Component {

  render() {
    let {name, children} = this.props;
    return (
      <form name={name} noValidate={true}>
        {children}
      </form>
    )
  }
}

export default Form