import { 
  CHANGE_PAYMENT_DATA,
  LOADING_BTN_PAYMENT  
} from './types'

import { 
  brands
} from '../../helper/constants';

const initialState = {
  form: {
    payment_credit_card_brand: brands.Elo,
    payment_credit_card_number: '',
    payment_credit_card_expiration_date: '',
    payment_credit_card_cvv: '',
    payment_credit_card_holder_name: '',
    payment_credit_card_holder_identification: '',
  }, 
  loadingBtnPayment: false
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAYMENT_DATA:
      let newForm = state.form;
      newForm[action.payload.field] = action.payload.value;
      return {
        ...state,
        form: newForm
      }
    case LOADING_BTN_PAYMENT:
      return {
        ...state,
        loadingBtnPayment: action.payload
      }    
    default:
      return state
  }
}
  
  
  