import React from 'react';
import './condominium.scss';

import Field from "../../../components/field";
import CheckObligation from "../../../components/checkObligation";
import Cards from '../../../components/cards';
import ItemCard from '../../../components/cards/itemCard';
import { blocksCount, nameOfUnits, numberOfUnits } from '../../../helper/constants';
import CheckBox from '../../../components/checkBox';
import ItemCheckBox from '../../../components/checkBox/itemCheckBox';

export default function Condominium({ props, createRefFields }) {

  const { form } = props.condominiumSpecificationsReducer; // reducer
  const { onChangeCondominiumSpecificationsData } = props; // actions

  return (
    <div>
      <CheckObligation
        field="condominiumSpec_condominium_blocksCount"
        fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
      >
        {(validations) => (
          <div className="col condominiumSpec_condominium_blocks">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_condominium_blocksCount"
              label="QUANTOS BLOCOS POSSUI SEU CONDOMÍNIO?"
              value={form.condominiumSpec_condominium_blocksCount}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              mask="999"
              style={{ width: '40%' }}
            />

            {/* <Cards
              classesCards="col-30"
              label="QUANTOS BLOCOS POSSUI NO SEU CONDOMINIO ?"
              items={[
                <ItemCard
                  key={1}
                  check={form.condominiumSpec_condominium_blocksCount === blocksCount.Two}
                  classes="cards-cond-blocks"
                  onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_condominium_blocksCount", blocksCount.Two)}
                  children={(<div>2</div>)}
                  width="60px"
                  height="60px"
                />,
                <ItemCard
                  key={2}
                  check={form.condominiumSpec_condominium_blocksCount === blocksCount.Three}
                  classes="cards-cond-blocks"
                  onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_condominium_blocksCount", blocksCount.Three)}
                  children={(<div>3</div>)}
                  width="60px"
                  height="60px"
                />,
                <ItemCard
                  key={3}
                  check={form.condominiumSpec_condominium_blocksCount === blocksCount.Four}
                  classes="cards-cond-blocks"
                  onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_condominium_blocksCount", blocksCount.Four)}
                  children={(<div>4</div>)}
                  width="60px"
                  height="60px"
                />,
                <ItemCard
                  key={4}
                  check={form.condominiumSpec_condominium_blocksCount === blocksCount.FiveOrMore}
                  classes="cards-cond-blocks"
                  onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_condominium_blocksCount", blocksCount.FiveOrMore)}
                  children={(<div>5+</div>)}
                  width="60px"
                  height="60px"
                />,
              ]}
            /> */}
          </div>

        )}
      </CheckObligation>

      <CheckObligation
        field="condominiumSpec_condominium_code"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_condominium_code">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_condominium_code"
              label="CÓDIGO DO CONDOMÍNIO"
              minlength={2}
              mask="SSSS"
              value={form.condominiumSpec_condominium_code}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value.toUpperCase())}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ width: '40%' }}
            />
          </div>
        )}
      </CheckObligation>

      <div className='condominiumSpec_condominium_checkNameOfUnits'>
        <CheckObligation
          field="condominiumSpec_condominium_checkNameOfUnits"
          fieldConditional={{ condominiumSpec_type: form.condominiumSpec_type }}
        >
          {(validations) => (
            <div className="col">
              <Field
                type="text"
                required={validations.required}
                name="condominiumSpec_condominium_checkNameOfUnits"
                label=""
                value={form.condominiumSpec_condominium_checkNameOfUnits}
                onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                returnObject={(self) => createRefFields(self)}
                hidden={true}
                noAsterisk
              />

              <CheckBox
                label="COMO DESEJA CHAMAR CADA UNIDADE DO SEU PRÉDIO ?"
                arrayItems={[
                  <ItemCheckBox
                    key={1}
                    label="SALA"
                    check={form.condominiumSpec_condominium_checkNameOfUnits === nameOfUnits.Sala}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNameOfUnits", nameOfUnits.Sala);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_nameOfUnits", "Sala");
                    }}
                  />,
                  <ItemCheckBox
                    key={2}
                    label="APARTAMENTO"
                    check={form.condominiumSpec_condominium_checkNameOfUnits === nameOfUnits.Apartamento}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNameOfUnits", nameOfUnits.Apartamento);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_nameOfUnits", "Apartamento");
                    }}
                  />,
                  <ItemCheckBox
                    key={3}
                    label="LOJA"
                    check={form.condominiumSpec_condominium_checkNameOfUnits === nameOfUnits.Loja}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNameOfUnits", nameOfUnits.Loja);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_nameOfUnits", "Loja");
                    }}
                  />,
                  <ItemCheckBox
                    key={4}
                    label="ESCRITÓRIO"
                    check={form.condominiumSpec_condominium_checkNameOfUnits === nameOfUnits.Escritório}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNameOfUnits", nameOfUnits.Escritório);
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_nameOfUnits", "Escritório");
                    }}
                  />,
                  <ItemCheckBox
                    key={5}
                    label="OUTRO"
                    check={form.condominiumSpec_condominium_checkNameOfUnits === nameOfUnits.Outro}
                    onchange={() => {
                      onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNameOfUnits", nameOfUnits.Outro);
                    }}
                  />
                ]}
              />
            </div>
          )}
        </CheckObligation>

        <CheckObligation
          field="condominiumSpec_condominium_nameOfUnits"
          fieldConditional={{
            condominiumSpec_type: form.condominiumSpec_type,
            condominiumSpec_condominium_checkNameOfUnits: form.condominiumSpec_condominium_checkNameOfUnits
          }}
        >
          {(validations) => (
            <div className="col condominiumSpec_condominium_nameOfUnits">
              <Field
                type="text"
                required={validations.required}
                name="condominiumSpec_condominium_nameOfUnits"
                label="COMO DESEJA CHAMAR A SUA UNIDADE ?"
                minlength={1}
                maxlength={50}
                value={form.condominiumSpec_condominium_nameOfUnits}
                onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                returnObject={(self) => createRefFields(self)}
                noAsterisk
                style={{ margin: 0, width: '100%' }}
              />
            </div>
          )}
        </CheckObligation>
      </div>

      <CheckObligation
        field="condominiumSpec_condominium_floors"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_condominium_floors">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_condominium_floors"
              label="QUANTIDADE DE ANDAR POR BLOCO"
              mask="999"
              value={form.condominiumSpec_condominium_floors}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_condominium_floors) > 0}
            />
          </div>
        )}
      </CheckObligation>

      <CheckObligation
        field="condominiumSpec_condominium_unitsCount"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_condominium_unitsCount">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_condominium_unitsCount"
              label="QUANTIDADE DE UNIDADES POR ANDAR EM CADA BLOCO"
              mask="999"
              value={form.condominiumSpec_condominium_unitsCount}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              noAsterisk
              style={{ width: '40%' }}
              validateSync={() => parseInt(form.condominiumSpec_condominium_unitsCount) > 0}
            />
          </div>
        )}
      </CheckObligation>

      <CheckObligation
        field="condominiumSpec_condominium_checkNumberOfUnits"
        fieldConditional={{
          condominiumSpec_type: form.condominiumSpec_type
        }}
      >
        {(validations) => (
          <div className="col condominiumSpec_condominium_checkNumberOfUnits">
            <Field
              type="text"
              required={validations.required}
              name="condominiumSpec_condominium_checkNumberOfUnits"
              label=""
              value={form.condominiumSpec_condominium_checkNumberOfUnits}
              onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
              returnObject={(self) => createRefFields(self)}
              hidden={true}
              noAsterisk
            />
            <CheckBox
              label="COMO PODEMOS INICIAR A NUMERAÇÃO DAS UNIDADES?"
              arrayItems={[
                <ItemCheckBox
                  key="UNIDADE"
                  label="UNIDADE(1)"
                  check={form.condominiumSpec_condominium_checkNumberOfUnits === numberOfUnits.Unidade}
                  onchange={() => {
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNumberOfUnits", numberOfUnits.Unidade);
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Unidade");
                  }}
                />,
                <ItemCheckBox
                  key="DEZENA"
                  label="DEZENA(10)"
                  check={form.condominiumSpec_condominium_checkNumberOfUnits === numberOfUnits.Dezena}
                  onchange={() => {
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNumberOfUnits", numberOfUnits.Dezena);
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Dezena");
                  }}
                />,
                <ItemCheckBox
                  key="CENTENA"
                  label="CENTENA(101)"
                  check={form.condominiumSpec_condominium_checkNumberOfUnits === numberOfUnits.Centena}
                  onchange={() => {
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNumberOfUnits", numberOfUnits.Centena);
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Centena");
                  }}
                />,
                <ItemCheckBox
                  key="MILHAR"
                  label="MILHAR(1001)"
                  check={form.condominiumSpec_condominium_checkNumberOfUnits === numberOfUnits.Milhar}
                  onchange={() => {
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_checkNumberOfUnits", numberOfUnits.Milhar);
                    onChangeCondominiumSpecificationsData("condominiumSpec_condominium_numberOfUnits", "Milhar");
                  }}
                />
              ]}
            />
          </div>
        )}
      </CheckObligation>


    </div>
  );
}
