import React from 'react';
import './checkBox.scss';


function CheckBox({label, arrayItems}) {

  return (
    <div className="checkbox-component">
      <div className="label-checkbox-component">{label}</div>
      <div className="items-checkbox-component">
        {arrayItems.map(x => x)}
      </div>
  </div>
  );
}

export default CheckBox;

