import React from 'react';
import './condominiumSpecifications.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';
import Field from "../../components/field";
import Form from "../../components/form";
import CheckObligation from "../../components/checkObligation";
import { condominiumTypes } from '../../helper/constants';
import OptionsBuilding from "./building";
import OptionsHouse from "./house";
import OptionsCondominium from "./condominium";
import Cards from '../../components/cards';
import ItemCard from '../../components/cards/itemCard';
import CircularProgress from '@material-ui/core/CircularProgress';

class CondominiumSpecifications extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getCondominiumSpecificationsData();
    this.formFields = []
  }
  render() {

    let { form, loadingCondominiumSpecifications } = this.props.condominiumSpecificationsReducer; // reducer

    let { onChangeCondominiumSpecificationsData, onChangeStreetsHouse, onChangeFloorsBuilding, onChangeBlocksCondominium } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        {loadingCondominiumSpecifications || !form ? <CircularProgress color="inherit"/> : (
           <div className="col">
           <Form name="condominiumSpecifications" noValidate={false}>
 
             {/* TIPO CONDOMÍNIO */}
             <CheckObligation field="condominiumSpec_type">
               {(validations) => (
                 <div className="col condominiumSpecifications">
                   <Field
                     type="text"
                     required={validations.required}
                     name="condominiumSpec_type"
                     label=""
                     value={form.condominiumSpec_type}
                     onChange={(field, value) => onChangeCondominiumSpecificationsData(field, value)}
                     returnObject={this.createRefFields}
                     hidden={true}
                     noAsterisk
                   />
 
                   <Cards
                     label=""
                     items={[
                       <ItemCard
                         key={1}
                         check={form.condominiumSpec_type === condominiumTypes.House}
                         classes="house"
                         onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_type", condominiumTypes.House)}
                         children={(
                           <div className="itemCard">
                             <span>CONDOMÍNIO DE CASA</span>
                             <span></span>
                           </div>
                         )}
                         width="304px"
                         height="324px"
                       />,
                       <ItemCard
                         key={2}
                         check={form.condominiumSpec_type === condominiumTypes.Building}
                         classes="one-block"
                         onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_type", condominiumTypes.Building)}
                         children={(
                           <div className="itemCard">
                             <span>PRÉDIO</span>
                             <span>APENAS UM BLOCO</span>
                           </div>
                         )}
                         width="304px"
                         height="324px"
                       />,
                       <ItemCard
                         key={3}
                         check={form.condominiumSpec_type === condominiumTypes.Condominium}
                         classes="multiples-blocks"
                         onchange={() => onChangeCondominiumSpecificationsData("condominiumSpec_type", condominiumTypes.Condominium)}
                         children={(
                           <div className="itemCard">
                             <span>CONDOMÍNIO</span>
                             <span>DOIS OU MAIS BLOCOS</span>
                           </div>
                         )}
                         width="304px"
                         height="324px"
                       />
                     ]}
                   />
                 </div>
               )}
             </CheckObligation>
 
             <OptionsHouse props={this.props} createRefFields={this.createRefFields} />
 
             <OptionsBuilding props={this.props} createRefFields={this.createRefFields} />
 
             <OptionsCondominium props={this.props} createRefFields={this.createRefFields} />
 
           </Form>
         </div>
        )}       

        <div className="col">
          <NavigationButtons 
            history={this.props.history} 
            validateFields={this.validateFields} 
            validationAction={() => true}
            disabledNext={false}
            noAsterisk 
            style={{ margin: 0 }}
            actionAfter={() => {
              if (form.condominiumSpec_type === condominiumTypes.House)
                onChangeStreetsHouse();
              else if (form.condominiumSpec_type === condominiumTypes.Building)
                onChangeFloorsBuilding();
              else if (form.condominiumSpec_type === condominiumTypes.Condominium)
                onChangeBlocksCondominium();
            }}
          />
        </div>
      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default CondominiumSpecifications;
