import { connect } from 'react-redux'
import CondominiumSpecifications from './condominiumSpecifications'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { onChangeCondominiumSpecificationsData, onChangeStreetsHouse, onChangeFloorsBuilding, onChangeBlocksCondominium, getCondominiumSpecificationsData } from '../../store/condominiumSpecifications/actions'

const mapStateToProps = (state, nextState) => ({
  condominiumSpecificationsReducer: state.condominiumSpecificationsReducer,
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  onChangeCondominiumSpecificationsData,
  onChangeStreetsHouse,
  onChangeFloorsBuilding,
  onChangeBlocksCondominium,
  getCondominiumSpecificationsData
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CondominiumSpecifications));
