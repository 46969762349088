import { 
  CHANGE_CONDOMINIUN_DATA,
  SET_CITIES,
  SET_FILTERED_CITIES,
  SET_FORM,
  SET_LOADING_CONDOMINIUM_REGISTRATION,
  SET_LOADING_BTN_CONDOMINIUM_REGISTRATION,
  SET_FORM_INITIAL_STATE,
  SET_ID_CONDOMINIUM_REGISTRATION,
  SET_CONDOMINIUM_CODE
} from './types'

const initialState = {
  loadingCondominiumRegistration: false,
  loadingBtnCondominiumRegistration: false,
  form: {
    condominiumRegistration_condominiumId: '',
    condominiumRegistration_condominiumCode: '',
    condominiumRegistration_condominiumName: '',
    condominiumRegistration_condominiumStreet: '',
    condominiumRegistration_condominiumNumber: '',
    condominiumRegistration_additionCondominiumStreet: '',
    condominiumRegistration_condominiumSate: '',
    condominiumRegistration_condominiumCity: '',
    condominiumRegistration_condominiumNeighborhood: '',
    condominiumRegistration_condomimiumCep: '',
    condominiumRegistration_cnpj: '',
    condominiumRegistration_condomimiumPhone: '',
    condominiumRegistration_condomimiumEmail: ''
  },
  cities: [],
  filteredCities: [],
}

export default function condominiumRegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CONDOMINIUN_DATA:
      let newForm = state.form;
      newForm[action.payload.field] = action.payload.value;
      return {
        ...state,
        form: newForm
      }
    case SET_CITIES:
      return { ...state, cities: action.payload }
    case SET_FILTERED_CITIES:
      return { ...state, filteredCities: action.payload }
    case SET_FORM:
      return { ...state, form: action.payload }
    case SET_LOADING_CONDOMINIUM_REGISTRATION:
      return { ...state, loadingCondominiumRegistration: action.payload }
    case SET_LOADING_BTN_CONDOMINIUM_REGISTRATION:
      return { ...state, loadingBtnCondominiumRegistration: action.payload }
    case SET_CONDOMINIUM_CODE:
      return { 
        ...state,
        form: {...state.form, condominiumRegistration_condominiumCode: action.payload }
      }
    case SET_ID_CONDOMINIUM_REGISTRATION:
      const form = { ...state.form, condominiumRegistration_condominiumId: action.payload }
      return { ...state, form: form }
    case SET_FORM_INITIAL_STATE:
      return { 
        ...state, 
        form: {
          condominiumRegistration_condominiumId: '',
          condominiumRegistration_condominiumName: '',
          condominiumRegistration_condominiumStreet: '',
          condominiumRegistration_condominiumNumber: '',
          condominiumRegistration_additionCondominiumStreet: '',
          condominiumRegistration_condominiumSate: '',
          condominiumRegistration_condominiumCity: '',
          condominiumRegistration_condominiumNeighborhood: '',
          condominiumRegistration_condomimiumCep: '',
          condominiumRegistration_cnpj: '',
          condominiumRegistration_condomimiumPhone: '',
          condominiumRegistration_condomimiumEmail: ''
        }
      }
    default:
      return state
  }
}


