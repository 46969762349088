import React from 'react';
import './administrativeHead.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';

import Field from "../../components/field";
import Form from "../../components/form";
import CheckObligation from "../../components/checkObligation";
import { Icon } from '@material-ui/core';
import masksPure from '../../helper/masksPure';
import validateUtils from '../../helper/validateUtils';
// import utils from "../../helper/validateUtils";

class AdministrativeHead extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    let { form } = this.props.administrativeHeadReducer;
    this.formFields = [];
    let { getNavigationStepsAction } = this.props;
    getNavigationStepsAction();

    this.validatePass(form.admHead_password)
  }

  validatePass = (pass) => {
    pass = pass.trim();

    let { onChangeAdministrativeHeadValidationPass } = this.props;

    let { validationRulesPass } = this.props.administrativeHeadReducer;
    let validPass = true;

    validationRulesPass.eightChar = pass.length >= 8;
    validationRulesPass.specialChar = !["*", "!", "-", "@"].every(subStr => {
      return !pass.includes(subStr);
    });
    validationRulesPass.upperCaseChar = /[A-Z]/.test(pass);
    validationRulesPass.lowerCaseChar = /[a-z]/.test(pass);
    validationRulesPass.numericChar = /\d/.test(pass);

    for (const [key, value] of Object.entries(validationRulesPass)) {
      if (!value) {
        validPass = false;
      }      
    }

    onChangeAdministrativeHeadValidationPass(validationRulesPass, validPass);
  }

  render() {

    let { form, validationRulesPass, validPass, loading } = this.props.administrativeHeadReducer; // reducer

    let { onChangeAdministrativeHeadData, saveDataAdministrativeHead } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        <div className="col administrativeHead">
          <Form name="administrativeHead" noValidate={false}>
            <div className="flex-space-b">
              <CheckObligation field="admHead_name">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                              type="text"
                              required={validations.required}
                              label="NOME"
                              name="admHead_name"
                              maxlength={150}
                              minlength={3}
                              value={form.admHead_name}
                              onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                              returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation> 

              <CheckObligation field="admHead_cpf">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                            type="cpf"
                            required={validations.required}
                            label="CPF"
                            name="admHead_cpf"
                            mask="999.999.999-99"
                            maxlength={150}
                            minlength={3}
                            value={form.admHead_cpf}
                            validateSync={() => validateUtils.isCPF(form.admHead_cpf)}
                            onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                            returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation>    
            </div>

            <div className="flex-space-b">
              <CheckObligation field="admHead_RG">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                            type="text"
                            required={validations.required}
                            label="RG"
                            name="admHead_RG"
                            mask='99.999.999-9'
                            maxlength={12}      
                            validateSync={() => form.admHead_RG.length === 12}                      
                            value={form.admHead_RG}
                            onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                            returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation> 

              <CheckObligation field="admHead_issuer">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                            type="text"
                            required={validations.required}
                            label="ÓRGÃO EMISSOR"
                            name="admHead_issuer"
                            maxlength={150}                            
                            value={form.admHead_issuer}
                            onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                            returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation>    
            </div>

            <div className="flex-space-b">
              <CheckObligation field="admHead_email">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                            type="email"
                            required={validations.required}
                            label="EMAIL"
                            name="admHead_email"
                            maxlength={50}
                            minlength={3}
                            value={form.admHead_email}
                            onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                            returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation>

              <CheckObligation field="admHead_jobRole">
                  {(validations) => (
                      <div className="col-45">
                          <Field
                            type="text"
                            required={validations.required}
                            label="FUNÇÃO"
                            name="admHead_jobRole"
                            maxlength={50}
                            minlength={3}
                            value={form.admHead_jobRole}
                            onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                            returnObject={this.createRefFields}
                          />
                      </div>
                  )}
              </CheckObligation>
            </div>

            <div className="flex-space-b">
              <CheckObligation field="admHead_password">
                {(validations) => (
                  <div style={{position: 'relative'}} className="col-45">
                    <Field
                      type="password"
                      required={validations.required}
                      label="SENHA"
                      name="admHead_password"
                      maxlength={50}
                      minlength={8}
                      value={form.admHead_password}
                      onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                      returnObject={this.createRefFields}
                      keyup={() => this.validatePass(form.admHead_password)}
                      validateSync={() => validPass}
                    />
                    <Icon 
                    fontSize='small'
                    onPointerDown={() => {this.formFields['admHead_password'].input.type = 'text'}}                    
                    onPointerUp={() => {this.formFields['admHead_password'].input.type = 'password'}}                    
                    style={{  
                      position: 'absolute',                    
                      top:40,
                      right:10,                       
                      opacity: 0.5,
                      cursor: 'pointer'
                      }}>
                        visibility
                      </Icon>
                  </div>
                )}
              </CheckObligation>

              <CheckObligation field="admHead_confirmPassword">
                {(validations) => (
                  <div style={{position:"relative"}} className="col-45">
                    <Field
                      type="password"
                      required={validations.required}
                      label="CONFIRMAR SENHA"
                      name="admHead_confirmPassword"
                      maxlength={50}
                      minlength={8}
                      value={form.admHead_confirmPassword}
                      onChange={(field, value) => onChangeAdministrativeHeadData(field, value)}
                      returnObject={this.createRefFields}
                      validateSync={() => form.admHead_confirmPassword === form.admHead_password}
                    />
                    <Icon 
                    fontSize='small'
                    onPointerDown={() => {this.formFields['admHead_confirmPassword'].input.type = 'text'}}                    
                    onPointerUp={() => {this.formFields['admHead_confirmPassword'].input.type = 'password'}}                    
                    style={{
                      position:'absolute', 
                      top:40,
                      right:10, 
                      opacity: 0.5,
                      cursor: 'pointer'
                      }}>
                        visibility
                    </Icon>
                  </div>
                )}
              </CheckObligation>
            </div>

            <div className="pass-validation">
              <ul>
                <li className={validationRulesPass.eightChar ? "color-green" : "color-red"}>8 CARACTERES</li>
                <li className={validationRulesPass.specialChar ? "color-green" : "color-red"}>1 CARACTERE ESPECIAL (@, *, -, !)</li>
                <li className={validationRulesPass.upperCaseChar ? "color-green" : "color-red"}>1 CARACTERE MAIÚSCULO</li>
                <li className={validationRulesPass.lowerCaseChar ? "color-green" : "color-red"}>1 CARACTERE MINÚSCULO</li>
                <li className={validationRulesPass.numericChar ? "color-green" : "color-red"}>1 CARACTERE NUMÉRICO</li>
              </ul>
            </div>
            
          </Form>
        </div>
        
        <div className="col">
          <NavigationButtons 
            history={this.props.history} 
            validateFields={this.validateFields} 
            validationAction={() => saveDataAdministrativeHead()}
            disabledNext={loading}
          />
        </div>
      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default AdministrativeHead;
