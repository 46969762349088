import { connect } from 'react-redux';
import SummaryData from './summaryData';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { onChangeSummaryData, getTerm, acceptTerm } from '../../store/summaryData/actions';
import { setConclusionMessage } from '../../store/conclusion/actions';

const mapStateToProps = (state, nextState) => ({
    reducers: {
      condominiumRegistration: state.condominiumRegistrationReducer,
      administrativeHead: state.administrativeHeadReducer,
      condominiumSpecifications: state.condominiumSpecificationsReducer,
      summaryData: state.summaryDataReducer,
    }    
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  onChangeSummaryData,
  setConclusionMessage,
  getTerm,
  acceptTerm
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SummaryData));
