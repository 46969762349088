import * as Types from './types';

const initialState = {
  subscription: null,
  subscriptionList: [],
  couponInfo: null,
  couponLoading: false,
  loading: false
}

export default function choosePlanReducer(state = initialState, { type, payload }) {
  switch (type) {

    case Types.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload
      }

    case Types.SET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: payload
      }

    case Types.SET_COUPON_INFO:
      return {
        ...state,
        couponInfo: payload
      }

    case Types.SET_COUPON_LOADING:
      return {
        ...state,
        couponLoading: payload
      }

    case Types.SET_LOADING:
      return {
        ...state,
        loading: payload
      }

    case Types.CLEAR_DATA:
      return initialState

    default:
      return state
  }
}

