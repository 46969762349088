import React from 'react'
import { Button, Card, CardContent } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import currency from '../../helper/currency'
import useCoupon from './hooks/useCoupon';

const PlanCard = ({subscription, titleColor, isSelected, includeFeature, onSelect }) => {
  
  const { getDiscountText } = useCoupon();  

  if(!subscription) return null;  

  return (
    <Card className='card'>
      <CardContent className='card-content'>
        {!!subscription?.couponInfo && subscription.couponInfo?.isValid && (
          <div className='ticket-container'>
            <div className='ticket-box'>
              {/* <svg className='svg-ticket' viewBox="0 0 55 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9373 0H51.0173C53.2274 0 54.9995 1.78 54.9995 4V12C53.9434 12 52.9305 12.4214 52.1837 13.1716C51.4369 13.9217 51.0173 14.9391 51.0173 16C51.0173 17.0609 51.4369 18.0783 52.1837 18.8284C52.9305 19.5786 53.9434 20 54.9995 20V28C54.9995 30.22 53.2274 32 51.0173 32H4.9373C3.88115 32 2.86826 31.5786 2.12144 30.8284C1.37463 30.0783 0.955078 29.0609 0.955078 28V20C3.16521 20 4.9373 18.22 4.9373 16C4.9373 14.9391 4.51775 13.9217 3.77094 13.1716C3.02412 12.4214 2.01123 12 0.955078 12V4C0.955078 2.93913 1.37463 1.92172 2.12144 1.17157C2.86826 0.421427 3.88115 0 4.9373 0ZM4.9373 4V9.08C6.14726 9.7808 7.15212 10.7893 7.8508 12.0041C8.54948 13.2189 8.91733 14.5971 8.91733 16C8.91733 17.4029 8.54948 18.7811 7.8508 19.9959C7.15212 21.2107 6.14726 22.2192 4.9373 22.92V28H51.0173V22.92C49.8073 22.2192 48.8025 21.2107 48.1038 19.9959C47.4051 18.7811 47.0373 17.4029 47.0373 16C47.0373 14.5971 47.4051 13.2189 48.1038 12.0041C48.8025 10.7893 49.8073 9.7808 51.0173 9.08V4H4.9373Z" fill="#672CB0"/>
              </svg> */}
              <span className='text-coupon'>
                -{getDiscountText(subscription.couponInfo)}
              </span>
            </div>
          </div>
        )}

        <p style={{color: titleColor}} className='title'>{subscription.name}</p>
        
        <span className='old-price'>
          <span className='rent'>R$</span> 
          <span>{currency(subscription.oldAmount)}</span>         
        </span>

        <div className='box-price'>        
          <span className='real'>
            R$
          </span>
          <span className='price'>
            {currency(subscription.amount)}
          </span>
          <span className='month'>
            / mês
          </span>
        </div>

        <p className='unit-amount'>valor {subscription.amount <= subscription.minPrice? ' mínimo ' : ''} para {subscription.units} unidades</p>
        {/* <p className='sm'>{`R$ ${currency(subscription.unitPrice)} por unidade`}</p>
        <p className='sm'>{`mínimo de R$ ${currency(subscription.minPrice)}`}</p> */}

        <Button 
        onClick={() => onSelect(subscription)}
        size='small' 
        fullWidth 
        className={'button ' + (isSelected ? 'button-contained' : 'button-outlined')} 
        variant='outlined'>
          <div>Quero esse</div>
        </Button>

        <ul className='list'>
          {
            !!includeFeature && (
              <li className='list-item'>           
                <DoneIcon className='icon-check'/>  
                <span className='sm text-center bold'>{includeFeature}</span>
              </li>
            )
          }
          {
            subscription.featureList?.map(feature => (
              <li key={feature} className='list-item'>           
                <DoneIcon className='icon-check'/>  
                <span className='sm text-center'>{feature}</span>
              </li>
            ))
          }
        </ul>

        <div className='price-per-unit'>
          <p>* Valor promocional R$ {currency(subscription.unitPrice)} por unidade</p>
        </div>
      </CardContent>
    </Card>
  )
}

export default PlanCard
