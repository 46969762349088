import React from 'react'
import { connect } from 'react-redux'
import {CheckObligation} from './checkObligation'

const Conteiner = ({currentStep, children, fieldConditional, field}) => {

  let steps = [];
  if(currentStep && currentStep.Fields)
    steps = currentStep.Fields.filter(e=>e);
  
  return (<CheckObligation steps={steps} children={children}  fieldConditional={fieldConditional} fieldKey={field} />)
}


const mapStateToProps = (state, nextState) => ({
  currentStep: state.navigationStepsReducer.currentStep
});

export default connect(
  mapStateToProps
)(Conteiner)
