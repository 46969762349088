import React, { useEffect } from 'react'
import './header.scss'
import { useDispatch } from 'react-redux'

import * as headTypes from '../../store/administrativeHead/types';
import * as planTypes from '../../store/choosePlan/types';
import * as registrationTypes from '../../store/condominiumRegistration/types';
import * as specificationsTypes from '../../store/condominiumSpecifications/types';
import * as detailUnitsTypes from '../../store/detailCondominiumUnits/types';
import * as paymentTypes from '../../store/payment/types';
import * as paymentMethodsTypes from '../../store/paymentMethods/types';
import * as summaryTypes from '../../store/summaryData/types';

const loadingTypes = [
  headTypes.SET_LOADING,
  planTypes.SET_LOADING,
  registrationTypes.SET_LOADING_BTN_CONDOMINIUM_REGISTRATION,
  registrationTypes.SET_LOADING_CONDOMINIUM_REGISTRATION,
  specificationsTypes.SET_LOADING_CONDOMINIUM_SPECIFICATIONS,
  detailUnitsTypes.SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS,
  paymentTypes.LOADING_BTN_PAYMENT,
  paymentMethodsTypes.SET_LOADING,
  summaryTypes.SET_LOADING
];

export default props => {
  const dispatch = useDispatch();

  const disablePersisterLoads = () => {    
    loadingTypes.forEach(type => {
      dispatch({type, payload: false});         
    })
  }

  useEffect(() => {
    disablePersisterLoads();
  },[]);

  return (
    <header>
      <div className="container">
        <div className="logo-container">
            <img
              className="logo"
              src={props.logoSrc}
              alt={props.logoAlt}
            />
          </div>
      </div>
    </header>
  )
}
