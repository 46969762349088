import { Axios } from "../../service/serviceBasic";
import { stepsFormGroups } from "../../service/stepsService";

import {
  SET_NAVIGATION_STEPS,
  LOADING_NAVIGATION_STEPS,
  SET_ERROR_NAVIGATION_STEPS,
  SET_CURRENT_STEP,
  SET_VALIDATE_FIELDS,
  SET_IS_VALID_FIELDS,
} from "./types";

export const setNavigationStepsAction = (steps) => ({
  type: SET_NAVIGATION_STEPS,
  payload: steps,
});

const loadingNavigationStepsAction = (flag) => ({
  type: LOADING_NAVIGATION_STEPS,
  payload: flag,
});

const setErrorNavigationStepsAction = (message) => ({
  type: SET_ERROR_NAVIGATION_STEPS,
  payload: message,
});

export const setCurrentStepAction = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});

export const getNavigationStepsAction = () => (dispatch, getState) => {
  let { navigationStepsReducer } = getState();
  let navigations = navigationStepsReducer.navigations;
  dispatch(setCurrentStepAction(navigations.Groups[0]));
};

export const setValidateFieldsAction = (flag) => ({
  type: SET_VALIDATE_FIELDS,
  payload: flag,
});

export const setIsValidFieldsAction = (flag) => ({
  type: SET_IS_VALID_FIELDS,
  payload: flag,
});
