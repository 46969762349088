import React from 'react';
import './condominiumRegistration.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';

import Field from "../../components/field";
import Dropdown from "../../components/dropdown";
import Form from "../../components/form";
import CheckObligation from "../../components/checkObligation";
import { states } from '../../helper/constants';
import maskPure from "../../helper/masksPure";
import utils from "../../helper/validateUtils";
import CircularProgress from '@material-ui/core/CircularProgress';


class CondominiumRegistration extends React.Component {

  constructor(props) {
    super(props);   
  }

  async componentWillMount() {
    this.formFields = []
    const { userId } = this.props;
    if (!userId) {
      this.props.history.push("/administrativeHead");
    } else {
      const { listCities, getCondominiumData, setLoadingBtnCondominiumRegistration } = this.props;
      setLoadingBtnCondominiumRegistration(false);
      await listCities();
      const { form, cities } = this.props.condominiumRegistrationReducer;
      getCondominiumData(userId, form, cities, this.props.history);
    }  
  }  

  render() {

    let { loadingCondominiumRegistration, form, filteredCities, loadingBtnCondominiumRegistration } = this.props.condominiumRegistrationReducer; // reducer

    let { onChangeCondominiumData, filterCities, addInititalDataCondominium, identifyCep } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        {loadingCondominiumRegistration ? <CircularProgress color="inherit"/> : (
            <div className="col condominiumRegistration">
            <Form name="start" noValidate={false}>
              <CheckObligation field="condominiumRegistration_condominiumName">
                {(validations) => (
                  <div className="col">
                    <Field
                      loading={loadingCondominiumRegistration}
                      type="text"
                      required={validations.required}
                      label="NOME DO CONDOMÍNIO"
                      name="condominiumRegistration_condominiumName"
                      maxlength={200}
                      minlength={3}
                      value={form.condominiumRegistration_condominiumName}
                      onChange={(field, value) => onChangeCondominiumData(field, value)}
                      returnObject={this.createRefFields}
                    />
                  </div>
                )}
              </CheckObligation>

              <div className="flex-space-b">
                <CheckObligation field="condominiumRegistration_condomimiumPhone">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="phone"
                        required={validations.required}
                        label="DDD + TELEFONE"
                        name="condominiumRegistration_condomimiumPhone"
                        maxlength={15}
                        value={maskPure.formatPhone(form.condominiumRegistration_condomimiumPhone)}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}    
                        validateSync={() => utils.isPhone(form.condominiumRegistration_condomimiumPhone)}                  
                      />
                    </div>
                  )}
                </CheckObligation>
  
                <CheckObligation field="condominiumRegistration_condomimiumEmail">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="email"
                        required={validations.required}
                        label="EMAIL"
                        name="condominiumRegistration_condomimiumEmail"
                        maxlength={100}
                        value={form.condominiumRegistration_condomimiumEmail}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>
              </div>

              <div className="flex-space-b">
                <CheckObligation field="condominiumRegistration_cnpj">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        required={validations.required}
                        label="CNPJ"
                        name="condominiumRegistration_cnpj"
                        mask="99.999.999/9999-99"
                        maxlength={18}
                        value={form.condominiumRegistration_cnpj}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                        validateSync={() => form.condominiumRegistration_cnpj !== '' ? utils.isCNPJ(form.condominiumRegistration_cnpj) : true}
                      />
                    </div>
                  )}
                </CheckObligation>

                <CheckObligation field="condominiumRegistration_condomimiumCep">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        mask="99.999-999"                        
                        required={validations.required}
                        label="CEP"
                        name="condominiumRegistration_condomimiumCep"
                        value={form.condominiumRegistration_condomimiumCep}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        onBlur={(ev) => identifyCep(ev.target.value)}
                        returnObject={this.createRefFields}
                        validateSync={() => form.condominiumRegistration_condomimiumCep.replace(".", "").replace("-", "").length === 8}
                      />
                    </div>
                  )}
                </CheckObligation>                  
              </div> 
  
              <div className="flex-space-b">
                <CheckObligation field="condominiumRegistration_condominiumStreet">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        required={validations.required}
                        label="LOGRADOURO"
                        name="condominiumRegistration_condominiumStreet"
                        maxlength={70}
                        minlength={3}
                        // placeholder="Rua cinquenta"
                        value={form.condominiumRegistration_condominiumStreet}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>
  
                <CheckObligation field="condominiumRegistration_condominiumNumber">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        mask="9999999999"
                        required={validations.required}
                        label="Nº"
                        name="condominiumRegistration_condominiumNumber"
                        maxlength={10}
                        value={form.condominiumRegistration_condominiumNumber}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>
              </div>
  
              <div className="flex-space-b">
                <CheckObligation field="condominiumRegistration_additionCondominiumStreet">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        required={validations.required}
                        label="COMPLEMENTO"
                        name="condominiumRegistration_additionCondominiumStreet"
                        maxlength={20}
                        value={form.condominiumRegistration_additionCondominiumStreet}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>              
  
                <CheckObligation field="condominiumRegistration_condominiumSate">
                  {(validations) => (
                    <div className="col-45">
                      <Dropdown
                        value={form.condominiumRegistration_condominiumSate}
                        onChange={(event) => {
                          onChangeCondominiumData(event.name, event.value);                          
                          filterCities(event.value);
                        }}
                        dataSource={states}
                        required={validations.required}
                        name="condominiumRegistration_condominiumSate"
                        label="UF"
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>
              </div>
  
              <div className="flex-space-b">
                <CheckObligation field="condominiumRegistration_condominiumCity">
                  {(validations) => (
                    <div className="col-45">
  
                      <Dropdown
                        value={form.condominiumRegistration_condominiumCity}
                        onChange={(event) => onChangeCondominiumData(event.name, event.value)}
                        dataSource={filteredCities}
                        required={validations.required}
                        name="condominiumRegistration_condominiumCity"
                        label="CIDADE"
                        returnObject={this.createRefFields}
                        disabled={!form.condominiumRegistration_condominiumSate}
                      />
                    </div>
                  )}
                </CheckObligation>
  
                <CheckObligation field="condominiumRegistration_condominiumNeighborhood">
                  {(validations) => (
                    <div className="col-45">
                      <Field
                        type="text"
                        required={validations.required}
                        label="BAIRRO"
                        name="condominiumRegistration_condominiumNeighborhood"
                        maxlength={100}
                        minlength={3}
                        value={form.condominiumRegistration_condominiumNeighborhood}
                        onChange={(field, value) => onChangeCondominiumData(field, value)}
                        returnObject={this.createRefFields}
                      />
                    </div>
                  )}
                </CheckObligation>
              </div>
              
            </Form>
          </div>
        )}      
        
        <div className="col">
          <NavigationButtons 
            history={this.props.history} 
            validateFields={this.validateFields} 
            validationAction={() => addInititalDataCondominium()}
            disabledNext={loadingBtnCondominiumRegistration}
          />            
        </div>
      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default CondominiumRegistration;
