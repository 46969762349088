import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCurrentStepAction, setIsValidFieldsAction, setValidateFieldsAction } from '../../store/navigationSteps/actions'

import {withRouter} from 'react-router-dom';
import NavigationButtons from './navigationButtons'
import { stepsFormGroups } from '../../service/stepsService';

// const makeSteps = ({currentNavigation} = {currentNavigation:{}}) =>
//   stepsFormGroups(currentNavigation)

const mapStateToProps = (state) => ({
  navigation: state.navigationStepsReducer,
  steps:state.navigationStepsReducer.navigations.Groups
})

const mapDispatchToProps = dispatch =>
bindActionCreators({ setCurrentStepAction, setValidateFieldsAction, setIsValidFieldsAction }, dispatch)

export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(NavigationButtons))
