import * as Types from './types';

const initialState = {
  purchaseOrder: null,
  error: '',
  loading: false,
  bankSlipLoading: false,
}

export default function paymentMethodsReducer(state = initialState, { type, payload }){
  switch (type) {

  case Types.SET_PURCHASE_ORDER:
    return { 
      ...state, 
      purchaseOrder: payload 
    }
  case Types.SET_LOADING:
    return { 
      ...state, 
      loading: payload 
    }
  case Types.SET_BANKSLIP_LOADING:
    return { 
      ...state, 
      bankSlipLoading: payload 
    }
  case Types.SET_ERROR:
    return { 
      ...state, 
      error: payload 
    }

  default:
    return state
  }
}

