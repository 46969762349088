import React from 'react';
import { Axios, AxiosAuth, tokenUser } from "../../service/serviceBasic";
import { toast } from "react-toastify";
import config from "../../service/constants/appConfig";
import { messageErrorDefault } from "../../helper/constants";
import jwt_decode from "jwt-decode";

import { 
  CHANGE_ADMINISTRATIVEHEAD_DATA,
  CHANGE_ADMINISTRATIVEHEAD_VALIDATION_PASS,
  SET_USER_ID,
  SET_LOADING
} from "./types";
import masksPure from '../../helper/masksPure';

export const onChangeAdministrativeHeadData = (field, value) => dispatch => {
  dispatch({
    type: CHANGE_ADMINISTRATIVEHEAD_DATA,
    payload: {field, value},
  });
}

export const onChangeAdministrativeHeadValidationPass = (validationRules, validPass) => dispatch => {
  dispatch({
    type: CHANGE_ADMINISTRATIVEHEAD_VALIDATION_PASS,
    payload: {validationRules, validPass},
  });
}

const setUserId = (id) => dispatch => {
  dispatch({
    type: SET_USER_ID,
    payload: id,
  });
}

const setLoading = (value) => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: value,
  });
}

export const saveDataAdministrativeHead = () => async (dispatch, getState) => {
  
  dispatch(setLoading(true));
  const { form } = getState().administrativeHeadReducer;

  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(config.appIdPortal + ":" + config.appKeyPortal),
      KeepConnected: false,
    },
  };

  let data = {
    "GrantType": "password",
    "Password": form.admHead_password,
    "Username": form.admHead_email
  }  

  // Get userId Funcionário ou cadastro
  return await AxiosAuth.post("/token", data, options)
  .then(res => {
    if (res.data.message !== "Usuário ou senha incorretos.") {
      const decodedToken = jwt_decode(res.data.access_token);
      dispatch(setUserId(decodedToken.UserId));
      dispatch(setLoading(false));
      return true;
    } else {
      const error = new Error(res.data.message);
      error.code = 401;
      throw error;
    }   
  })
  .catch(async (err) => {
    if (err.code === 401) {
      const model = {
        Name: form.admHead_name,
        CnpjCpf: masksPure.removeMask(form.admHead_cpf),
        Email: form.admHead_email,
        JobRole: form.admHead_jobRole,        
        Password: form.admHead_password,
        Issuer: form.admHead_issuer,
        RG: masksPure.removeMask(form.admHead_RG),
      }
  
      // Insert Funcionário / Usuário
      return await Axios.post("v1/employee/insertWithoutCondominium", model)
      .then((response) => {      
        dispatch(setUserId(response.data.id));        
        return true;
      })
      .catch((error) => {
        // if (error.message === "E-mail já cadastrado no banco de dados.") {
        //   toast.warn("Senha incorreta", 
        //   {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     className: "ui-alert-toast",
        //     autoClose: 20000
        //   });
        // } else {
          toast.warn(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "ui-alert-toast",
          });
        // }
  
        return false;
      })
      .finally(() => dispatch(setLoading(false)));  
    } else {
      toast.warn(messageErrorDefault, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "ui-alert-toast",
      });
      dispatch(setLoading(false));
      return false;
    }   
  });   
}



