import axios from "axios";
import config from "./constants/appConfig";

const baseAuthURL = config.baseAuthURL;
const appId = config.appId;
const appKey = config.appKey;
const baseURL = config.baseURL;
const username = config.username;
const password = config.password;
const storageAuthName = config.storageAuthName;

const AxiosAuth = axios.create({
  baseURL: baseAuthURL,
  timeout: 100000,
  responseType: "json",
});

const Axios = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  responseType: "json",
});

const getLocalToken = () => {
  return Promise.resolve(
    (JSON.parse(window.sessionStorage.getItem(storageAuthName))) 
    || {}
  );
};

//Request Auth Interceptor
Axios.interceptors.request.use(async (config) => {
  try {
    let authData = await getLocalToken();
    if (!authData || !authData.access_token) {
      let { data } = await token();
      authData = data;
    }
    config.headers.Authorization = "Bearer " + authData.access_token;
  } catch (error) {
    console.error(error);
  }
  return config;
});

//Response Error Interceptor
const axiosId = Axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    let code = error && error.response ? error.response.status : -1;
    let message = messageError(code);
    if (code === 401) {
      // Axios.interceptors.response.eject(axiosId);
      const refreshCall = refreshAuthLogic();
      const requestQueueInterceptorId = Axios.interceptors.request.use(
        (request) => refreshCall.then(() => request)
      );
      return refreshCall
        .then((tokenRefreshResponse) => {
          error.response.config.headers["Authorization"] =
            "Bearer " + tokenRefreshResponse.data.access_token;
          Axios.interceptors.request.eject(requestQueueInterceptorId);
          return axios(error.response.config);
        })
        .catch((error) => {
          error.message = "Serviço indisponível, por favor tente novamente mais tarde.";
          Axios.interceptors.request.eject(requestQueueInterceptorId);
          return Promise.reject(error);
        });
    }

    if (code !== 401) {
      const data = error.response.data;
      
      if (data) {
        const isTwCoreException =  
        data.ExceptionType.includes("TWCoreWarning") ||
        data.ExceptionType.includes("TWCoreException");

        if (isTwCoreException) {
          
          message =
          data && data.ExceptionMessage
            ? data.ExceptionMessage
            : message;  
  
          error.message = message;         
          error.type = data.ExceptionType;
        } else {                 
          error.message = "Serviço indisponível, por favor tente novamente mais tarde.";
          error.type = data.ExceptionType;
        }       
      } else {
        error.message = message;
      }       
    }    
   
    return Promise.reject(error);
  }
);

const axiosAuthId = AxiosAuth.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    let code = error && error.response ? error.response.status : -1;
    let message = messageError(code);
    error.code = code;
    error.message = message;
    return Promise.reject(error);
  }
);

const token = async (user = username, pass = password, key = appId, secretKey = appKey) => {
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(key + ":" + secretKey),
      KeepConnected: false,
    },
  };

  let data = {
    "GrantType": "password",
    "Password": pass,
    "Username": user
  }

  const response = await AxiosAuth.post("/token", data, options);
  window.sessionStorage.setItem(storageAuthName, JSON.stringify(response.data));
  return response;
};

const refreshAuthLogic = (user = username, pass = password) =>
  token(user, pass).then((tokenRefreshResponse) => Promise.resolve(tokenRefreshResponse));

const messageError = (code) => {
  switch (code) {
    case -1:
      return "Falha ao acessar o servidor. Verifique sua internet.";
    case 401:
      return "Serviço não autorizado. Verifique se você tem permissão para acessar esse recurso.";
    case 404:
      return "Serviço não localizado. Talvez ele esteja apenas indisponível.";
    default:
      return "Falha na chamada do serviço.";
  }
};
export { Axios, AxiosAuth };
