export default {
  appId: process.env.REACT_APP_APPID,
  appKey: process.env.REACT_APP_APPKEY,
  username: process.env.REACT_APP_USERNAME,
  password: process.env.REACT_APP_PASSWORD, 
  storageAuthName: "AuthCadastroZIP",
  baseAuthURL: process.env.REACT_APP_AUTH,
  baseURL: process.env.REACT_APP_API,
  appIdPortal: process.env.REACT_APP_APPIDPORTAL,
  appKeyPortal: process.env.REACT_APP_APPKEYPORTAL,
  urlPortal: process.env.REACT_APP_URLPORTAL,
};
