import { Axios } from "../../service/serviceBasic";
import { toast } from "react-toastify";
import { brands, messageErrorCard, messageErrorDefault } from "../../helper/constants";
import masksPure from "../../helper/masksPure";

import * as Types from "./types";
import { paymentMethodEnum } from "../../helper/purchaseOrderEnum";

const setLoadingBtnPayment = (value) => dispatch => {
  dispatch({
    type: Types.LOADING_BTN_PAYMENT,
    payload: value,
  });
}

export const onChangePaymentData = (field, value) => dispatch => {  
  dispatch({
    type: Types.CHANGE_PAYMENT_DATA,
    payload: {field, value},
  });
}

export const getTokenMPI = async () => {
  return await Axios.post("v1/payment/mpi/token")
  .then((res) => {
    return res.data.accessToken;
  })
  .catch(error => {
    toast.warn(error.message || messageErrorDefault, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "ui-alert-toast",
      bodyClassName: "ui-alert-toast-content",
    });
  })
} 


export const pay = (creditCardData) => async(dispatch, getState) => {  
  // dispatch(setLoadingBtnPayment(false));
  const condominiumId = getState().condominiumRegistrationReducer.form.condominiumRegistration_condominiumId;
  const { purchaseOrder } = getState().paymentMethodsReducer;
  try {
    dispatch(setLoadingBtnPayment(true));
    let expirationDate = creditCardData.payment_credit_card_expiration_date.split("/");
      
    const brand = Object.keys(brands).find((value) => brands[value] == creditCardData.payment_credit_card_brand);
    
    const valueTotal = purchaseOrder.valueGross - (purchaseOrder.valueDiscount || 0);

    const request = {
      condominiumId: condominiumId,
      purchaseOrderId: purchaseOrder.id,
      paymentMethod: paymentMethodEnum.creditCard.id,
      CreditCard: 
      {
        CardNumber: masksPure.removeMask(creditCardData.payment_credit_card_number),
        Holder: creditCardData.payment_credit_card_holder_name,
        ExpirationDate: `${expirationDate[0]}/20${expirationDate[1]}`,
        SecurityCode: creditCardData.payment_credit_card_cvv,
        Brand: brand,
        CpfCnpj: masksPure.removeMask(creditCardData.payment_credit_card_holder_identification),
      },
      ValueTotal: valueTotal
    }   
  
    return await Axios.post("v1/payment/paysubscription", request)
    .then(() => {
      dispatch(setLoadingBtnPayment(false));
      return true;
    })
    .catch((ex) => {
      toast.warn(ex.response.data.Message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "ui-alert-toast",
        bodyClassName: "ui-alert-toast-content",
      });
      dispatch(setLoadingBtnPayment(false));
      return false;
    })
  }
  catch(e) {
    dispatch(setLoadingBtnPayment(false));
    return false;
  } 
}

export const cardBin = (cardBin) => (dispatch) => {
  try{
    const card = {
      bin: cardBin
    } 

    return new Promise((resolve,reject) => {
      return Axios.post("v1/payment/cardbin", card).then((result) => {
        resolve(result);
      }).catch((ex) => {
        reject(ex);
        toast.warn(ex.response.data.Message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "ui-alert-toast",
          bodyClassName: "ui-alert-toast-content",
        });
      })
    })
  }
  catch(e){
    //dispatch(setLoadingBtnPayment(false));
    return false;
  }
}


