import React, { Component } from 'react'

class _3DS extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    env: ''
  }

  componentWillMount() {
    this.setState({env: this.getQueryString("env")})
  }

  componentDidMount () {
    const script = document.createElement("script");
    script.src = "../../assets/js/_3ds.min.js";
    script.async = true;
    document.body.appendChild(script);
}

  // sendOrder = () => {
  //   bpmpi_authenticate();
  // }
  
  bpmpi_config = () => {
    return {
      onReady: function () {
        // Evento indicando quando a inicialização do script terminou.
        document.getElementById("btnSendOrder").disabled = false;
      },
      onSuccess: function (e) {
        // Cartão elegível para autenticação, e portador autenticou com sucesso.
        var cavv = e.Cavv;
        var xid = e.Xid;
        var eci = e.Eci;
        var version = e.Version;
        var referenceId = e.ReferenceId;
      },
      onFailure: function (e) {
        // Cartão elegível para autenticação, porém o portador finalizou com falha.
        var xid = e.Xid;
        var eci = e.Eci;
        var version = e.Version;
        var referenceId = e.ReferenceId;
      },
      onUnenrolled: function (e) {
        // Cartão não elegível para autenticação (não autenticável).
        var xid = e.Xid;
        var eci = e.Eci;
        var version = e.Version;
        var referenceId = e.ReferenceId;
      },
      onDisabled: function () {
        // Loja não requer autenticação do portador (classNamee "bpmpi_auth" false -> autenticação desabilitada).
      },
      onError: function (e) {
        // Erro no processo de autenticação.
        var xid = e.Xid;
        var eci = e.Eci;
        var returnCode = e.ReturnCode;
        var returnMessage = e.ReturnMessage;
        var referenceId = e.ReferenceId;
      },
      onUnsupportedBrand: function (e) {
        // Bandeira não suportada para autenticação.
        var returnCode = e.ReturnCode;
        var returnMessage = e.ReturnMessage;
      },
      Environment: this.props.Environment === "Production" ? "PRD" : "SDB",
      Debug: true,
    };
  }
  
  getQueryString = (field) => {
    var href = window.location.href;
    var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
    var string = reg.exec(href);
    return string ? string[1] : null;
  }  

  render() {
    return (
      <div>
        {/* DADOS DE PARAMETRIZAÇÃO */}
        <input type="hidden" name="authEnabled" className="bpmpi_auth" value="true" />
        <input type="hidden" name="authEnabledNotifyonly" className="bpmpi_auth_notifyonly" value="false" />
        <input type="hidden" name="accessToken" className="bpmpi_accesstoken" value={this.props.Token3DS} />
        
        {/* DADOS DE PEDIDO */}
        <input type="hidden" name="orderNumber" className="bpmpi_ordernumber" value={this.props.OrderNumber} />
        <input type="hidden" name="currency" className="bpmpi_currency" value={986} /> {/*BRL*/}
        <input type="hidden" name="amount" className="bpmpi_totalamount" value={this.props.Amount} />
        <input type="hidden" name="installments" className="bpmpi_installments" value={1} />
        <input type="hidden" name="paymentMethod" className="bpmpi_paymentmethod" value="credit" />
        <input type="hidden" name="cardNumber" className="bpmpi_cardnumber" value={this.props.CardNumber} />
        <input type="hidden" name="expMonth" className="bpmpi_cardexpirationmonth" value={this.props.CardExpirationMonth} /> {/*ex: 01*/}
        <input type="hidden" name="expYear" className="bpmpi_cardexpirationyear" value={this.props.CardExpirationYear} /> {/*ex: 2021*/}

        {/* DADOS DAS CARACTERÍSTICAS DO PEDIDO */}
        <input type="hidden" name="bpmpi_recurring_frequency" className="bpmpi_recurring_frequency" value={1} /> 
        {/*Recorrência 1-Mensal 2 - Bimestral 3 - Trimestral 4 - Quadrimestral 6 - Semestral 12 - Anual*/}
        <input type="hidden" name="bpmpi_order_recurrence" className="bpmpi_order_recurrence" value={true} />
        <input type="hidden" name="bpmpi_order_productcode" className="bpmpi_order_productcode" value="PHY" />
         {/*PHY: compra de mercadorias
          CHA: Check acceptance
          ACF: Financiamento de conta
          QCT: Transação quase-dinheiro
          PAL: recarga*/}

        {/* DADOS DE ENDEREÇO DE COBRANÇA */}
        <input type="hidden" name="bpmpi_billlto_customerid" className="bpmpi_billlto_customerid" value={this.props.CPFCNPJ} />
        <input type="hidden" name="bpmpi_billlto_contactname" className="bpmpi_billlto_contactname" value={this.props.ContactName} />
        <input type="hidden" name="bpmpi_billTo_phonenumber" className="bpmpi_billTo_phonenumber" value={this.props.PhoneNumber} />
        <input type="hidden" name="bpmpi_billTo_email" className="bpmpi_billTo_email" value={this.props.Email} />
        <input type="hidden" name="bpmpi_billTo_street1" className="bpmpi_billTo_street1" value={this.props.StreetNumber} /> {/* Logradouro e Número do endereço de cobrança */}
        <input type="hidden" name="bpmpi_billTo_street2" className="bpmpi_billTo_street2" value={this.props.ComplementNeighborhood} /> {/* Complemento e bairro do endereço de cobrança */}
        <input type="hidden" name="bpmpi_billTo_city" className="bpmpi_billTo_city" value={this.props.City} />
        <input type="hidden" name="bpmpi_billTo_state" className="bpmpi_billTo_state" value={this.props.State} />
        <input type="hidden" name="bpmpi_billlto_zipcode" className="bpmpi_billlto_zipcode" value={this.props.ZipCode} />
        <input type="hidden" name="bpmpi_billlto_country" className="bpmpi_billlto_country" value={this.props.Country} />

      </div>
    )
  }
} 

export default _3DS;


