import React from 'react';
import './cards.scss';

function Cards({label, items, classesCards}) {

  return (
    <div>
      <div className="label">{label}</div>
      <div className={`registration-cond-card ${classesCards}`}>      
        {items.map(x => x)}
      </div>
    </div>
  );
}

export default Cards;

