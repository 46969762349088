import { connect } from 'react-redux';
import Payment from './payment';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { onChangePaymentData, pay, cardBin } from '../../store/payment/actions';
import { setConclusionMessage } from '../../store/conclusion/actions';

const mapStateToProps = (state, nextState) => ({
  paymentReducer: state.paymentReducer,
  paymentMethodsReducer: state.paymentMethodsReducer  
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  onChangePaymentData,
  setConclusionMessage,
  pay,
  cardBin 
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
