

export const fieldValidationMessage = {
  'valueMissing': () => 'Este campo é obrigatório.',
  'tooShort': value => `Este campo deve ter no mínimo ${value.validations.minlength} caracteres.`,
  'tooLong': value => `Este campo deve ter no máximo ${value.validations.maxlength} caracteres.`,
  'rangeUnderflow': value => `Valor mínimo permitido é ${value.validations.min}.`,
  'rangeOverflow': value => `Valor máximo permitido é ${value.validations.max}.`,
  'patternMismatch': value => `${value.label} inválido!`,
  'typeMismatch': value => `${value.label} inválido!`,
}

export const limiteTamanhoArquivo = 5

export const states = [
  {name: 'AC', value: 1},
  {name: 'AL', value: 2},
  {name: 'AM', value: 3},
  {name: 'AP', value: 5},
  {name: 'BA', value: 6},
  {name: 'CE', value: 7},
  {name: 'DF', value: 8},
  {name: 'ES', value: 9},
  {name: 'GO', value: 10},
  {name: 'MA', value: 11},
  {name: 'MG', value: 12},
  {name: 'MS', value: 13},
  {name: 'MT', value: 14},
  {name: 'PA', value: 15},
  {name: 'PB', value: 16},
  {name: 'PE', value: 17},
  {name: 'PI', value: 18},
  {name: 'PR', value: 19},
  {name: 'RJ', value: 20},
  {name: 'RN', value: 21},
  {name: 'RO', value: 22},
  {name: 'RR', value: 23},
  {name: 'RS', value: 24},
  {name: 'SC', value: 25},
  {name: 'SE', value: 26},
  {name: 'SP', value: 27},
  {name: 'TO', value: 4},  
]

export const condominiumTypes = {
  House: 1,
  Building: 2,
  Condominium: 3
}

export const nameOfUnits = {
  Sala: 1,
  Apartamento: 2,
  Loja: 3,
  Escritório: 4,
  Outro: 5
}

export const numberOfUnits = {
  Unidade: 1,
  Dezena: 2,
  Centena: 3,
  Milhar: 4
}

export const blocksCount = {
  Two: 2,
  Three: 3,
  Four: 4,
  FiveOrMore: 5
}

export const brands = {
  Visa: 1,
  Master: 2,
  Elo: 3,
  AmericanExpress: 4,
  Diners: 5
}

export const messageErrorDefault = "Serviço indisponível, por favor tente novamente mais tarde.";