import React from 'react';
import './checkBox.scss';


function ItemCheckBox({label, onchange, check}) {

  return (
    <div
      className={`checkbox ${check ? 'selected' : ''}`}
      onClick={onchange}
    >
        <span></span>
        <span>{label}</span>
    </div>
  );
}

export default ItemCheckBox;

