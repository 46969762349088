import React, { useEffect } from 'react'
import './choosePlan.scss';
import Breadcrumbs from '../../components/breadcrumbs';
import PlanCard from './PlanCard';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions, registerSubscription, setSubscription } from '../../store/choosePlan/actions';
import NavigationButtons from '../../components/navigationButtons';
import { withRouter } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import CouponInput from './CouponInput';

const ChoosePlan = ({ history }) => {
  const dispatch = useDispatch();
  const { choosePlanReducer, navigationStepsReducer } = useSelector(state => state);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [])

  const handleChangeSubscription = (plan) => {
    dispatch(setSubscription(plan));
  }

  const handleNextPage = () => {
    const nextIndex = navigationStepsReducer.currentStep.Id + 1;
    const nextStep = navigationStepsReducer.navigations.Groups.find(item => item.Id == nextIndex);

    if (choosePlanReducer.subscription != null && !!nextStep) {
      dispatch(registerSubscription(history));
    }
  }

  return (
    <section className='container'>
      <div className="col">
        <Breadcrumbs />       
      </div>

      {choosePlanReducer.loading ? <CircularProgress color='inherit' /> : (
        <div className='col choose-plan'>
          <div className='plan-box'>  

            <CouponInput/>

            <div className='form-group-plans'>
              <PlanCard
                subscription={choosePlanReducer?.subscriptionList?.bronze}
                titleColor='#FF8039'
                isSelected={choosePlanReducer.subscription?.id === choosePlanReducer?.subscriptionList?.bronze?.id}
                onSelect={handleChangeSubscription}
              />
              <PlanCard
                subscription={choosePlanReducer?.subscriptionList?.prata}
                titleColor='#A8A8A8'
                isSelected={choosePlanReducer.subscription?.id === choosePlanReducer?.subscriptionList?.prata?.id}
                includeFeature='Tudo incluído do pacote bronze e mais:'
                onSelect={handleChangeSubscription}
              />
              <PlanCard
                subscription={choosePlanReducer?.subscriptionList?.ouro}
                titleColor='#FFD600'
                isSelected={choosePlanReducer.subscription?.id === choosePlanReducer?.subscriptionList?.ouro?.id}
                includeFeature='Tudo incluído do pacote prata e mais:'
                onSelect={handleChangeSubscription}
              />
            </div>           

          </div>
        </div>
      )}


      <div className="col">
        <NavigationButtons
          noAsterisk
          style={{ margin: 0 }}
          nextCustomButton={
            <button
              onClick={handleNextPage}
              className='next'>
              Próximo
            </button>
          }
        //actionAfter={() => console.log(choosePlanReducer.plan)}
        />
      </div>
    </section>
  )
}

export default withRouter(ChoosePlan);
