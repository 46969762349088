import {
  SET_NAVIGATION_STEPS, 
  LOADING_NAVIGATION_STEPS, 
  SET_ERROR_NAVIGATION_STEPS, 
  SET_CURRENT_STEP, 
  SET_VALIDATE_FIELDS, 
  SET_IS_VALID_FIELDS
} from './types'

import { condominiumTypes, nameOfUnits } from '../../helper/constants';

const initialState = {
  navigations: {
    "Groups": [
      {
        "Id": 0,
        "URL": "/administrativeHead",
        "Name": "Responsável Administrativo",
        "Fields": [
          {
            "key": "admHead_name",
            "required": true
          },
          {
            "key": "admHead_cpf",
            "required": true
          },
          {
            "key": "admHead_RG",
            "required": true
          },
          {
            "key": "admHead_issuer",
            "required": true
          },
          {
            "key": "admHead_email",
            "required": true
          },
          {
            "key": "admHead_jobRole",
            "required": true
          },
          {
            "key": "admHead_password",
            "required": true
          },
          {
            "key": "admHead_confirmPassword",
            "required": true,
          },             
        ]
      },
      {
        "Id": 1,
        "URL": "/condominiumRegistration",
        "Name": "Cadastro de Condomínio",
        "Fields": [
          {
            "key": "condominiumRegistration_condominiumName",
            "required": true
          },
          {
            "key": "condominiumRegistration_condominiumStreet",
            "required": true
          },
          {
            "key": "condominiumRegistration_condominiumNumber",
            "required": true
          },
          {
            "key": "condominiumRegistration_additionCondominiumStreet",
            "required": false
          },
          {
            "key": "condominiumRegistration_condominiumSate",
            "required": true
          },
          {
            "key": "condominiumRegistration_condominiumCity",
            "required": true
          },
          {
            "key": "condominiumRegistration_condominiumNeighborhood",
            "required": true
          },
          {
            "key": "condominiumRegistration_condomimiumCep",
            "required": true
          },
          {
            "key": "condominiumRegistration_cnpj",
            "required": true
          },   
          {
            "key": "condominiumRegistration_condomimiumPhone",
            "required": true
          },
          {
            "key": "condominiumRegistration_condomimiumEmail",
            "required": true
          },         
        ]
      },     
      {
        "Id": 2,
        "URL": "/condominiumSpecifications",
        "Name": "Especificações do Condomínio",
        "Fields": [
          {
            "key": "condominiumSpec_type",
            "required": true
          },
          {
            "key": "condominiumSpec_house_streetCount",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.House,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.House,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_house_unitsPerStreet",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.House,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.House,
                  "test": "="
                }
              ],
            }
          },
          // {
          //   "key": "condominiumSpec_house_code",
          //   "required": true,
          //   "validations": {
          //     "visible": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.House,
          //         "test": "="
          //       }
          //     ],
          //     "required": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.House,
          //         "test": "="
          //       }
          //     ],
          //   }
          // },
          {
            "key": "condominiumSpec_building_checkNameOfUnits",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_building_nameOfUnits",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_building_checkNameOfUnits",
                  "value": nameOfUnits.Outro,
                  "test": "="
                },
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_building_floorsCount",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_building_unitsPerFloor",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Building,
                  "test": "="
                }
              ],
            }
          },
          // {
          //   "key": "condominiumSpec_building_code",
          //   "required": true,
          //   "validations": {
          //     "visible": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.Building,
          //         "test": "="
          //       }
          //     ],
          //     "required": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.Building,
          //         "test": "="
          //       }
          //     ],
          //   }
          // },               
          {
            "key": "condominiumSpec_condominium_blocksCount",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
          // {
          //   "key": "condominiumSpec_condominium_code",
          //   "required": true,
          //   "validations": {
          //     "visible": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.Condominium,
          //         "test": "="
          //       }
          //     ],
          //     "required": [
          //       {
          //         "field": "condominiumSpec_type",
          //         "value": condominiumTypes.Condominium,
          //         "test": "="
          //       }
          //     ],
          //   }
          // },
          {
            "key": "condominiumSpec_condominium_checkNameOfUnits",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_condominium_nameOfUnits",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_condominium_checkNameOfUnits",
                  "value": nameOfUnits.Outro,
                  "test": "="
                },
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_condominium_floors",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_condominium_unitsCount",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
          {
            "key": "condominiumSpec_condominium_checkNumberOfUnits",
            "required": true,
            "validations": {
              "visible": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
              "required": [
                {
                  "field": "condominiumSpec_type",
                  "value": condominiumTypes.Condominium,
                  "test": "="
                }
              ],
            }
          },
        ]
      },
      {
        "Id": 3,
        "URL": "/detailCondominiumUnits",
        "Name": "Especificações do Condomínio",
        "Fields": []
      },
      {
        "Id": 4,
        "URL": "/summaryData",
        "Name": "Todos os Dados",
        "Fields":[
          {
            "key": "summaryData_conditions",
            "required": true
          },
          {
            "key": "summaryData_termsOfUse",
            "required": true
          },
        ]
      },
      {
        "Id": 5,
        "URL": "/choosePlan",
        "Name": "Escolher Plano"        
      },
      {
        "Id": 6,
        "URL": "/paymentMethods",
        "Name": "Formas de pagamento"        
      },
      {
        "Id": 7,
        "URL": "/payment",
        "Name": "Pagamento",
        "Fields":[
          {
            "key": "payment_credit_card_brand",
            "required": false
          },
          {
            "key": "payment_credit_card_number",
            "required": true
          },
          {
            "key": "payment_credit_card_expiration_date",
            "required": true
          },
          {
            "key": "payment_credit_card_cvv",
            "required": true
          },
          {
            "key": "payment_credit_card_holder_name",
            "required": true
          },
          {
            "key": "payment_credit_card_holder_identification",
            "required": true
          }
        ]
      }
    ]
  },
  currentStep: null,
  loading: false,
  error: null,
  validateFields: false,
  isValidFields: false
}


export default function navigationStepsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAVIGATION_STEPS:
      return { ...state, navigations: action.payload }
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload }
    case LOADING_NAVIGATION_STEPS:
      return { ...state, loading: action.payload }
    case SET_ERROR_NAVIGATION_STEPS:
      return { ...state, error: action.payload }
    case SET_VALIDATE_FIELDS:
      return { ...state, validateFields: action.payload }
    case SET_IS_VALID_FIELDS:
      return { ...state, isValidFields: action.payload }
    default:
      return state
  }
}

