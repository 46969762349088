export const paymentStatusEnum = {
  Pago: '66bc7b51-2f1c-402d-9089-a048aea63a74',
  AReceber: 'a66619b4-e217-4dcc-bf74-eda2de96b8db',
  Cancelado: '11614890-f6a2-48df-8561-965a4aea759a',
  Expirado: '12e289b9-c9ad-4001-bfdc-86cf22716717'
}

export const paymentMethodEnum = {
  pix:{
      id:"45eca6c9-3614-4307-bbf9-ce7e4f442037",
      name:"Pix"
  },
  bankSlip:{
      id:"d6990766-f108-11ed-8225-0242ac190002",
      name:"Boleto"
  },
  creditCard: {
      id:"ed1222a5-f108-11ed-8225-0242ac190002",
      name:"Cartão de Crédito"
  }
}
