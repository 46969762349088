import React from 'react';
import './dropdown.scss';
import loadingImg from '../../assets/images/loading.gif'
import { fieldValidationMessage } from '../../helper/constants';

const initialState = {
  className: '',
  asyncLoading: false
}

class Dropdown extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { ...initialState }
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validateAsync = this.validateAsync.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillMount() {
    let { returnObject } = this.props;

    if (returnObject) {
      returnObject(this)
    }
  }

  handleChange(event) {

    let { onChange } = this.props;

    if (onChange) {
      onChange(event.target)
    }
    this.validate(event.target);
  }

  render() {

    let { dataSource, value, name, required, label, loading, classSelect, disabled } = this.props;
    let { handleChange, handleBlur } = this;
    let { className, errorDescription } = this.state;

    let hasValue = value !== null && value !== '' ? 'has-value' : ''

    return (
      <label htmlFor={name} className={`dropdown ${className} ${hasValue}`}>
        <select
          disabled={disabled}
          className={classSelect}
          id={name}
          placeholder="&nbsp;"
          name={name}
          required={required}
          onChange={handleChange}
          value={value}
          onBlur={handleBlur}
          valor={value}
          ref={input => { this.input = input }}
        >
          <option></option>
          {
            dataSource.map(item => <option key={item.value} value={item.value} > {item.name} </option>)
          }
        </select>
        {loading && <i className="icon icon-loading"> <img src={loadingImg} alt="loading" /></i>}
        {!loading && <i className="icon icon-dropdown"> </i>}
        <span className="label">{label} {required ? "*" : ""}</span>
        <span className="border"></span>
        <small className="message">{errorDescription}</small>

      </label>
    );
  }

  handleBlur(event) {
    this.setState({ className: 'validate' });
    this.validate(event.target);
  }

  validate = field => {
    let fieldValidityState = null

    return new Promise((resolve, reject) => {
      if (!field.checkValidity()) {
        fieldValidityState = field.validity

        let errorType = this.validatePropertiesValidityState(fieldValidityState)
        reject({ message: fieldValidationMessage[errorType]({ ...this.props, ...this.state }) })
      }

      let resultValidateSync = this.validateSync(field.value)
      if (resultValidateSync !== true)
        reject(resultValidateSync)

      this.setState({ asyncLoading: true })
      resolve(field.value)
    })
      .then(this.validateAsync)
      .then(() => {
        this.setState({ className: 'info', errorDescription: '' })
        field.setCustomValidity('')
        return true
      })
      .catch(error => {
        this.setState({ className: 'danger', errorDescription: error.message })
        let { onError } = this.props
        if (onError)
          onError({ name: this.state.name, errorDescription: error.message })

        return false
      }).finally(() => {
        this.setState({ asyncLoading: false })
        return this.state
      })
  }

  async validateAsync(value) {
    if (typeof (this.props.validateAsync) === "function")
      return await this.props.validateAsync(value)
    return true
  }

  validateSync = value => {
    if (!this.props.validateSync)
      return true

    return this.props.validateSync(value)
  }


  validatePropertiesValidityState = validityState => {
    for (var key in validityState) {
      if (validityState[key] === true && key !== 'valid')
        return key
    }
  }
}

export default Dropdown;
