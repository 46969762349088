import {
    CHANGE_COND_SPECIF_DATA,
    CHANGE_STREETS_HOUSE,
    CHANGE_FLOORS_BUILDING,
    CHANGE_BLOCKS_CONDOMINIUM,
    SET_FORM_CONDOMINIUM_SPECIFICATIONS,
    SET_LOADING_CONDOMINIUM_SPECIFICATIONS,
} from './types';

import {
    CHANGE_STREET_NAME,
    CHANGE_UNIT_COUNT,
    DELETE_STREET,
    ADD_STREET,
    CHANGE_FLOOR_NAME,
    CHANGE_UNITS_NAMES_BUILDING,
    DELETE_FLOOR_BUILDING,
    ADD_FLOOR_BUILDING,
    DELETE_UNIT_BUILDING,
    DELETE_UNIT_HOUSE,
    ADD_UNIT_BUILDING,    
    CHANGE_BLOCK_CONDOMINIUM_NAME,
    CHANGE_FLOOR_CONDOMINIUM_NAME,
    ADD_FLOOR_CONDOMINIUM,
    ADD_BLOCK_CONDOMINIUM,
    ADD_UNIT_HOUSE,
    SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS
} from '../detailCondominiumUnits/types';

import listProcessing from '../../helper/listProcessing';
 
const initialState = {
    form: {
        condominiumSpec_type: '',
        
        condominiumSpec_house_streetCount: '',
        condominiumSpec_house_unitsPerStreet: '',
        condominiumSpec_house_streets: [], 
        condominiumSpec_house_code: '',

        condominiumSpec_building_checkNameOfUnits: '',
        condominiumSpec_building_nameOfUnits: '',
        condominiumSpec_building_floorsCount: '',
        condominiumSpec_building_unitsPerFloor: '',
        condominiumSpec_building_floors: [], 
        condominiumSpec_building_checkNumberOfUnits: '',
        condominiumSpec_building_code: '',

        condominiumSpec_condominium_checkNameOfUnits: '',
        condominiumSpec_condominium_blocksCount: '',
        condominiumSpec_condominium_code: '',
        condominiumSpec_condominium_nameOfUnits: '',
        condominiumSpec_condominium_floors: '',
        condominiumSpec_condominium_unitsCount: '',
        condominiumSpec_condominium_blocks: [],
        condominiumSpec_condominium_checkNumberOfUnits: '',
        condominiumSpec_condominium_numberOfUnits: '',
    },
    loadingCondominiumSpecifications: false,
    loadingBtnCondominiumSpecifications: false,
}

export default function condominiumSpecificationsReducer(state = initialState, action) {
    let newForm = state.form;
    switch (action.type) {
        case CHANGE_COND_SPECIF_DATA:
            newForm[action.payload.field] = action.payload.value;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_STREETS_HOUSE:
            newForm.condominiumSpec_house_streets = action.payload;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_STREET_NAME:
            newForm.condominiumSpec_house_streets.find(x => x.id === action.payload.id).name = action.payload.name;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_UNIT_COUNT:
            newForm.condominiumSpec_house_streets.find(x => x.id === action.payload.id).unitCount = action.payload.unitCount;
            return {
                ...state,
                form: newForm
            }
        case DELETE_STREET:
            newForm.condominiumSpec_house_streets = newForm.condominiumSpec_house_streets.filter(x => x.id !== action.payload);
            newForm.condominiumSpec_house_streets = listProcessing.reindex(newForm.condominiumSpec_house_streets);
            return {
                ...state,
                form: newForm
            }
        case ADD_STREET:
            newForm.condominiumSpec_house_streets.push(action.payload);
            newForm.condominiumSpec_house_streets = listProcessing.reindex(newForm.condominiumSpec_house_streets);
            return {
                ...state,
                form: newForm
            }
        case CHANGE_FLOORS_BUILDING:
            newForm.condominiumSpec_building_floors = action.payload;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_FLOOR_NAME:
            newForm.condominiumSpec_building_floors.find(x => x.id === action.payload.id).name = action.payload.name;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_UNITS_NAMES_BUILDING:
            newForm.condominiumSpec_building_floors.find(x => x.id === action.payload.id).units = action.payload.units;
            return {
                ...state,
                form: newForm
            }
        case DELETE_FLOOR_BUILDING:
            newForm.condominiumSpec_building_floors = newForm.condominiumSpec_building_floors.filter(x => x.id !== action.payload);
            newForm.condominiumSpec_building_floors = listProcessing.reindex(newForm.condominiumSpec_building_floors);
            return {
                ...state,
                form: newForm
            }
        case ADD_FLOOR_BUILDING:
            newForm.condominiumSpec_building_floors.push(action.payload);
            newForm.condominiumSpec_building_floors = listProcessing.orderByName(newForm.condominiumSpec_building_floors);
            newForm.condominiumSpec_building_floors = listProcessing.reindex(newForm.condominiumSpec_building_floors);
            return {
                ...state,
                form: newForm
            }
        case DELETE_UNIT_BUILDING:
            newForm.condominiumSpec_building_floors = newForm.condominiumSpec_building_floors.map(floor => {
                if (floor.id === action.payload.idFloor) {
                    floor.units = floor.units.filter(unit => unit.id !== action.payload.idUnit);
                    floor.units = listProcessing.reindex(floor.units);
                }
                return floor;
            });
            
            return {
                ...state,
                form: newForm
            }
        case DELETE_UNIT_HOUSE:
            newForm.condominiumSpec_house_streets = newForm.condominiumSpec_house_streets.map(street => {
                if (street.id === action.payload.idStreet) {
                    street.units = street.units.filter(unit => unit.id !== action.payload.idUnit);
                    street.units = listProcessing.reindex(street.units);
                }
                return street;
            });
            
            return {
                ...state,
                form: newForm
            }
        case ADD_UNIT_BUILDING:
            newForm.condominiumSpec_building_floors = newForm.condominiumSpec_building_floors.map(floor => {
                if (floor.id === action.payload.idFloor) {
                    floor.units.push(action.payload.unit);
                    floor.units = listProcessing.orderUnitsByNumberAsc(floor.units);
                }
                return floor;
            });
            newForm.condominiumSpec_building_floors = listProcessing.reindex(newForm.condominiumSpec_building_floors);
            return {
                ...state,
                form: newForm
            }
        case ADD_UNIT_HOUSE:
            newForm.condominiumSpec_house_streets = newForm.condominiumSpec_house_streets.map(street => {
                if (street.id === action.payload.idStreet) {
                    street.units.push(action.payload.unit);
                    street.units = listProcessing.orderUnitsByNumberAsc(street.units);
                }
                return street;
            });
            newForm.condominiumSpec_house_streets = listProcessing.reindex(newForm.condominiumSpec_house_streets);
            return {
                ...state,
                form: newForm
            }
        case CHANGE_BLOCKS_CONDOMINIUM:
            newForm.condominiumSpec_condominium_blocks = action.payload;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_BLOCK_CONDOMINIUM_NAME:
            newForm.condominiumSpec_condominium_blocks.find(x => x.id === action.payload.idBlock).name = action.payload.name;
            return {
                ...state,
                form: newForm
            }
        case CHANGE_FLOOR_CONDOMINIUM_NAME:
            newForm.condominiumSpec_condominium_blocks
                .find(x => x.id === action.payload.idBlock).floors
                .find(y => y.id === action.payload.idFloor).name = action.payload.name
            return {
                ...state,
                form: newForm
            }
        case ADD_FLOOR_CONDOMINIUM:
            newForm.condominiumSpec_condominium_blocks = action.payload
            return {
                ...state,
                form: newForm
            }
        case ADD_BLOCK_CONDOMINIUM:
            newForm.condominiumSpec_condominium_blocks = action.payload
            return {
                ...state,
                form: newForm
            }
        case SET_FORM_CONDOMINIUM_SPECIFICATIONS:
            return {
                ...state,
                form: action.payload
            }
        case SET_LOADING_CONDOMINIUM_SPECIFICATIONS:
            return {
                ...state,
                loadingCondominiumSpecifications: action.payload
            }
        case SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS:
            return {
                ...state,
                loadingBtnCondominiumSpecifications: action.payload
            }
        default:
            return state
    }
}


