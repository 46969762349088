import { 
    CHANGE_ADMINISTRATIVEHEAD_DATA,
    CHANGE_ADMINISTRATIVEHEAD_VALIDATION_PASS,
    SET_USER_ID,
    SET_LOADING
  } from './types'
  
  const initialState = {
    form: {      
      admHead_name: '',
      admHead_cpf: '',
      admHead_RG: '',
      admHead_issuer: '',
      admHead_email: '',
      admHead_jobRole: '',
      admHead_password: '',
      admHead_confirmPassword: '',
    },
    validationRulesPass: {
      eightChar: false,
      specialChar: false,
      upperCaseChar: false,
      lowerCaseChar: false,
      numericChar: false,
    },
    validPass: false,
    userId: null,
    loading: false
  }
  
  export default function administrativeHeadReducer(state = initialState, action) {
    switch (action.type) {
      case CHANGE_ADMINISTRATIVEHEAD_DATA:
        let newForm = state.form;
        newForm[action.payload.field] = action.payload.value;
        return {
          ...state,
          form: newForm
        }
      case CHANGE_ADMINISTRATIVEHEAD_VALIDATION_PASS:
        return {
          ...state,
          validationRulesPass: action.payload.validationRules,
          validPass: action.payload.validPass
        }
      case SET_USER_ID:
        return {
          ...state,
          userId: action.payload
        }
      case SET_LOADING:
        return {
          ...state,
          loading: action.payload
        }
      default:
        return state
    }
  }
  
  
  