import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// import step1Reducers from '/step1/reducers'
import condominiumRegistrationReducer from "./condominiumRegistration/reducer";
import administrativeHeadReducer from "./administrativeHead/reducer";
import navigationStepsReducer from "./navigationSteps/reducer";
import condominiumSpecificationsReducer from "./condominiumSpecifications/reducer";
import summaryDataReducer from "./summaryData/reducer";
import choosePlanReducer from "./choosePlan/reducer";
import paymentMethodsReducer from "./paymentMethods/reducer";
import paymentReducer from "./payment/reducer";
import conclusionReducer from "./conclusion/reducer";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Separar -----------------------------
import { combineReducers } from "redux";

const defaultState = {
  state: (state = {}) => state,
};

const persistConfig = {
  key: "root",
  storage,
};

const rootReducers = combineReducers({
  defaultState,
  condominiumRegistrationReducer,
  navigationStepsReducer,
  administrativeHeadReducer,
  condominiumSpecificationsReducer,
  summaryDataReducer,
  choosePlanReducer,
  paymentMethodsReducer,
  paymentReducer,
  conclusionReducer
});
//--------------------------------------

const middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducers);
const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middlewares)
)(createStore);

export const store = createStoreWithMiddleware(persistedReducer);
export const persistor = persistStore(store);
