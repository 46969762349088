import Conclusion from './conclusion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({
    conclusion: state.conclusionReducer
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conclusion);
