import React, { Component } from "react";
import "./navigationButtons.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';

class NavigationButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalExisteCadastro: false
    };
  }

  render() {
    let { currentStep } = this.props.navigation;
    let { nextCustomButton, disabledNext, mensage, openModalExisteCadastro, actionBefore } = this.props;
    let showBack = false;

    if (currentStep && currentStep.Id > 0) {
      showBack = true;
    }

    let classNext =  "next";

    return (
      <>
        <div className="navigationButtons" style={this.props.style}>
          <div className="col-50">
            {mensage && <p>{mensage}</p>}
            {!this.props.noAsterisk && (
              <p>Campos marcados com (*) são de preenchimento obrigatório!</p>
            )}           
          </div>
          <div className="col-50">
            <div className="col-75">
              {showBack && (
                <button onClick={this.previousPage} className="previous">
                  Voltar
                </button>
              )}
            </div>
            <div className="col-25">
              {!nextCustomButton && (
                <button
                  onClick={() => {
                    if (actionBefore) {
                      actionBefore();
                    }
                    if (openModalExisteCadastro) {
                      this.setState({ modalExisteCadastro: true });
                    } else {
                      this.nextPage(this.props.nextUrlRedirect);                     
                    }
                  }}
                  className={classNext}
                  disabled={disabledNext}
                >
                  {disabledNext ? (<>{<CircularProgress color="inherit"/>}</>) : 'Próximo'}                  
                </button>
              )}
              {nextCustomButton}
            </div>
          </div>
        </div>

         {/* Modal cadastro existe */}
         <Dialog
          open={this.state.modalExisteCadastro}
          onClose={() => this.openModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle 
            id="alert-dialog-title"
            style={{borderBottom: '1px solid gray'}}
          >
            <span style={{color: 'green'}}>Atenção</span>
          </DialogTitle>
          <DialogContent style={{borderBottom: '1px solid gray'}}>
            <DialogContentText id="alert-dialog-description">
              Prezado cliente, caso você já possua cadastro conosco e utilize o aplicativo ZIP, 
              seu acesso ao aplicativo ficará suspenso após o envio dos dados até que sejam analisados. 
              <br/>
              Continua com o preenchimento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              variant="contained" 
              style={{backgroundColor: 'white'}}
              onClick={() => this.setState({ modalExisteCadastro: false })}
            >
              <span style={{color: 'green'}}>Cancelar</span>
            </Button>
            <Button 
              variant="contained" 
              autoFocus
              style={{backgroundColor: 'green'}}
              onClick={() => {              
                this.setState({ modalExisteCadastro: false });
                this.nextPage();            
              }}
            >
              <span style={{color: 'white'}}>Sim</span>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  nextPage = (nextUrlRedirect = null) => {
    this.props.setValidateFieldsAction(true);

    let next = null;
    let { currentStep } = this.props.navigation;
    let { steps } = this.props;
    if (steps) {
      if (!currentStep) {
        next = steps[0];
      } else {
        let index = this.findIndexElement(steps, currentStep, "Id");
        next = steps[index + 1];
      }
    }
   
    if (this.props.validateFields) {
      this.props
        .validateFields()
        .then((validationForm) => {
          if (validationForm.length === 0) return false;

          return validationForm.find((o) => {
            return !o.valid;
          });
        })
        .then(async (r) => {
          // Se passou pela validação dos campos --
          if ((next && next.URL && !r) || (nextUrlRedirect && !r)) {

            // Se não tem alguma validação por action ou se a validação retornar true, segue para a próxima tela.
            const isValid = this.props.validationAction ? await this.props.validationAction() === true : false;
            if (isValid) {
              // Se ainda tem Steps
              if (next && next.URL && !r) {
                this.props.setCurrentStepAction(next);              
              }

              if (this.props.actionAfter) {
                this.props.actionAfter();
              }

              this.props.history.push(nextUrlRedirect ? nextUrlRedirect : next.URL);
            }          
          }

          this.props.setValidateFieldsAction(false);
        });
    } else {
      if (next && next.URL) {
        this.props.setCurrentStepAction(next);
        this.props.history.push(nextUrlRedirect ? nextUrlRedirect : next.URL);
      }

      this.props.setValidateFieldsAction(false);
    }
  };

  findIndexElement = (list, element, props) => {
    return list.findIndex((e, i) => {
      return e[props] === element[props];
    });
  };

  previousPage = () => {
    let { currentStep } = this.props.navigation;
    let { steps, history, setCurrentStepAction } = this.props;
    let index,
      previous = null;

    if (steps) {
      index = this.findIndexElement(steps, currentStep, "Id") - 1;
      if (index < 0) {
        previous = { URL: "administrativeHead" };
        setCurrentStepAction({});
      } else {
        previous = steps[index];

        setCurrentStepAction(previous);
      }
      history.push(previous.URL);
    }
  };
}

export default NavigationButtons;
