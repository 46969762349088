import { Axios } from "../../service/serviceBasic";
import { toast } from "react-toastify";
import { nameOfUnits, numberOfUnits } from '../../helper/constants';
import listProcessing from '../../helper/listProcessing';

import { 
    CHANGE_COND_SPECIF_DATA,
    CHANGE_STREETS_HOUSE,
    CHANGE_FLOORS_BUILDING,
    CHANGE_BLOCKS_CONDOMINIUM,
    SET_FORM_CONDOMINIUM_SPECIFICATIONS,
    SET_LOADING_CONDOMINIUM_SPECIFICATIONS
} from "./types";
import { condominiumTypes } from "../../helper/constants";

export const onChangeCondominiumSpecificationsData = (field, value) => dispatch => {
  dispatch({
    type: CHANGE_COND_SPECIF_DATA,
    payload: {field, value},
  });
}

const setFormCondominiumSpecifications = (value) => dispatch => {
  dispatch({
    type: SET_FORM_CONDOMINIUM_SPECIFICATIONS,
    payload: value,
  });
}

export const setloadingCondominiumSpecifications = (value) => dispatch => {
  dispatch({
    type: SET_LOADING_CONDOMINIUM_SPECIFICATIONS,
    payload: value,
  });
}

export const onChangeStreetsHouse = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  let streetCount = form.condominiumSpec_house_streetCount;

  let streets = form.condominiumSpec_house_streets;
  if (streets.length > streetCount) {    
    const y = streets.length - streetCount;
    for (let x = 0; x < y; x++) {
      streets.pop();
    }
  } else if (streets.length < streetCount) {
    const y = streetCount - streets.length;
    for (let x = 0; x < y; x++) {
      streets.push({ id: streets.length+1, name: `Rua ${x + 1}` });
    }
  }

  streets.map((street, index) => {
    let units = [];
    for(let x = 0; x < form.condominiumSpec_house_unitsPerStreet; x++) {
      let unit = {
        id: x+1,
        name: `Casa ${x+1}`
      }
      units.push(unit);
    }

    street.units = units;

    return street;
  })

  for (let x = 0; x < streets.length; x++) {
    streets[x].id = x+1;
  }

  dispatch({
    type: CHANGE_STREETS_HOUSE,
    payload: streets,
  });
}

export const onChangeFloorsBuilding = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  let floorsCount = form.condominiumSpec_building_floorsCount;
  let floors = form.condominiumSpec_building_floors;
  
  const numberOfUnit = form.condominiumSpec_building_checkNumberOfUnits;
  
  let value = 0;

  if(numberOfUnit == numberOfUnits.Dezena)
  {
    value = 11;
  }
  
  if(numberOfUnit == numberOfUnits.Centena)
  {
    value = 101;
  }
  
  if(numberOfUnit == numberOfUnits.Milhar){
    value = 1001;
  }
  
  if (floors.length > floorsCount) {    
    const y = floors.length - floorsCount;
    for (let x = 0; x < y; x++) {
      floors.pop();
    }
  } else if (floors.length < floorsCount) {    
    const y = floorsCount - floors.length;
    for (let x = 0; x < y; x++) {
      floors.push({ id: floors.length+1, name: `Andar ${floors.length+1}` });
    }
  }

  floors.map((floor, index) => {
    let units = [];
    
    if(index > 0){
      if(numberOfUnit == numberOfUnits.Dezena)
        value += 10;
      if(numberOfUnit == numberOfUnits.Centena)
        value += 100;
      if(numberOfUnit == numberOfUnits.Milhar)
        value += 1000;
    }
    
    if(numberOfUnit !== numberOfUnits.Unidade){
      for(let x = 0; x < form.condominiumSpec_building_unitsPerFloor; x++) {
        let unit = {
          id: x+1,
          name: `${form.condominiumSpec_building_nameOfUnits} ${x+value}`
        }
        units.push(unit);
      }
    }
    else{
      for(let x = 0; x < form.condominiumSpec_building_unitsPerFloor; x++) {
        let unit = {
          id: x+1,
          name: `${form.condominiumSpec_building_nameOfUnits} ${x+1}`
        }
        units.push(unit);
      }
    }

    floor.units = units;
    return floor;
  })

  for (let x = 0; x < floors.length; x++) {
    floors[x].id = x+1;
  }

  dispatch({
    type: CHANGE_FLOORS_BUILDING,
    payload: floors,
  });
}

export const onChangeBlocksCondominium = () => (dispatch, getState) => {
  const { form } = getState().condominiumSpecificationsReducer;

  let blocks = form.condominiumSpec_condominium_blocks;
  let blocksCount = form.condominiumSpec_condominium_blocksCount;
  const numberOfUnit = form.condominiumSpec_condominium_checkNumberOfUnits;

  const numbersOfUnitsObj = {
    [numberOfUnits.Dezena]: 11,
    [numberOfUnits.Centena]: 101,
    [numberOfUnits.Milhar]: 1001    
  }  

  if (blocks.length > blocksCount) {    
    const y = blocks.length - blocksCount;
    for (let x = 0; x < y; x++) {
      blocks.pop();
    }
  } else if (blocks.length < blocksCount) {    
    const y = blocksCount - blocks.length;
    for (let x = 0; x < y; x++) {

      let value = numbersOfUnitsObj[numberOfUnit] || 0;  

      let block = {
        id: blocks.length+1, 
        name: `Bloco ${blocks.length+1}`,
      };

      block.floors = [];
      for (let z = 0; z < form.condominiumSpec_condominium_floors; z++) {

        if(z > 0){
          if(numberOfUnit == numberOfUnits.Dezena)
            value += 10;
          if(numberOfUnit == numberOfUnits.Centena)
            value += 100;
          if(numberOfUnit == numberOfUnits.Milhar)
            value += 1000;
        }

        let mdlFloor = {
          id: z+1,
          name: `Andar ${ z + 1}`
        }

        mdlFloor.units = [];
        for (let k = 0; k < form.condominiumSpec_condominium_unitsCount; k++) {
          mdlFloor.units.push({
            id: k  + value,
            name: `${form.condominiumSpec_condominium_nameOfUnits} ${k + value}`
          })
        }

        block.floors.push(mdlFloor);
      }      

      blocks.push(block);
    }
  }
  
  dispatch({
    type: CHANGE_BLOCKS_CONDOMINIUM,
    payload: listProcessing.orderByName(blocks),
  });
}

export const getCondominiumSpecificationsData = () => async (dispatch, getState) => {
  dispatch(setloadingCondominiumSpecifications(true));

  const { condominiumRegistration_condominiumId } = getState().condominiumRegistrationReducer.form;

  const form = {
    condominiumSpec_type: '',
    
    condominiumSpec_house_streetCount: '',
    condominiumSpec_house_unitsPerStreet: '',
    condominiumSpec_house_streets: [], 
    condominiumSpec_house_code: '',

    condominiumSpec_building_checkNameOfUnits: '',
    condominiumSpec_building_nameOfUnits: '',
    condominiumSpec_building_floorsCount: '',
    condominiumSpec_building_unitsPerFloor: '',
    condominiumSpec_building_floors: [], 
    condominiumSpec_building_checkNumberOfUnits: '',
    condominiumSpec_building_code: '',

    condominiumSpec_condominium_blocksCount: '',
    condominiumSpec_condominium_code: '',
    condominiumSpec_condominium_checkNameOfUnits: '',
    condominiumSpec_condominium_nameOfUnits: '',
    condominiumSpec_condominium_floors: '',
    condominiumSpec_condominium_unitsCount: '',
    condominiumSpec_condominium_blocks: [],
    condominiumSpec_condominium_numberOfUnits: ''
  };

  return await Axios.post("v1/condominium/getCondominiumSpecifications", {CondominiumId: condominiumRegistration_condominiumId})
  .then(res => {
    if (res.data !== null) {
      form.condominiumSpec_type = res.data.condominiumTypeId;
      let sort = listProcessing.orderByDivision(res.data.units);
      // House
      if (form.condominiumSpec_type === condominiumTypes.House) {
        let streetsCount = 0;        
        if (res.data.units && res.data.units.length > 0) {          
          let streetsDistinct = sort.filter(function(itm, index, array) {
            return (index === 0) || (itm.division !== array[index-1].division);
          });
          streetsCount = streetsDistinct.length;

          form.condominiumSpec_house_unitsPerStreet = res.data.units.filter(x => x.division === res.data.units[0].division).length;

          streetsDistinct.map((unit, index) => {
            let units = res.data.units.filter(x => x.division === unit.division);
            form.condominiumSpec_house_streets.push({
              id: index+1,
              name: unit.division,
              units: units.map((x, i) => {return {id: i+1, name: x.name}})
            })
          })
        }
        form.condominiumSpec_house_code = res.data.codeCondominium;
        form.condominiumSpec_house_streetCount = streetsCount;
      } 
      // Building
      else if (form.condominiumSpec_type === condominiumTypes.Building) {
        let floorsDistinct = sort.filter(function(itm, index, array) {
          return (index === 0) || (itm.division !== array[index-1].division);
        });
        
        form.condominiumSpec_building_code = res.data.codeCondominium;
        form.condominiumSpec_building_floorsCount = floorsDistinct.length;
        form.condominiumSpec_building_nameOfUnits = res.data.units[0].name.split(" ")[0];
        const enumValue = nameOfUnits[form.condominiumSpec_building_nameOfUnits];
        form.condominiumSpec_building_checkNameOfUnits = enumValue === undefined ? nameOfUnits.Outro : enumValue;
        form.condominiumSpec_building_checkNumberOfUnits = enumValue === undefined ? numberOfUnits.Unidade : enumValue;
        form.condominiumSpec_building_unitsPerFloor = res.data.units.filter(x => x.division === res.data.units[0].division).length;

        floorsDistinct.map((unit, index) => {
          let units = res.data.units.filter(x => x.division === unit.division);
          form.condominiumSpec_building_floors.push({
            id: index+1,
            name: unit.division,
            units: units.map((x, i) => {return {id: i+1, name: x.name}})
          })
        })   
      }
      // Condominium
      else if (form.condominiumSpec_type === condominiumTypes.Condominium) {

        form.condominiumSpec_condominium_code = res.data.codeCondominium;
        form.condominiumSpec_condominium_blocksCount = res.data.blocks.length;
        const enumValue = nameOfUnits[form.condominiumSpec_condominium_nameOfUnits];
        form.condominiumSpec_condominium_checkNameOfUnits = enumValue === undefined ? nameOfUnits.Outro : enumValue;
        form.condominiumSpec_condominium_nameOfUnits = res.data.units[0].name.split(" ")[0];
        
        res.data.blocks.map((block, index) => {
          let units = res.data.units.filter(x => x.blockId === block.id); // Unidades do bloco
          let floorsDistinct = listProcessing.orderByDivision(units).filter(function(itm, index, array) { // Pavimentos distintos
            return (index === 0) || (itm.division !== array[index-1].division);
          });
          if (index === 0) {
            form.condominiumSpec_condominium_floors = floorsDistinct.length;
            form.condominiumSpec_condominium_unitsCount = units.filter(x => x.division === res.data.units[0].division).length;  // Unidades por andar em cada bloco
          }          

          let floors = [];
          floorsDistinct.map((unit, index) => {
            let floorUnits = units.filter(x => x.division === unit.division); // Unidades do pavimento
            floors.push({
              id: index+1,
              name: unit.division,
              units: listProcessing.orderUnitsByNumberAsc(floorUnits.map((x, i) => {return {id: i+1, name: x.name}}))
            })
          })          

          form.condominiumSpec_condominium_blocks.push({
            id: index+1,
            name: block.name,
            floors: listProcessing.orderByName(floors)
          })
          return block;
        })       
      }
    } 
  })
  .catch(err => {
    console.log(err.message);
  })
  .finally(() => {
    form.condominiumSpec_condominium_floors = form.condominiumSpec_condominium_floors === 0 ? '' : form.condominiumSpec_condominium_floors;    
    dispatch(setFormCondominiumSpecifications(form));
    dispatch(setloadingCondominiumSpecifications(false))
  })
}




