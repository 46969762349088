export const CHANGE_STREET_NAME = "CHANGE_STREET_NAME";
export const CHANGE_UNIT_COUNT = "CHANGE_UNIT_COUNT";
export const DELETE_STREET = "DELETE_STREET";
export const ADD_STREET = "ADD_STREET";
export const CHANGE_FLOOR_NAME = "CHANGE_FLOOR_NAME";
export const DELETE_UNIT_HOUSE = "DELETE_UNIT_HOUSE";
export const ADD_UNIT_HOUSE = "ADD_UNIT_HOUSE";

export const CHANGE_UNITS_NAMES_BUILDING = "CHANGE_UNITS_NAMES_BUILDING";
export const DELETE_FLOOR_BUILDING = "DELETE_FLOOR_BUILDING";
export const ADD_FLOOR_BUILDING = "ADD_FLOOR_BUILDING";
export const DELETE_UNIT_BUILDING = "DELETE_UNIT_BUILDING";
export const ADD_UNIT_BUILDING = "ADD_UNIT_BUILDING";

export const CHANGE_BLOCK_CONDOMINIUM_NAME = "CHANGE_BLOCK_CONDOMINIUM_NAME";
export const CHANGE_FLOOR_CONDOMINIUM_NAME = "CHANGE_FLOOR_CONDOMINIUM_NAME";
export const ADD_FLOOR_CONDOMINIUM = "ADD_FLOOR_CONDOMINIUM";
export const ADD_BLOCK_CONDOMINIUM = "ADD_BLOCK_CONDOMINIUM";

export const SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS = "SET_LOADING_BTN_CONDOMINIUM_SPECIFICATIONS";