import { connect } from 'react-redux'
import Home from './condominiumRegistration'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { onChangeCondominiumData, listCities, filterCities, getCondominiumData, addInititalDataCondominium, setLoadingBtnCondominiumRegistration, identifyCep } from '../../store/condominiumRegistration/actions'

const mapStateToProps = (state, nextState) => ({
  condominiumRegistrationReducer: state.condominiumRegistrationReducer,
  userId: state.administrativeHeadReducer.userId
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  onChangeCondominiumData,
  listCities,
  identifyCep,
  filterCities,
  getCondominiumData,
  addInititalDataCondominium,
  setLoadingBtnCondominiumRegistration
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
