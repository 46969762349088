import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions } from '../../../store/choosePlan/actions';
import currency from '../../../helper/currency';

const useCoupon = () => {  
  const dispatch = useDispatch();
  const [openCouponInput, setOpenCouponInput] = useState(false);  

  const { couponLoading, subscriptionList, couponInfo } = useSelector(state => state.choosePlanReducer)
 

  const getDiscountText = () => {
    if(!couponInfo) return '';    

    if(couponInfo.type === 'Percent'){
      return `${couponInfo?.value?.toFixed(0)}%`
    } else {
      return `R$${couponInfo?.value?.toFixed(0)}`
    }
  }

  const getCouponErrorMessage = () => {
    if(!!couponInfo && !couponInfo.isValid){
      return 'Cupom inválido'
    }

    return '';
  }
  
  const onSubmit = (couponCode) => {       
    if(!!couponCode){
      dispatch(getSubscriptions(couponCode));
    }
  }
 
  return {
    loading: couponLoading, 
    openCouponInput, 
    couponInfo, 
    onSubmit,
    getDiscountText, 
    setOpenCouponInput, 
    getCouponErrorMessage,
  }
}

export default useCoupon

