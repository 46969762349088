import React from 'react';
import './summaryData.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';
import Form from "../../components/form";
import { condominiumTypes, states } from '../../helper/constants';
import Field from "../../components/field";
import CheckObligation from "../../components/checkObligation";
import CircularProgress from '@material-ui/core/CircularProgress';

class SummaryData extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.formFields = [];
    this.props.getTerm();
  }
  render() {

    let { condominiumRegistration, administrativeHead, condominiumSpecifications, summaryData } = this.props.reducers; // reducers

    let { onChangeSummaryData, setConclusionMessage, acceptTerm } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        {summaryData.loadingSummaryData ? <CircularProgress color="inherit"/> : (
          <div className="col summaryData">
            <Form name="summaryData" noValidate={false}>

              {/* DADOS INICIAIS */}
              <section>
                <h1 className="title">DADOS INICIAIS</h1>
                <div className="data">
                  <div>
                    <label>Nome do condomínio: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condominiumName}</span>
                  </div>
                  <div>
                    <label>Logradouro: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condominiumStreet}</span>
                  </div>
                  <div>
                    <label>Nº: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condominiumNumber}</span>
                  </div>
                  <div>
                    <label>Complemento: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_additionCondominiumStreet}</span>
                  </div>
                  <div>
                    <label>UF: </label>
                    <span>
                      {states.find(x => parseInt(x.value) === parseInt(condominiumRegistration.form.condominiumRegistration_condominiumSate)) ?
                      states.find(x => parseInt(x.value) === parseInt(condominiumRegistration.form.condominiumRegistration_condominiumSate)).name : ''}
                    </span>
                  </div>
                  <div>
                    <label>Cidade: </label>
                    <span>
                      {condominiumRegistration.cities.find(x => parseInt(x.value) === parseInt(condominiumRegistration.form.condominiumRegistration_condominiumCity)) ?
                      condominiumRegistration.cities.find(x => parseInt(x.value) === parseInt(condominiumRegistration.form.condominiumRegistration_condominiumCity)).name : ''}
                    </span>
                  </div>
                  <div>
                    <label>Bairro: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condominiumNeighborhood}</span>
                  </div>
                  <div>
                    <label>CEP: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condomimiumCep}</span>
                  </div>
                  <div>
                    <label>CNPJ: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_cnpj}</span>
                  </div>
                  <div>
                    <label>Telefone: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condomimiumPhone}</span>
                  </div>
                  <div>
                    <label>Email: </label>
                    <span>{condominiumRegistration.form.condominiumRegistration_condomimiumEmail}</span>
                  </div>
                </div>
              </section>

              {/* RESPONSÁVEL ADMINISTRATIVO */}
              <section>
                <h1 className="title">RESPONSÁVEL ADMINISTRATIVO</h1>
                <div className="data">
                  <div>
                    <label>Nome: </label>
                    <span>{administrativeHead.form.admHead_name}</span>
                  </div>
                  <div>
                    <label>Email: </label>
                    <span>{administrativeHead.form.admHead_email}</span>
                  </div>
                  <div>
                    <label>Função: </label>
                    <span>{administrativeHead.form.admHead_jobRole}</span>
                  </div>
                </div>
              </section>

              {/* ESPECIFICAÇÕES DO CONDOMÍNIO */}
              <section>
                <h1 className="title">ESPECIFICAÇÕES DO CONDOMÍNIO</h1>
                {/* CASA */}
                {condominiumSpecifications.form.condominiumSpec_type === condominiumTypes.House && (
                  <div className="data">
                    <div>
                      <label>Tipo: </label>
                      <span>Casa</span>
                    </div>
                    <div>
                      <label>Código do condomínio: </label>
                      <span>{condominiumRegistration.form.condominiumRegistration_condominiumCode}</span>
                    </div>
                    {/* <div>
                      <label>Quantidades de ruas: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_house_streets.length}</span>
                    </div> */}
                    <div>
                      <label>Quantidade de unidades do condomínio: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_house_streets.reduce((acc, cur) => acc + cur.units.length, 0)}</span>
                    </div>
                  
                  </div>
                )}

                {/* PREDIO */}
                {condominiumSpecifications.form.condominiumSpec_type === condominiumTypes.Building && (
                  <div className="data">
                    <div>
                      <label>Tipo: </label>
                      <span>Prédio - um bloco</span>
                    </div>
                    <div>
                      <label>Código do condomínio: </label>
                      <span>{condominiumRegistration.form.condominiumRegistration_condominiumCode}</span>
                    </div>
                    {/* <div>
                      <label>Nome das unidades: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_building_nameOfUnits}</span>
                    </div> */}
                    {/* <div>
                      <label>Quantidade de pavimentos: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_building_floors.length}</span>
                    </div> */}
                    <div>
                      <label>Quantidade de unidades do condomínio: </label>
                      <span>
                        {condominiumSpecifications.form.condominiumSpec_building_floors.reduce((acc, cur) => acc + cur.units.length, 0)}
                      </span>
                    </div>
                  
                  </div>
                )}

                {/* CONDOMINIO */}
                {condominiumSpecifications.form.condominiumSpec_type === condominiumTypes.Condominium && (
                  <div className="data">
                    <div>
                      <label>Tipo: </label>
                      <span>Condomínio - dois ou mais blocos</span>
                    </div>
                    <div>
                      <label>Código do condomínio: </label>
                      <span>{condominiumRegistration.form.condominiumRegistration_condominiumCode}</span>
                    </div>
                    {/* <div>
                      <label>Quantidade de blocos: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_condominium_blocks.length}</span>
                    </div>
                    <div>
                      <label>Nome das unidades: </label>
                      <span>{condominiumSpecifications.form.condominiumSpec_condominium_nameOfUnits}</span>
                    </div>
                    <div>
                      <label>Quantidade de pavimentos: </label>
                      <span>
                        {condominiumSpecifications.form.condominiumSpec_condominium_blocks.reduce((acc, cur) => acc + cur.floors.length, 0)}
                      </span>
                    </div> */}
                    <div>
                      <label>Quantidade de unidades do condomínio: </label>
                      <span>
                        {condominiumSpecifications.form.condominiumSpec_condominium_blocks.reduce(
                          (acc, cur) => acc + cur.floors.reduce((acc, cur) => acc + cur.units.length, 0), 0)
                        }
                      </span>
                    </div>                 
                  </div>
                )}     
              </section>

              <section>
                <h1 className="title">REGRAS DE CONTRATO</h1>
                <div className="data">

                  {/* <div>
                    <div 
                      className={`checkbox-conditions ${summaryData.summaryData_conditions ? 'checked' : ''}`}
                      onClick={() => onChangeSummaryData('summaryData_conditions', !summaryData.summaryData_conditions)}
                    >
                      {summaryData.summaryData_conditions && (<span></span>)} 
                    </div>
                    <label 
                      className="terms-and-conditions link" 
                      onClick={() => { 
                        window.scrollTo(0,0); 
                        this.props.history.push({
                          pathname: '/contract',
                          state: { html: summaryData.summaryData_conditions_html },
                        }) 
                      }}
                    >
                      Eu li, entendi e concordo com as regras e condições.
                    </label>
                  </div> */}
                  {/* <CheckObligation field="summaryData_conditions">
                    {(validations) => (
                      <Field
                        type="text"
                        required={validations.required}
                        label=""
                        name="summaryData_conditions"
                        value={summaryData.summaryData_conditions}
                        onChange={(field, value) => onChangeSummaryData('summaryData_conditions', !summaryData.summaryData_conditions)}
                        returnObject={this.createRefFields}
                        hidden={true}
                        noAsterisk
                        validateSync={() => summaryData.summaryData_conditions}
                        validateSyncMessage="Campo obrigatório"
                      />
                    )}
                  </CheckObligation> */}

                  <div>      
                    <div 
                      className={`checkbox-terms-of-use ${summaryData.summaryData_termsOfUse ? 'checked' : ''}`} 
                      onClick={() => onChangeSummaryData('summaryData_termsOfUse', !summaryData.summaryData_termsOfUse)}
                    >
                      {summaryData.summaryData_termsOfUse && (<span></span>)}                    
                    </div>
                    <label className="terms-and-conditions">
                      Li e concordo com as <a className="link" href="https://zipcondominio.com/privacy-policy-v1.0" target="_blank">Politicas de Privacidade.</a>
                    </label>
                  </div>

                  <CheckObligation field="summaryData_termsOfUse">
                    {(validations) => (
                      <Field
                        type="text"
                        required={validations.required}
                        label=""
                        name="summaryData_termsOfUse"
                        value={summaryData.summaryData_termsOfUse}
                        onChange={(field, value) => onChangeSummaryData('summaryData_termsOfUse', !summaryData.summaryData_termsOfUse)}
                        returnObject={this.createRefFields}
                        hidden={true}
                        noAsterisk
                        validateSync={() => summaryData.summaryData_termsOfUse}
                        validateSyncMessage="Campo obrigatório"
                      />
                    )}
                  </CheckObligation>
                </div>
              </section>

            </Form>
          </div>
        )}        

        <div className="col">
          <NavigationButtons
            history={this.props.history}
            validateFields={this.validateFields}
            validationAction={() => acceptTerm()}
            disabledNext={summaryData.loadingBtnSummaryData}
            noAsterisk
            style={{ margin: 0 }}
            actionAfter={() => this.props.history.push('/choosePlan')

            //   setConclusionMessage({
            //   icon: "fa-check-circle-o",
            //   title: "Cadastro concluído com sucesso!",
            //   discription: "Suspendisse tortor nulla, finibus at scelerisque in, condimentum vel nisi. Maecenas tincidunt ullamcorper aliquet. Aenean ut rutrum nisi, tempor dapibus ipsum. Nam tempus odio at dolor bibendum, nec lacinia magna ultrices. Ut accumsan vestibulum porta.",
            //   urlRedirect: "/payment",
            //   clearStorage: false,
            //   link: null
            // })
            }
            //nextUrlRedirect="/conclusion"
          />
        </div>
      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default SummaryData;
