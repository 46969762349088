import { connect } from 'react-redux'
import DetailCondominiumUnits from './detailCondominiumUnits'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { 
  onChangeStreetName, 
  onChangeUnitCount, 
  onDeleteStreet, 
  onAddStreet,
  onChangeFloorName,
  onChangeUnitsNamesBuilding,
  onDeleteFloorBuilding,
  onAddFloorBuilding,
  onDeleteUnitBuilding,
  onAddUnitBuilding,
  onChangeBlockCondominiumName,
  onChangeFloorCondominiumName,
  onAddFloorCodominium,
  onAddUnitCondominium,
  onDeleteFloorCondominium,
  onDeleteUnitCondominium,
  onAddBlockCondominium,
  onDeleteUnitHouse,
  onAddUnitHouse,
  addCondominiumSpecifications
} from '../../store/detailCondominiumUnits/actions'

const mapStateToProps = (state, nextState) => ({
    condominiumSpecificationsReducer: state.condominiumSpecificationsReducer,
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  onChangeStreetName,
  onChangeUnitCount,
  onDeleteStreet,
  onAddStreet,
  onChangeFloorName,
  onChangeUnitsNamesBuilding,
  onDeleteFloorBuilding,
  onAddFloorBuilding,
  onDeleteUnitBuilding,
  onAddUnitBuilding,
  onChangeBlockCondominiumName,
  onChangeFloorCondominiumName,
  onAddFloorCodominium,
  onAddUnitCondominium,
  onDeleteFloorCondominium,
  onDeleteUnitCondominium,
  onAddBlockCondominium,
  onDeleteUnitHouse,
  onAddUnitHouse,
  addCondominiumSpecifications
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailCondominiumUnits));
