import React from 'react';
import './payment.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';

import Field from "../../components/field";
import Form from "../../components/form";
import CheckObligation from "../../components/checkObligation";
import maskPure from "../../helper/masksPure";
import utils from "../../helper/validateUtils";
import brandVisa from '../../assets/images/visa.png'
import brandAExpress from '../../assets/images/americanexpress.png'
import brandElo from '../../assets/images/elo.png'
import brandMaster from '../../assets/images/mastercard.png'
import brandDiners from '../../assets/images/dinersclub.png'
import { brands } from '../../helper/constants';
import _3DS from './_3DS';

import currency from '../../helper/currency';

class Payment extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    payment_credit_card_brand: '',
    payment_credit_card_number: '',
    payment_credit_card_expiration_date: '',
    payment_credit_card_cvv: '',
    payment_credit_card_holder_name: '',
    payment_credit_card_holder_identification: '',
    last_nine_number_bin: ''
  }

  componentWillMount() {
    this.formFields = [];    
  }

  onChangePaymentData = (field, value) => {       

    const newForm = { ...this.state, [field]: value };
    this.setState(newForm)    

    if (field == 'payment_credit_card_number')
      this.cardBinFind(value, newForm);

  }

  cardBinFind(value, newForm) {
    const { cardBin } = this.props;
    const bin = value.replace(/\D/g, "").slice(0, 9);

    if (bin.length < 9) {
      return;
    }

    if (bin === this.state.last_nine_number_bin) return

    this.setState({
      ...newForm,
      last_nine_number_bin: bin
    });  

    cardBin(bin).then((res) => {

      const brands = {
        VISA: 1,
        MASTERCARD: 2,
        ELO: 3,
        AMEX: 4,
        DINERS: 5
      }

      var setBrand = brands[res.data.provider] || 0;

      if (setBrand != 0) {
        this.setState({
          ...this.state,
          payment_credit_card_brand: setBrand
        });
      }

    }).catch((ex) => {
      console.log(ex);
      this.setState({
        ...this.state,
        payment_credit_card_brand: ''
      });      
    });

  }

  render() {

    let { form, loadingBtnPayment } = this.props.paymentReducer; // reducer

    let { setConclusionMessage, pay } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        <div className="col payment">

          <div className="col form-payment-data">
            <div className="brands">
              <img src={brandVisa} title="Visa" alt="Visa" className={`${this.state.payment_credit_card_brand === brands.Visa ? '' : 'grayscale'}`} />
              <img src={brandMaster} title="MasterCard" alt="MasterCard" className={`${this.state.payment_credit_card_brand === brands.Master ? '' : 'grayscale'}`} />
              {/* <img src={brandElo} title="Elo" alt="Elo" className={`${this.state.payment_credit_card_brand === brands.Elo ? '' : 'grayscale'}`} />
              <img src={brandAExpress} title="American Express" alt="American Express" className={`${this.state.payment_credit_card_brand === brands.AmericanExpress ? '' : 'grayscale'}`} />
              <img src={brandDiners} title="DinersClub" alt="DinersClub" className={`${this.state.payment_credit_card_brand === brands.Diners ? '' : 'grayscale'}`} /> */}
            </div>
            <Form name="payment" noValidate={false}>

              <CheckObligation field="payment_credit_card_brand">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      required={validations.required}
                      label=""
                      name="payment_credit_card_brand"
                      value={this.state.payment_credit_card_brand}
                      onChange={(field, value) => this.onChangePaymentData(field, value)}
                      returnObject={this.createRefFields}
                      noAsterisk
                      hidden
                    />
                  </div>
                )}
              </CheckObligation>

              <br />
              <br />
              <br />

              <CheckObligation field="payment_credit_card_number">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      //placeholder="XXXX XXXX XXXX XXXX"
                      required={validations.required}
                      label="Número do cartão de crédito:"
                      name="payment_credit_card_number"
                      mask="9999 9999 9999 9999"
                      value={this.state.payment_credit_card_number}
                      onChange={(field, value) => this.onChangePaymentData(field, value)}
                      returnObject={this.createRefFields}
                      noAsterisk
                      validateSync={() => this.state.payment_credit_card_number.length === 19}
                    />
                  </div>
                )}
              </CheckObligation>

              <CheckObligation field="payment_credit_card_expiration_date">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      //placeholder="MM/AA"
                      required={validations.required}
                      label="Data de validade:"
                      name="payment_credit_card_expiration_date"
                      mask="99/99"
                      value={this.state.payment_credit_card_expiration_date}
                      onChange={(field, value) => this.onChangePaymentData(field, value)}
                      returnObject={this.createRefFields}
                      noAsterisk
                      validateSync={() => utils.isDateValidExpirationCard(this.state.payment_credit_card_expiration_date)}
                    />
                  </div>
                )}
              </CheckObligation>

              <CheckObligation field="payment_credit_card_cvv">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      required={validations.required}
                      label="CVV:"
                      name="payment_credit_card_cvv"
                      mask="9999"
                      value={this.state.payment_credit_card_cvv}
                      onChange={(field, value) => this.onChangePaymentData(field, value)}
                      returnObject={this.createRefFields}
                      noAsterisk
                      validateSync={() => this.state.payment_credit_card_cvv.length > 2}
                    />
                  </div>
                )}
              </CheckObligation>

              <CheckObligation field="payment_credit_card_holder_name">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      required={validations.required}
                      label="Nome do titular:"
                      name="payment_credit_card_holder_name"
                      maxlength={200}
                      minlength={3}
                      value={this.state.payment_credit_card_holder_name}
                      onChange={(field, value) => this.onChangePaymentData(field, value.toUpperCase())}
                      returnObject={this.createRefFields}
                      noAsterisk
                    />
                  </div>
                )}
              </CheckObligation>

              <CheckObligation field="payment_credit_card_holder_identification">
                {(validations) => (
                  <div className="col">
                    <Field
                      type="text"
                      required={validations.required}
                      label="CPF ou CNPJ do titular:"
                      name="payment_credit_card_holder_identification"
                      maxlength={18}
                      value={this.state.payment_credit_card_holder_identification}
                      onChange={(field, value) => this.onChangePaymentData(field, maskPure.formatCpfCnpj(value))}
                      returnObject={this.createRefFields}
                      // validateSync={() => utils.isCpfCnpj(this.state.payment_credit_card_holder_identification)}
                      noAsterisk
                    />
                  </div>
                )}
              </CheckObligation>
            </Form>
          </div>

          <div className="col payment-value">
            <span>Valor do Pagamento: R$ {currency(this.props.paymentMethodsReducer?.purchaseOrder?.valueTotal)} por mês a partir do dia do cadastro ou próximo dia útil.</span>
          </div>
        </div>

        {/* <_3DS 
          Environment={"Homolog"}
          Token3DS={""}
          OrderNumber={""}
          Amount={""}
          CardNumber={""}
          CardExpirationMonth={""}
          CardExpirationYear={""}
          CPFCNPJ={""}
          ContactName={""}
          PhoneNumber={""}
          Email={""}
          StreetNumber={""}
          ComplementNeighborhood={""}
          City={""}
          State={""}
          ZipCode={""}
          Country={""}
        /> */}

        <div className="col">
          <NavigationButtons
            noAsterisk
            history={this.props.history}
            validateFields={this.validateFields}
            actionAfter={() => setConclusionMessage({
              icon: "fa-check-circle-o",
              title: "PAGAMENTO EFETUADO COM SUCESSO!",
              discription: "Suspendisse tortor nulla, finibus at scelerisque in, condimentum vel nisi. Maecenas tincidunt ullamcorper aliquet. Aenean ut rutrum nisi, tempor dapibus ipsum. Nam tempus odio at dolor bibendum, nec lacinia magna ultrices. Ut accumsan vestibulum porta.",
              link: "https://portal.zipcondominio.com/#/login",
              urlRedirect: null,
              clearStorage: true
            })}
            validationAction={() => pay(this.state)}
            disabledNext={loadingBtnPayment}
            nextUrlRedirect="/conclusion"
          />
        </div>

      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default Payment;
