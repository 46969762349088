const formatCpfCnpj = (value) => {
    value = value.replace(/\D/g,'');
    if (value) {
        if (value.length <= 11) {
            value = maskCpf(value);
        } else {
            value = maskCnpj(value);
        }
    }  
    return value;
}

const maskCpf = (value) => {
    // return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
    return value.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    
}
const maskCnpj = (value) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4-\$5");
}

const formatPhone = (value) => {
    value = value.replace(/\D/g,'');
    if (value) {
        if (value.length <= 10) {
            value = maskPhone(value);
        } else {
            value = maskCellPhone(value);
        }
    }  
    return value;
}

const maskPhone = (value) => {
    return value.replace(/(\d{2})(\d{4})(\d{4})/g,"\($1) \$2-\$3");
}

const maskCellPhone = (value) => {
    return value.replace(/(\d{2})(\d{5})(\d{4})/g,"\($1) \$2-\$3");
}

const removeMask = (value) => {
  return value.replace(/\D/g,'') || value;
}

export default {
    formatCpfCnpj: formatCpfCnpj,
    formatPhone: formatPhone,
    maskCpf,
    maskCnpj,
    removeMask
}
