import { Axios } from "../../service/serviceBasic";
import { toast } from "react-toastify";
import { states, messageErrorDefault } from '../../helper/constants';
import Mask from '../../helper/masksPure';
import Utils from '../../helper/validateUtils';

import {
  CHANGE_CONDOMINIUN_DATA,
  SET_CITIES,
  SET_FILTERED_CITIES,
  SET_FORM,
  SET_LOADING_CONDOMINIUM_REGISTRATION,
  SET_LOADING_BTN_CONDOMINIUM_REGISTRATION,
  SET_FORM_INITIAL_STATE,
  SET_ID_CONDOMINIUM_REGISTRATION,
  SET_CONDOMINIUM_CODE
} from "./types";
import { setCurrentStepAction } from "../navigationSteps/actions";

const setCities = (payload) => ({
  type: SET_CITIES,
  payload: payload,
});

const setFilteredCities = (payload) => ({
  type: SET_FILTERED_CITIES,
  payload: payload,
});

const setForm = (value) => ({
  type: SET_FORM,
  payload: value,
})

const setFormInitialState = () => ({
  type: SET_FORM_INITIAL_STATE
})

export const setLoadingCondominiumRegistration = (value) => dispatch => {
  dispatch({
    type: SET_LOADING_CONDOMINIUM_REGISTRATION,
    payload: value,
  });
}

export const setLoadingBtnCondominiumRegistration = (value) => dispatch => {
  dispatch({
    type: SET_LOADING_BTN_CONDOMINIUM_REGISTRATION,
    payload: value,
  });
}

export const setIdCondominiumRegistration = (value) => dispatch => {
  dispatch({
    type: SET_ID_CONDOMINIUM_REGISTRATION,
    payload: value,
  });
}

export const setCondominiumCode = (value) => dispatch => {
  dispatch({
    type: SET_CONDOMINIUM_CODE,
    payload: value,
  });
}

export const onChangeCondominiumData = (field, value) => dispatch => {
  dispatch({
    type: CHANGE_CONDOMINIUN_DATA,
    payload: { field, value },
  });
}

export const listCities = () => async (dispatch) => {

  dispatch(setLoadingCondominiumRegistration(true));

  await Axios.post("v1/city/list")
    .then((response) => {
      let cities = response.data.map(x => {
        return {
          name: x.name,
          value: x.id,
          uf: x.uf
        }
      })
      dispatch(setCities(cities));
      dispatch(setFilteredCities(cities));
    })
    .catch((error) => {
      console.log(error.message);
    });
}

export const filterCities = (stateId) => (dispatch, getState) => {
  const { cities } = getState().condominiumRegistrationReducer;
  const state = states.find(state => state.value === parseInt(stateId))
  if (!state) {
    dispatch(setFilteredCities([]));
  } else {
    const filterResult = cities.filter(city => city.uf.toUpperCase() === state.name);
    dispatch(setFilteredCities(filterResult));
  }
}

export const getCondominiumData = (userId, form, cities, history) => async (dispatch, getState) => {

  return await Axios.post("v1/condominium/getIncompleteRegistrationByOwner", { EmployeeId: userId })
    .then(res => {
      if (res.data !== null) {        
        //const city = cities.find(x => parseInt(x.value) === parseInt(res.data.cityId));

        form.condominiumRegistration_condominiumId = res.data.id;
        form.condominiumRegistration_condominiumName = res.data.name;
        form.condominiumRegistration_condominiumStreet = res.data.address;
        form.condominiumRegistration_condominiumNumber = res.data.number;
        form.condominiumRegistration_additionCondominiumStreet = res.data.complement;
        //form.condominiumRegistration_condominiumSate = states.find(x => x.name === city.uf).value;
        //form.condominiumRegistration_condominiumCity = city.value;
        form.condominiumRegistration_condominiumNeighborhood = res.data.neighborhood === null ? '' : res.data.neighborhood;
        form.condominiumRegistration_condomimiumCep = res.data.cep === null ? '' : res.data.cep;
        form.condominiumRegistration_cnpj = res.data.cnpj === null ? '' : res.data.cnpj;
        form.condominiumRegistration_condomimiumPhone = res.data.phoneMain === null ? '' : res.data.phoneMain;
        form.condominiumRegistration_condomimiumEmail = res.data.email === null ? '' : res.data.email;

        dispatch(setForm(form));

        if(res.data.cep)
          dispatch(identifyCep(res.data.cep))

        if (res.data.statusCondominiumId == 1) {
          const groups = getState().navigationStepsReducer.navigations.Groups;
          const paymentStep = groups.find(step => step.URL == "/paymentMethods");
          dispatch(setCurrentStepAction(paymentStep));
          history.push(paymentStep.URL);
        }
      } else {
        dispatch(setFormInitialState());
      }
    })
    .catch(err => {
      console.log(err.message);
      dispatch(setFormInitialState());
    })
    .finally(() => dispatch(setLoadingCondominiumRegistration(false))) //Loading iniciado na tela anterior
}

export const addInititalDataCondominium = () => async (dispatch, getState) => {
  dispatch(setLoadingBtnCondominiumRegistration(true));

  const { form } = getState().condominiumRegistrationReducer;
  const userId = getState().administrativeHeadReducer.userId;
  const jobRole = getState().administrativeHeadReducer.form.admHead_jobRole;

  const data = {
    CondominiunId: Utils.isNullOrEmptyOrUndefined(form.condominiumRegistration_condominiumId) ? null : form.condominiumRegistration_condominiumId,
    Name: form.condominiumRegistration_condominiumName,
    Street: form.condominiumRegistration_condominiumStreet,
    Number: form.condominiumRegistration_condominiumNumber,
    Complement: form.condominiumRegistration_additionCondominiumStreet,
    CityId: Utils.isNullOrEmptyOrUndefined(form.condominiumRegistration_condominiumCity) ? null : parseInt(form.condominiumRegistration_condominiumCity),
    Neighborhood: form.condominiumRegistration_condominiumNeighborhood,
    CEP: Mask.removeMask(form.condominiumRegistration_condomimiumCep),
    CNPJ: Mask.removeMask(form.condominiumRegistration_cnpj),
    Phone: Mask.removeMask(form.condominiumRegistration_condomimiumPhone),
    Email: form.condominiumRegistration_condomimiumEmail,
    EmployeeId: userId,
    EmployeeJobRole: jobRole
  }

  return await Axios.post("v1/condominium/addInitialData", data)
    .then((res) => {
      if (Utils.isNullOrEmptyOrUndefined(form.condominiumRegistration_condominiumId)) {
        dispatch(setIdCondominiumRegistration(res.data.id));
        dispatch(setCondominiumCode(res.data.codeCondominium));
      }
      dispatch(setLoadingBtnCondominiumRegistration(false));
      return true;
    })
    .catch((error) => {
      toast.warn(error.message || messageErrorDefault, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "ui-alert-toast",
        bodyClassName: "ui-alert-toast-content",
      });
      dispatch(setLoadingBtnCondominiumRegistration(false));
      return false;
    })
}

export const identifyCep = (cep) => (dispatch, getState) => {
  const removeMask = (value) => value.replace(/\D/g, '');

  const { cities, form } = getState().condominiumRegistrationReducer;
  const logradouroFieldName = 'condominiumRegistration_condominiumStreet';
  const ufFieldName = 'condominiumRegistration_condominiumSate';
  const cidadeFieldName = 'condominiumRegistration_condominiumCity';
  const bairroFieldName = 'condominiumRegistration_condominiumNeighborhood';

  Axios.post('v1/condominium/getAddressInfo', { cep: removeMask(cep) })
    .then(async ({ data: result }) => {

      const uf = states.find(state => state.name == result.uf);
      const city = cities.find(city => city.uf == result.uf && city.name == result.localidade);

      dispatch(filterCities(uf?.value));

      dispatch(setForm({
        ...form,
        [ufFieldName]: uf?.value || '',
        [cidadeFieldName]: city?.value || '',
        [bairroFieldName]: result?.bairro || '',
        [cidadeFieldName]: city?.value || '',
        [logradouroFieldName]: result?.logradouro || ''
      }));

    }).catch(error => console.log(error))
}

// const fetchCep = (cep) => {
//   return new Promise((resolve, reject) => {
//     fetch(`https://viacep.com.br/ws/${cep}/json/`)
//     .then(res => res.json())
//     .then(async result => resolve({data: result}))
//     .catch(reject);
//   })
// }

