import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#672CB0',
    },
    secondary: {
      main: '#ABABAB',
    },
    text: {
      primary: '#2E2E2E',
      secondary: '#FFF',
    },
    info: {
      main: 'rgb(0, 119, 255)',
    },
    success: {
      main: 'rgb(50, 255, 101)',
    },
    warning: {
      main: 'rgb(245, 180, 82)',
    },
    danger: {
      main: 'rgb(248, 56, 56)',
    },
    neutral: {
      main: 'rgb(34, 34, 34)',
    },
    darkGreen: '#078A34',
    purple: '#672CB0',
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  breakpoints: {
    values: {
      sm: 576, // Dispositivos pequenos
      md: 768, // Dispositivos médios
      lg: 992, // Dispositivos grandes
      xl: 1170, // Dispositivos extra grandes
    },
  },
});

export default theme;
