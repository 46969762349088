import React from 'react';
import './button.scss';

export default function Button({onclick, content, classes}) {

	return (
		<div className={`btn-registration-cond ${classes}`}>
			<button onClick={(event) => {
				event.preventDefault();
				onclick();
			}}>
                {content}
            </button>
		</div>
	);
}