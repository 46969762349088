import React from 'react'
import './breadcrumbs.scss'
import '../../../node_modules/font-awesome/css/font-awesome.css'

export const Breadcrumbs = (props) => {
  let { title, number, steps } = props

    // if (steps.length <= 2) {
  //   steps = []
  // }

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {/* <li className={`breadcrumbs-list-item`}>{number}</li> */}
        {/* <li className={`breadcrumbs-list-item`}></li> */}

        {/* {steps.length > 0 && steps.map(({ className, text }, index, array) =>
          <li key={`step_${index}`} className={`breadcrumbs-list-item ${className}`}>{text}</li>
        )} */}

        {/* {steps.length <= 2 && steps.map(({ className, text }, index, array) =>
          <li key={`step_${index}`} className={`breadcrumbs-list-item-whiteBorder ${className}`}>{text}</li>
        )} */}

      </ul>
      <h1 className="title">{title}</h1>
      <span className="line"></span>
    </div>
  )
}
