import React from 'react';
import { HashRouter } from 'react-router-dom'
import Routes from '../src/Routes'
import Header from './components/header'
import { PersistGate } from 'redux-persist/integration/react'


import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';

/* Style Import */
import './styles/main.scss'

/* Assets Import */
import logo from '../src/assets/images/logo.png'

/* Redux Settings */
import { Provider } from 'react-redux'
import { store, persistor } from '../src/store/index'
import { ThemeProvider } from '@material-ui/core';
import theme from './theme/mui.theme';

toast.configure()

function Main() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <div className="App">
            <ThemeProvider theme={theme}>
              <Header logoSrc={logo} logoAlt="Logo ZIP" />
              <Routes />
            </ThemeProvider>
          </div>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default Main;
