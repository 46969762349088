import React from 'react';
import './detailCondominiumUnits.scss';

import Breadcrumbs from '../../components/breadcrumbs';
import NavigationButtons from '../../components/navigationButtons';
import Field from "../../components/field";
import Table from "../../components/table";
import Form from "../../components/form";
import CheckObligation from "../../components/checkObligation";
import Trash from "../../assets/images/trash.png";
import { condominiumTypes } from '../../helper/constants';
import DetailHouse from './detailHouse/detailHouse';
import DetailBuilding from './detailBuilding/detailBuilding';
import DetailCondominium from './detailCondominium/detailCondominium';
import { Typography } from '@material-ui/core';

class DetailCondominiumUnits extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.formFields = [];
  }

  render() {

    let { form, loadingBtnCondominiumSpecifications } = this.props.condominiumSpecificationsReducer; // reducer

    let { addCondominiumSpecifications } = this.props; // actions

    return (
      <section className="container">
        <div className="col">
          <Breadcrumbs />
        </div>

        <div className="col detailCondominiumUnits">
          <Form name="detailCondominiumUnits" noValidate={false}>

            {form.condominiumSpec_type === condominiumTypes.House && (<DetailHouse props={this.props} createRefFields={this.createRefFields} />)}
            {form.condominiumSpec_type === condominiumTypes.Building && (<DetailBuilding props={this.props} createRefFields={this.createRefFields} />)}
            {form.condominiumSpec_type === condominiumTypes.Condominium && (<DetailCondominium props={this.props} createRefFields={this.createRefFields} />)}

          </Form>
        </div>

        {form.condominiumSpec_type === condominiumTypes.House && 
          <Typography style={{fontSize: 12}}>* Clique no texto nome da rua para editar</Typography>
        }

        {form.condominiumSpec_type === condominiumTypes.Building && 
          <Typography style={{fontSize: 12}}>* Clique no texto de pavimento para editar</Typography>
        }

        {form.condominiumSpec_type === condominiumTypes.Condominium && 
          <Typography style={{fontSize: 12}}>* Clique no texto de bloco ou pavimento para editar</Typography>
        }

        <div className="col">
          <NavigationButtons 
            history={this.props.history} 
            validateFields={this.validateFields} 
            validationAction={() => addCondominiumSpecifications()}
            disabledNext={loadingBtnCondominiumSpecifications}
            noAsterisk 
            style={{ margin: 0 }} 
          />
        </div>
      </section>
    );
  }

  createRefFields = (self) => {
    this.formFields[self.props.name] = self
  }

  validateFields = () => {

    let list = []
    let countMounted = 0

    return new Promise(resolve => {
      if (Object.keys(this.formFields).length === 0) {
        resolve([])
      }

      for (let key in this.formFields) {
        if (this.formFields[key]._isMounted) {
          countMounted++
          this.formFields[key].validate(this.formFields[key].input)
            // eslint-disable-next-line no-loop-func
            .then(r => {
              list.push({ name: this.formFields[key].props.name, valid: r })

              if (countMounted === list.length) {
                resolve(list)
              }
            })
        }
      }
    })

  }
}

export default DetailCondominiumUnits;
