import { connect } from 'react-redux'
import AdministrativeHead from './administrativeHead'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { 
  onChangeAdministrativeHeadData, 
  onChangeAdministrativeHeadValidationPass,
  saveDataAdministrativeHead,
} from '../../store/administrativeHead/actions';
import { getNavigationStepsAction } from '../../store/navigationSteps/actions'

const mapStateToProps = (state, nextState) => ({
  administrativeHeadReducer: state.administrativeHeadReducer,
});

const mapDispatchToProps = dispatch =>
bindActionCreators({
  getNavigationStepsAction,
  onChangeAdministrativeHeadData,
  onChangeAdministrativeHeadValidationPass,
  saveDataAdministrativeHead
}, dispatch); 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdministrativeHead));
