/* eslint-disable no-eval */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VMasker from 'vanilla-masker'
import helpersRegex from './helpers'

import loadingImg from '../../assets/images/loading.gif'
import './areaField.scss'

const initialState = {
  visitedInput: false,
  className: '',
  errors: [],
  errorDescription: '',
  type: '',
  ariaInvalid: false,
  asyncLoading: false,
  validations: {
    required: false,
    minlength: null,
    maxlength: null,
    min: null,
    max: null,
    pattern: '',
  }
}

const fieldValidationMessage = {
  'valueMissing': () => 'Este campo é obrigatório.',
  'tooShort': value => `Este campo deve ter no mínimo ${value.validations.minlength} caracteres.`,
  'tooLong': value => `Este campo deve ter no máximo ${value.validations.maxlength} caracteres.`,
  'rangeUnderflow': value => `Valor mínimo permitido é ${value.validations.min}.`,
  'rangeOverflow': value => `Valor máximo permitido é ${value.validations.max}.`,
  'patternMismatch': value => `Campo ${value.label} inválido!`,
  'typeMismatch': value => `Campo ${value.label} inválido!`,
  'singleCharacter': () => 'Não é permitido um único caractere repetido várias vezes',
  'GerenteCodeInvalid': () => 'Digite um código de gerente válido.'
}

class AreaField extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.validateAsync = this.validateAsync.bind(this)
  }
  state = { ...initialState }

  componentWillMount() {
    const { returnObject } = this.props;

    if (returnObject) {
      returnObject(this)
    }

    let typeValid = this.isValidInput(this.props.type)
    this.setState(typeValid)
  }

  componentDidMount() {
    this._isMounted = true;
    this.setValidations(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setValidations(nextProps)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    let { value, loading } = this.props
    let { handleChange } = this
    let hasValue = value ? ' has-value' : '' 


    return (
      <label htmlFor={this.state.name} className={`field ${this.state.className + hasValue}`}>
        <textarea
          wrap="soft"
          id={this.state.name}
          placeholder={this.props.placeholder}
          name={this.props.name}
          type={this.state.type}
          value={this.props.mask ? VMasker.toPattern(value, this.props.mask) : value}
          required={this.state.validations.required}
          pattern={this.state.validations.pattern ? this.state.validations.pattern : null}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          min={this.state.validations.min}
          max={this.state.validations.max}
          minLength={this.state.validations.minlength}
          maxLength={this.state.validations.maxlength}
          aria-invalid={this.state.ariaInvalid}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={handleChange}
          ref={input => { this.input = input }}
          onKeyUp={this.props.keyup}
          hidden={this.props.hidden}
          style={this.props.style}
        />
        {loading && <i className="icon"> <img src={loadingImg} alt="loading" /></i>}
        <span className="label">{this.state.validations.required && !this.props.noAsterisk ? this.props.label + ' *' : this.props.label}</span>
        <span className="border"></span>
        {this.state.errorDescription && (
          <small className="message">{this.state.errorDescription}</small>
        )}
      </label>
    )
  }

  setValidations = (props) => {
    let validations = {
      required: props.hasOwnProperty('required') ? props.required : this.state.validations.required,
      minlength: props.hasOwnProperty('minlength') ? props.minlength : this.state.validations.minlength,
      maxlength: props.hasOwnProperty('maxlength') ? props.maxlength : this.state.validations.maxlength,
      min: props.hasOwnProperty('min') ? props.min : this.state.validations.min,
      max: props.hasOwnProperty('max') ? props.max : this.state.validations.max,
      pattern: props.hasOwnProperty('pattern') ? props.pattern : this.state.validations.pattern
    }
    this.setState({ validations: validations })
  }

  handleFocus = event => {
    this.setState({ visitedInput: true })

    if (this.state.visitedInput === false) {

      if (this.props.mask)
        VMasker(event.target).maskPattern(this.props.mask)

      if (this.props.type === "money")
        VMasker(event.target).maskMoney({ unit: 'R$' })

    }
  }

  handleChange = event => {
      
    event.preventDefault()
    if (this.props.mask)
      VMasker(event.target).maskPattern(this.props.mask)
    if (this.props.type === "money")
      VMasker(event.target).maskMoney({ unit: 'R$' })

    let { name, value } = event.target
    let { onChange } = this.props

    if (onChange) {
      onChange(name, value)
    }
  }

  handleBlur = event => {
    event.preventDefault()
    this.validate(event.target)
  }


  isValidInput = type => {
    switch (type) {
      case "date": return { type: "tel", ariaInvalid: true, validations: { pattern: this.props.hasOwnProperty('pattern') ? this.props.pattern : helpersRegex.regex.date } }
      case "email": return { type: "email", validations: { pattern: this.props.pattern ? this.props.hasOwnProperty('pattern') : helpersRegex.regex.email } }
      case "tel": return { type: "tel", ariaInvalid: true }
      case "number": return { type: "tel", ariaInvalid: true }
      case "cpf": return { type: "tel", ariaInvalid: true, validations: { pattern: this.props.hasOwnProperty('pattern') ? this.props.pattern : helpersRegex.regex.cpf } }
      case "money": return { type: "tel", ariaInvalid: true }
      case "checkbox": return { type: "checkbox", ariaInvalid: true }
      default: return { type: type }
    }
  }

  validate = (field, valueNextProps = null) => {
    const {validateName} = this.props;
    let fieldValidityState = null
    return new Promise((resolve, reject) => {
      
      if (validateName) {
        const message = this.validateName(field.value);
        if (message !== null) {
          reject({ message: message })
        }
      }
      
      if (!field.checkValidity()) {
        
        fieldValidityState = field.validity

        let errorType = this.validatePropertiesValidityState(fieldValidityState)
        reject({ message: fieldValidationMessage[errorType]({ ...this.props, ...this.state }) })
      }

      let resultValidateSync = this.validateSync(field.value)
      if (resultValidateSync !== true)
        reject({ message: this.props.validateSyncMessage ? this.props.validateSyncMessage : fieldValidationMessage["typeMismatch"]({ ...this.props, ...this.state }) })

      if (this.state.validations.minlength && field.value.length < this.state.validations.minlength) {
          reject({ message: fieldValidationMessage["tooShort"]({ ...this.props, ...this.state }) })
      }

      this.setState({ asyncLoading: true })
      resolve(field.value)
    })
      .then(this.validateAsync)
      .then(() => {
        this.setState({ className: 'info', errorDescription: '' })
        field.setCustomValidity('')

        return true
      })
      .catch(error => {
        this.setState({ className: 'danger', errorDescription: error.message })
        let { onError } = this.props
        if (onError)
          onError({ name: this.state.name, errorDescription: error.message })

        return false
      }).finally(() => {
        this.setState({ asyncLoading: false })
        return this.state
      })
  }

  async validateAsync(value) {
    if (typeof (this.props.validateAsync) === "function")
      return await this.props.validateAsync(value)
    return true
  }

  validateSync = value => {
    if (!this.props.validateSync)
      return true

    return this.props.validateSync(value)
  }


  validatePropertiesValidityState = validityState => {
    for (var key in validityState) {
      if (validityState[key] === true && key !== 'valid')
        return key
    }

  }

  validateName = (name) => {
    if (name === undefined || name === null || name === "") {
      return null;
    }

    let message = null;
    name = name.trim();
    let data = name.split(" ");

    if (data.length === 1 || (
      data.length === 2 && (data[1] === "de" || data[1] === "da")
    )) {
      message = "Informe ao menos um sobrenome"
    }

    for(let x = 0; x < data.length; x++) {
      if (data[x].length < 2) {
        message =  "Digite ao menos 2 caracteres para cada parte do nome";       
        break;
      }
    }
    
    return message;
  }

}

AreaField.protoTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string
}

export default AreaField

